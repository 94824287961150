import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from '../styles/Dashboard.scss';
import cx from 'classnames';
// import { RaisedButton } from 'material-ui';
import RaisedButton from './../../../Common/RaisedButton';

import RegionList from './RegionList';


class List extends Component {
  static contextTypes = {

  };

  static propTypes = {

  };

  static defaultProps = {

  };

  constructor(props) {
    super(props);

    this.state = {

    };
  }

  render() {
    const { selectedRegion, changeParentState, toggleDialog, updatePort, allRegions, allPorts, name, isExcluded } = this.props;

    return (
      <div className={s.choose}>
        <RegionList
          selectedRegion={selectedRegion}
          changeParentState={changeParentState}
          allRegions={allRegions}
          allPorts={allPorts}
        />
        <div className={s.wrapper_buttons}>
          <RaisedButton
            label="Cancel"
            disableTouchRipple
            disableFocusRipple
            secondary
            buttonStyle={{
              borderRadius: '0',
            }}
            style={{
              marginRight: '12px'
            }}
            onClick={toggleDialog}
          />

          <RaisedButton
            label="Select"
            disableTouchRipple
            disableFocusRipple
            disabled={!selectedRegion}
            onClick={() => {
              updatePort(selectedRegion, name, isExcluded);
            }}
            primary
            style={{
              marginRight: '0',
            }}
            buttonStyle={{
              borderRadius: '0',
            }}
          />
        </div>
      </div>
    )
  }
}

export default withStyles(s)(List);
