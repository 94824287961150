import PropTypes from 'prop-types';
import React, { Component } from 'react';
import history from '../../core/history';
import MenuItem from 'material-ui/MenuItem';
import ActionSearch from 'material-ui/svg-icons/action/search';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './SearchInput.scss';
import { debounceWithoutFirstCall } from "../../core/utils";

let scrollPositionStorage;
class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.query || '',
    };
  }

  componentDidMount() {
      this.setState({ text: this.props.query || '' });
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (!this.focused && this.props.query !== nextProps.query && nextProps.query !== this.state.text) {
      this.setState({ text: nextProps.query || ''});
    }
  }


  refInput = (element) => {
    this.input = element;
  };

  timer = null;

  handleChange = (e) => {
    const value = e.target.value.trim();
    this.setState({ text: e.target.value});
    this.handleSearch(value);
  };

  handleSearch=debounceWithoutFirstCall((value) => {
    this.props.handleSearch(value);
  }, 300)

  onClick = (port) => {
    this.setState({ text: '' });
    this.props.handleResetList();
    history.push(`/port/${port.seo?.sefName || port._id}`);
  }

  handleClear = () => {
      this.props.dropDown ? this.props.handleResetList() : this.props.handleSearch('');
      this.setState({ text: '' }, () => { this.input && this.input.focus(); });
  };

  handleFocus = () => {
    this.focused = true;
  };

  handleBlur = () => {
    this.focused = false;
    setTimeout(() => {
      this.props.dropDown && this.setState({ text: '' });
    }, 100);
  };

  render() {
    const { style, styleInput } = this.props;
    return (
    <div style={{position: 'relative'}}>
      <div className={s.search} style={style}>
        <ActionSearch
          style={{
            transform: 'scale(-1,1)',
            width: '18px',
            height: '18px',
          }}
        />
        <input
          autoComplete="off"
          name="input"
          ref={this.refInput}
          value={this.state.text}
          onChange={this.handleChange}
          type="text"
          style={styleInput}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          placeholder={this.props.placeholder}
        />
        {this.state.text ?
          <NavigationClose
            onClick={this.handleClear}
            className={s.close}
            style={{
              width: '16px',
              height: '16px',
            }}
          />
          : null}
        </div>
        {this.props.portList?.length && this.focused && this.props.dropDown ?
          <div className={s.search_results} onScroll={this.handleScroll}>
            {this.props.portList.map((port, i) => (
              <MenuItem name={port.name} key={i} onClick={() => this.onClick(port)}>
                <span className={s.results_port_name}>{port.name}</span>
                <span className={s.results_port_unlocode}>{port.unLoCode}</span>
              </MenuItem>
            ))}
        </div> : null
        }
      </div>
    )
  }
}
SearchInput.propTypes = {
  handleSearch: PropTypes.func,
  query: PropTypes.string,
};
export default withStyles(s)(SearchInput);
