/*
 * @Author: salterok
 * @Date: 2017-10-06 17:27:24
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2017-10-24 15:19:51
 */

import React from "react";

import s from "../Chat.scss";
import cx from "classnames";
import { connect } from "react-redux";

import IconChat from "material-ui/svg-icons/communication/chat-bubble-outline";
import IconClose from "material-ui/svg-icons/navigation/close";


import { selectContact } from "../actions";
import { getContacts, getUsernameSearchString } from "../selectors";
import { ContactList } from "../Contact/ContactList";
import { BoundChatRoom } from "./ChatRoom";

export class ChatPanel_ extends React.Component {

  constructor(...args) {
    super(...args);

    this.handleSearchContacts = this.handleSearchContacts.bind(this);
    this.handleSelectContact = this.handleSelectContact.bind(this);
  }

  state = {
    contactFilter: ""
  };

  handleSearchContacts(event) {
    const filter = event.target.value || "";

    this.setState({
      contactFilter: filter.toLowerCase()
    });
  }

  handleSelectContact(contactId) {
    this.props.selectContact(contactId);
  }

  render() {
    const { users, me, selectedContact, hideChat, selectContact, that } = this.props;
    const { contactFilter } = this.state;

    let usersArray = users;
    if (this.state.contactFilter.length > 0) {
      usersArray = users.filter(user => {
        return getUsernameSearchString(user).toLowerCase().indexOf(contactFilter) !== -1;
      });
    }

    return (
      <div className={cx(s.wrapper_opened_msglist, selectedContact ? s.wrapper_opened_contact : null)}>
        <div className={s.header}>
          <div>
            <IconChat
              style={{
              width: "20px",
              height: "20px",
              margin:"0 10px"
            }}
            />
            <span className={s.name}>chat</span>
          </div>
          <IconClose
            style={{
              marginRight:"8px",
              cursor: "pointer"
            }}
            onClick={hideChat}
          />
        </div>
        <div className={s.body}>
          <ContactList users={usersArray} selectedContact={selectedContact} selectRoom={this.handleSelectContact} searchContacts={this.handleSearchContacts} />
          <BoundChatRoom that={that} userId={selectedContact} closeRoom={() => this.handleSelectContact(undefined)} />
        </div>
      </div>
    );
  }
}




export const ChatPanel = connect((state, props) => {
  const me = state.login.user._id;

  const users = getContacts(state, me);

  return {
    me,
    users,
    selectedContact: state.chat.selectedContact
  };
},{
  selectContact
})(ChatPanel_);
