/*
 * @Author: salterok
 * @Date: 2017-10-05 19:22:44
 * @Last Modified by: Sergiy Samborskiy
 * @Last Modified time: 2017-11-02 14:01:57
 */

import React from "react";
import { List, makeSelectable } from "material-ui/List";
import IconSearch from "material-ui/svg-icons/action/search";
import s from "../Chat.scss";
import cx from "classnames";
import Avatar from "material-ui/Avatar";

import { ContactItem } from "./ContactItem";

const SelectableList = makeSelectable(List);

export function ContactList(props) {
  const { users, selectedContact, selectRoom, searchContacts } = props;

  const closeRoom = () => selectRoom(undefined);

  return (
    <div className={cx(s.contacts_list, selectedContact ? s.contacts_list_small : null)}>
      <div
        className={s.search}
        onClick={selectedContact ? closeRoom : null}
      >
        <IconSearch
          className={s.icon_search}
          color={"#CBCBCB"}
        />
        <input
          type="text"
          onChange={searchContacts}
        />
      </div>
      <hr className={s.line} />
      <div className={cx(s.contacts)}>
        <SelectableList
          style={{
            padding: "0 0 0 8px"
          }}
        >
          {

            users.map(user => <ContactItem key={user._id} user={user} isSelected={selectedContact===user._id} selectRoom={selectRoom} />)

          }

        </SelectableList>
      </div>
    </div>
  );
}
