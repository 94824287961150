import React from 'react';
import FittedSelect from "../../../Common/FittedSelect";
import MenuItem from "material-ui/MenuItem";
import { NarrowSelect } from "../../../NewInputs/NarrowSelect";
import { IGS_TYPES } from "../../../Monitor/Edit/config";
import NarrowFormsyText from "../../../NewInputs/NarrowFormsyText";
import { ContentAddButton, ContentClearButton } from "../../../Common/AddButton";
import s from './IgsForm.scss'
import cx from "classnames";
import withStyles from "isomorphic-style-loader/lib/withStyles";

const defaultSystem = { type: '', capacity: '', percentage: '' };
export const FormIGS = withStyles(s)(function FormIGS({
  prefix,
  value = {},
  onChange
}) {
  const { fitted } = value;

  let systems = value.systems
  if (!systems || !systems.length) {
    systems = [defaultSystem]
  }

  const addRow = () => onChange({
    igs: { fitted, systems: [...systems, defaultSystem] },
  });

  const removeRow = index => onChange({
    igs: { fitted, systems: systems.filter((_, i) => i !== index) },
  });

  const changeValue = (index, key, val) => onChange({
    igs: {
      fitted,
      systems: systems.map((s, i) => i === index ? { ...s, [key]: val } : s)
    },
  })

  return (
    <div className={cx(s.w_full)}>
      {systems.map((system, i) => (
        <div key={i} className={cx(s.w_full, s.grid, s.grid_cols_4, s.gap_16px)}>
          <div>
            {i === 0 && (
              <FittedSelect
                name={`${prefix}[igs][fitted]`}
                value={fitted}
                floatingLabelText="IGS"
                onChange={(_, val) => onChange({ igs: { ...value, fitted: val } })}
              />
            )}
          </div>
          <div>
            <NarrowSelect
              disabled={!fitted}
              floatingLabelText="IGS type"
              name={`${prefix}[igs][systems][${i}][type]`}
              maxHeight={400}
              value={system.type}
              onChange={(_, val) => changeValue(i, 'type', val)}
              fullWidth
            >
              {[...IGS_TYPES, ''].map(type =>
                <MenuItem
                  key={type.id}
                  value={type}
                  primaryText={type}
                />
              )}
            </NarrowSelect>
          </div>
          <div>
            <NarrowFormsyText
              disabled={!fitted}
              name={`${prefix}[igs][systems][${i}][capacity]`}
              floatingLabelText="Capacity, cbm/hr"
              validations={'isNumeric,isInt,min:0'}
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: 'min 0',
              }}
              onChange={(_, val) => changeValue(i, 'capacity', val)}
              value={system.capacity}
              fullWidth
            />
          </div>
          <div className={cx(s.flex, s.items_center)}>
            <NarrowFormsyText
              disabled={!fitted}
              name={`${prefix}[igs][systems][${i}][percentage]`}
              floatingLabelText="%"
              validations={'isNumeric,isInt,min:0'}
              validationErrors={{
                isInt: 'only integer',
                isNumeric: 'only numbers',
                min: 'min 0',
              }}
              onChange={(_, val) => changeValue(i, 'percentage', val)}
              value={system.percentage}
              fullWidth
            />
            <div className={s.ml_16px}>
              {i === systems?.length - 1
                ? <ContentAddButton onClick={addRow}/>
                : <ContentClearButton onClick={() => removeRow(i)}/>
              }
            </div>
          </div>
        </div>
      ))}
    </div>
  );
})
