exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2TiX2 {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: white;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center;\n  z-index: 1000; }\n  ._2TiX2 > div {\n    -webkit-transition: unset;\n    transition: unset;\n    -webkit-animation: unset;\n            animation: unset;\n    -webkit-transform: unset;\n            transform: unset; }\n  ._2TiX2 > div svg circle {\n      -webkit-animation-name: _2FXxQ;\n              animation-name: _2FXxQ;\n      -webkit-animation-duration: 10s;\n              animation-duration: 10s;\n      -webkit-animation-iteration-count: infinite;\n              animation-iteration-count: infinite;\n      -webkit-animation-timing-function: ease;\n              animation-timing-function: ease;\n      -webkit-transform-origin: center;\n              transform-origin: center; }\n  @-webkit-keyframes _2FXxQ {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg); }\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg); } }\n  @keyframes _2FXxQ {\n  0% {\n    -webkit-transform: rotate(0deg);\n            transform: rotate(0deg); }\n  100% {\n    -webkit-transform: rotate(360deg);\n            transform: rotate(360deg); } }\n  ._2TiX2 > div > div {\n      -webkit-transition-duration: unset !important;\n              transition-duration: unset !important;\n      -webkit-transition-timing-function: unset !important;\n              transition-timing-function: unset !important; }\n  ._2TiX2.syI40 {\n    opacity: 0.7; }\n  ._2TiX2.n7wFy {\n    background-color: var(--bg-table-dark); }\n  ._2TiX2._1Z9I4 {\n    background-color: rgba(255, 255, 255, 0); }\n", ""]);

// Exports
exports.locals = {
	"loader": "_2TiX2",
	"spin": "_2FXxQ",
	"opaque": "syI40",
	"dark": "n7wFy",
	"transparent": "_1Z9I4"
};