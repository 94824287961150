import cx from "classnames";
import { PUMP_TYPES } from "../../../Monitor/Edit/config";
import MenuItem from "material-ui/MenuItem";
import NarrowFormsyText from "../../../NewInputs/NarrowFormsyText";
import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { NarrowSelect } from "../../../NewInputs/NarrowSelect";
import s from './PumpsForm.scss'
import { ContentAddButton, ContentClearButton } from "../../../Common/AddButton";

export const PumpsForm = withStyles(s)(({
  prefix,
  pumps = [{}],
  blockTitleClass,
  onChange,
}) => {
  if (!pumps.length) pumps = [{}]

  const changeValue = (index, key, value) => {
    onChange({ pumps: pumps.map((x, i) => i === index ? { ...x, [key]: value } : x) })
  }

  const handleAddRow = () => {
    onChange({ pumps: [...pumps, {}] })
  }

  const handleRemoveRow = (index) => {
    onChange({ pumps: pumps.filter((_, i) => i !== index) })
  }

  prefix = `${prefix}[pumps]`

  return (
    <div className={s.w_full}>
      <div className={cx(s.flex, s.items_center, s.h_44px)}>
        <h6 className={blockTitleClass}>CARGO PUMP</h6>
      </div>
      <div className={cx(s.flex, s.w_full)}>
        <div className={s.w_full}>
          {pumps?.map((row, i) => (
            <div key={i} className={cx(s.flex, s.items_center, s.w_full, s.gap_16px)}>
              <div style={{ width: '45%' }}>
                <NarrowSelect
                  floatingLabelText="Type"
                  name={`${prefix}[${i}][type]`}
                  maxHeight={271}
                  fullWidth
                  value={row?.type || ''}
                  onChange={(_, value) => changeValue(i, 'type', value)}
                >
                  {PUMP_TYPES.map(type => (
                    <MenuItem key={type} value={type} primaryText={type}/>
                  ))}
                </NarrowSelect>
              </div>
              <div style={{ width: '21%' }}>
                <NarrowFormsyText
                  floatingLabelText="Quantity"
                  validations={'isNumeric,isInt,min:0'}
                  validationErrors={{
                    isInt: 'only integer',
                    isNumeric: 'only numbers',
                    min: '0 - 20',
                  }}
                  onBlur={(_, value) => changeValue(i, 'quantity', value)}
                  value={row?.quantity || ''}
                  name={`${prefix}[${i}][quantity]`}
                  fullWidth
                />
              </div>
              <div style={{ width: '21%' }}>
                <NarrowFormsyText
                  floatingLabelText="Capacity, m3/hour"
                  validations={'isNumeric,isInt,min:0'}
                  validationErrors={{
                    isInt: 'only integer',
                    isNumeric: 'only numbers',
                    min: 'min 0',
                  }}
                  onBlur={(_, value) => changeValue(i, 'capacity', value)}
                  name={`${prefix}[${i}][capacity]`}
                  value={row?.capacity || ''}
                  fullWidth
                />
              </div>
              <div>
                {i === pumps?.length - 1
                  ? <ContentAddButton onClick={handleAddRow}/>
                  : <ContentClearButton onClick={() => handleRemoveRow(i)}/>
                }
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
});
