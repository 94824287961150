export default [
  { name: 'Cargo request', value: 'cargoRequest', id: 1 },
  { name: 'Order request', value: 'orderRequest', id: 2 },
  { name: 'Fleet', value: 'fleet', id: 3 },
  { name: 'Suitable Fleet', value: 'suitableFleet', id: 4 },
  { name: 'Account Name', value: 'accountName', id: 5 },
  { name: 'Port of Landing', value: 'portOfLanding', id: 6 },
  { name: 'Port of Destination', value: 'portOfDestination', id: 7 },
  { name: 'Shipment Date', value: 'shipmentDate', id: 8 },
  { name: 'Laycan', value: 'laycan', id: 9 },
  { name: 'Readiness Date', value: 'readinessDate', id: 10 },
  { name: 'POL/POD Details', value: 'polPodDetails', id: 11 },
  { name: 'Details', value: 'details', id: 12 },
  { name: 'Cargo Details', value: 'cargoDetails', id: 13 },
  { name: 'Cargo Type', value: 'cargoType', id: 14 },
  { name: 'Loading Rate', value: 'loadingRate', id: 15 },
  { name: 'Discharging Rate', value: 'dischargingRate', id: 16 },
  { name: 'Terms', value: 'terms', id: 17 },
  { name: 'Commission', value: 'commission', id: 18 },
  { name: 'Contract', value: 'contract', id: 19 },
  { name: 'Tags', value: 'tags', id: 20 },
  { name: 'Comment', value: 'comment', id: 21 },
  { name: 'AIS Details', value: 'aisDetails', id: 22 },
  { name: 'Open Position', value: 'openPosition', id: 23 },
  { name: 'Freight Request', value: 'freightRequest', id: 24 },
  { name: 'Vessel Position Date', value: 'vesselPositionDate', id: 25 },
  { name: 'Position Status', value: 'positionStatus', id: 26 },
  { name: 'Vessel Particulars', value: 'vesselParticulars', id: 27 },
  { name: 'Disponent Owner', value: 'disponentOwner', id: 28 },
  { name: 'Vessel Details', value: 'vesselDetails', id: 29 },
  { name: 'Attachments', value: 'attachments', id: 30 },
  { name: 'Speed and Consumptions', value: 'speedAndConsumptions', id: 31 },
  { name: 'Mailbox', value: 'mailbox', id: 32 },
  { name: 'Search field', value: 'searchField', id: 33 },
  { name: 'Send mail', value: 'sendMail', id: 34 },
  { name: 'Archive mail', value: 'archiveMail', id: 35 },
  { name: 'Mail Dates', value: 'mailDates', id: 36 },
  { name: 'Message thread', value: 'messageThread', id: 37 },
  { name: 'Original message', value: 'originalMessage', id: 38 },
  { name: 'Message Subject', value: 'messageSubject', id: 39 },
  { name: 'Counter Offer', value: 'counterOffer', id: 40 },
  { name: 'Mail Flow', value: 'mailFlow', id: 41 },
  { name: 'Mail receivers', value: 'mailReceivers', id: 42 },
  { name: 'Cargo Monitor', value: 'cargoMonitor', id: 43 },
  { name: 'Cargo Status', value: 'cargoStatus', id: 44 },
  { name: 'Vessel Information', value: 'vesselInformation', id: 45 },
  { name: 'Offer Hire', value: 'offerHire', id: 46 },
  { name: 'Fleet Monitor', value: 'fleetMonitor', id: 47 },
  { name: 'Fleet Status', value: 'fleetStatus', id: 48 },
  { name: 'Add Ship', value: 'addShip', id: 49 },
  { name: 'Add Position', value: 'addPosition', id: 50 },
  { name: 'Document', value: 'document', id: 51 },
  { name: 'Document Status', value: 'documentStatus', id: 52 },
  { name: 'Bill of Landing', value: 'billOfLanding', id: 53 },
  { name: 'Document Preview', value: 'documentPreview', id: 54 },
  { name: 'Request Details', value: 'requestDetails', id: 55 },
  { name: 'Voyage Charter', value: 'voyageCharter', id: 56 },
  { name: 'Time Charter', value: 'timeCharter', id: 57 },
  { name: 'Booking Note', value: 'bookingNote', id: 58 },
  { name: 'Account Settings', value: 'accountSettings', id: 59 },
  { name: 'Email address', value: 'emailAddress', id: 60 },
  { name: 'Accounts', value: 'accounts', id: 61 },
  { name: 'Company', value: 'company', id: 62 },
];
