import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import type { IMessageDetails } from '../types';
import Search from '../../Common/Search';
import { dateTime } from '../../../core/format';
import { Collapse } from '../../Common/Collapse';
import collapseCss from '../../Common/Collapse.scss';
import EmailActionButtons from '../EmailActionButtons';
import {debounceWithoutFirstCall, escapeRegExp, sleep} from '../../../core/utils';
import Highlight from '../../Monitor/Highlight';
import { uniq } from 'lodash/array';
import cx from 'classnames';
import CustomTooltip from '../../Common/CustomTooltip';
import ComposeReply from './ComposeReply';
import MessageContent from '../MessageContent';
import { EmailHeadersPopover } from '../EmailHeaders';
import EmailDetailsDialog from '../EmailDetailsDialog';
import { mapChipsToEmail } from '../utils';
import MailFlowItem from "./MailFlowItem";
import SelectableElement from '../../IssueReport/SelectableElement';

type State = {
  replyText: string,
};
type Props = {
  message: IMessageDetails,
  blockHistory?: () => void,
  unblock?: () => void,
  onSendReply: () => void,
  scrollToOriginalMessage: () => void,
  innerRef: () => void,
  isMailGateErrored: boolean,
  markAsUnread: (e: *, threadId: string) => void,
  searchQuery: string,
  searchRegExp: RegExp,
  transitionLockerPredicate: () => boolean
}

const StyledWrapper = styled.div`

`


const RepliesList = styled((props: {
  replies: IMessageDetails[] | *, className?: string, scrollToReply: (refNo: string, e: *) => void,
  refCollapse: (refNo: string, el: *) => void,
  onReplyClick:(message: IMessageDetails) => void,
  onForwardClick:(message: IMessageDetails) => void,
  searchRegExp: RegExp,
  search: string,
  replyTo: IMessageDetails,
  mailGatesState: *,
  markAsUnread: (e: *, threadId: string) => void,
}) => {
  if (!props.replies || !props.replies.length) {
    return <div className={props.className}><h3>No message replies yet</h3></div>;
  }
  return <div className={props.className}>
    {props.replies.map((reply: IMessageDetails, index) => {
      const defaultOpen = index === props.replies.length - 1;
      return (
        <SelectableElement entity="mailThreads" entityId={reply.id} type="exchange ref" id={reply.refNo}>
          <MailFlowItem
            key={reply.refNo} reply={reply}
            onReplyRefClick={props.scrollToReply.bind(this, reply.replyToRef)}
            onReplyClick={props.onReplyClick.bind(this, reply)}
            onForwardClick={props.onForwardClick.bind(this, reply)}
            refCollapse={props.refCollapse.bind(this, reply.refNo)}
            searchRegExp={props.searchRegExp}
            className={cx(props.replyTo.refNo === reply.refNo && 'active')}
            mailGatesState={props.mailGatesState}
            isLast={index === props.replies.length - 1}
            defaultOpen={defaultOpen}
            markAsUnread={props.markAsUnread}
            search={props.search}
            deleteMail={props.deleteMail}
          />
        </SelectableElement>
      )})}
  </div>
})`
  background: white;
  display: flow-root;
   h3 {
     text-align: center;
     margin: 34px 0;
     font-weight: normal;
     color: var(--stroke-light-gray1);
     font-size: 20px;
   }
  >div {
    margin-top: 4px;
  }
`

const Replies = styled(({ className, onSearch, search, searchRegExp, message, scrollToReply, refCollapse, onReplyClick, onForwardClick, searchMatchesCount,focusedMatchNumber, onScrollToFoundMatch, replyTo, mailGatesState, markAsUnread, deleteMail, ...props }) => {
  return <div className={className}>
    <div className={'list'}>
      <RepliesList replies={message.nestedMails} scrollToReply={scrollToReply} refCollapse={refCollapse} onReplyClick={onReplyClick} onForwardClick={onForwardClick} search={search} searchRegExp={searchRegExp} replyTo={replyTo} mailGatesState={mailGatesState} markAsUnread={markAsUnread} deleteMail={deleteMail} />
    </div>
  </div>;
})`
  display: flex;
  flex-direction: column;
  >div.list {
    /*flex: 1;
    overflow: auto;
    min-height: 88px;*/
    background: var(--bg-light-gray);
  }
`






class MailFlow extends Component<Props, State> {

  static contextTypes = {
    blockTransition: PropTypes.func,
  }

  static defaultProps = {}


  state = {
    replyText: "",
    replyTo: null,
    subjectEditDisabled: true,
  };
  replyCollapses: {[string]: Collapse} = {}
  historyBlocked: false;

  constructor(props, context) {
    super(props, context);
    this.state.replyTo = props.message;
    const receivedReplies = props.message.nestedMails?.filter(nm => !nm.sentByClient);
    if (receivedReplies?.length > 0) {
      this.state.replyTo = receivedReplies[receivedReplies.length - 1];
    }
    this.state.replyTo.actionType = "reply";
    let subject = this.state.replyTo.subject;
    if (subject.indexOf("Re: ") !== 0) {
      subject = 'Re: ' + subject;
    }
    this.state.subject = subject;
    if (props.innerRef) {
      props.innerRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.innerRef) {
      this.props.innerRef(this);
    }
    if (this.unblock) {
      this.unblock();
    }
  }

  handleStartReply = (replyTo: IMessageDetails, e, replyToAll = false) => {
    let subject = replyTo.subject;
    if (subject.indexOf("Re: ") !== 0) {
      subject = 'Re: ' + subject;
    }
    replyTo = { ...replyTo,
      replyToAll,
      // added regular expression for removing body styles
      html: replyTo.html.replace(/<style\b[^>]*>[\s\S]*?<\/style>|<head\b[^>]*>[\s\S]*?<\/head>/g, ''),
      actionType: "reply",
    };
    this.setState({ replyTo, subject }, () => {
      this.composeReply.quoteReplyTo(replyTo);
      document.getElementById(`compose-reply-${this.props.message.refNo}`)?.scrollIntoView();
    });

  }
  handleStartForward = (message: IMessageDetails, e,) => {
    let subject = message.subject;
    if (subject.indexOf("Fwd: ") !== 0) {
      subject = 'Fwd: ' + subject;
    }
    message = { ...message,
      html: message.html.replace(/<style\b[^>]*>[\s\S]*?<\/style>|<head\b[^>]*>[\s\S]*?<\/head>/g, ''),
      actionType: "forward",
    };
    this.setState({ replyTo: message, subject }, () => {
      this.composeReply.quoteForward(message);
      document.getElementById(`compose-reply-${this.props.message.refNo}`)?.scrollIntoView();
    });
  }

  handleEditSubject = () => {
    this.setState({ subjectEditDisabled: false });
  }

  handleSendReply = async ({ from, to, cc, bcc, replyTo, subject }) => {
    const message = this.props.message;
    const actionType = this.state.replyTo.actionType;
    await sleep(600);
    const div = document.createElement('div');
    div.innerHTML = this.state.replyText;
    const text = div.textContent;
    const content = `${this.state.replyText}`.replace('shipnext_quote_collapsible','').replace('shipnext_quote_collapsed', '');
    const cargoId = this.props.cargoId;
    const vesselId = this.props.vesselId;
    const res = await this.props.sendReply(message.id, {
      subject: subject,
      content,
      text,
      cargoId,
      vesselId,
      replyTo: this.state.replyTo.id,
      attachments: this.composeReply.getUploadedFiles().map(f => f._id),
      to: mapChipsToEmail(to),
      cc: mapChipsToEmail(cc),
      bcc: mapChipsToEmail(bcc),
      shouldReplyTo: mapChipsToEmail(replyTo),
      replyGate: from,
      actionType: actionType,
    });
    if (res instanceof Error || res.status > 399) {
      return res;
    }
    this.handleClearReply();
    setTimeout(() => {
      document.getElementById(`compose-reply-${this.props.message.refNo}`)?.scrollIntoView();
    });
   return res;
  }
  handleClearReply = (e, keepAttachments = false) => {
    this.setState({ replyText: "" });
    if (this.historyBlocked) {
      this.historyBlocked = false;
      this.unblock();
    }
    if (!keepAttachments) {
      this.composeReply.clearUploadedFiles();
    }
  }

  defaultTransitionLockerPredicate = (location, action) => location.pathname.indexOf(`/${this.props.message.id}/`) === -1

  handleReplyTextChange = (val, delta, source) => {
    if (!val) {
      return this.handleClearReply(e,true);
    }
    if (!this.state.replyText) {
      this.setState({ replyText: val });
    } else {
      this.state.replyText = val;
    }


    if (!this.historyBlocked && source !== 'silent') {
      this.historyBlocked = true;
      this.unblock = this.context.blockTransition('Are you sure you want to leave this page?', this.props.transitionLockerPredicate || this.defaultTransitionLockerPredicate);
    }
  }

  scrollToReply = (replyRef: string, e: *) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (replyRef === this.props.message.refNo) {
      this.props.scrollToOriginalMessage();
    }
    this.replyCollapses[replyRef]?.show(e, () => document.querySelector(`#mail-reply-${replyRef} .scroll_anchor`)?.scrollIntoView());

  }

  refReplyCollapse = (replyRef: string, el: Collapse) => {
    this.replyCollapses[replyRef] = el;
  }

  getDomId(){
    return `mail-flow-${this.props.message.refNo}`;
  }

  refComposeReply = (el: ComposeReply) => {
    this.composeReply = el;
}

  render() {
    const { message } = this.props;
    const { replyText } = this.state;
    const composeHeadersProps = {
      handleReply: this.handleStartReply,
      handleForward: this.handleStartForward,
      handleSubject: this.handleEditSubject,
      subjectProps: { disabled: this.state.subjectEditDisabled } };
    return (
      <StyledWrapper id={this.getDomId()}>
        <Replies
          message={message}
          scrollToReply={this.scrollToReply}
          onReplyClick={this.handleStartReply}
          onForwardClick={this.handleStartForward}
          refCollapse={this.refReplyCollapse}
          onSearch={this.handleSearch}
          search={this.props.searchQuery}
          searchRegExp={this.props.searchRegExp}
          replyTo={this.state.replyTo}
          markAsUnread={this.props.markAsUnread}
          mailGatesState={this.props.mailGatesState}
          deleteMail={this.props.deleteMail}
        />
        {this.props.mailGatesState?.senderGates?.length ? (<ComposeReply
          id={`compose-reply-${message.refNo}`}
          text={replyText}
          onTextChange={this.handleReplyTextChange}
          replyTo={this.state.replyTo}
          onReplyToRefClick={this.scrollToReply.bind(this, this.state.replyTo.refNo)}
          onClearReply={this.handleClearReply}
          onSendReply={this.handleSendReply}
          ref={this.refComposeReply}
          subject={this.state.subject}
          mailGatesState={this.props.mailGatesState}
          lastUsedAlias={this.props.message.lastUsedAlias}
          user={this.props.user}
          scrollingContainer={this.props.scrollingContainer}
          headersProps={composeHeadersProps}
          isNotNew
        />) : null}
      </StyledWrapper>
    );
  }
}

export default MailFlow;
