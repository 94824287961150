import React from 'react';
import Sort from './Sort';

const OWNER_ME = 'me';
const OWNER_GROUP = 'group';

class SortVessel extends Sort {
  static defaultProps = {
    sort: { field: 'status', value: -1, label: 'Status' },
    values: {
      status: { field: 'status', value: -1, label: 'Status' },
    },
    owner: [OWNER_ME, OWNER_GROUP],
  };
}

export default SortVessel;
