import _fetch from '../fetch';
import plans from '../../components/Settings/plans';
import {cloneDeep} from "lodash";

let API_ENDPOINT = '/api/v1';
let HOST = '';
export function FetchException(message, status, url, params) {
  this.message = message;
  this.message.url = url;
  this.message.params = params;
  this.status = status;
  this.name = 'FetchException';
}
const commonHeaders = { 'content-type': 'application/json' };
const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:Z|(\+|-)([\d|:]*))?$/;

//const IS_BROWSER = typeof window !== 'undefined';
export function convertDatesFromUTC(o, prevKey) {
  Object.keys(o).forEach((key) => {
    const v = o[key];
    if (o.loadingPort && (!o.loading || !o.loading[0] || !o.loading[0].oneOf || !o.loading[0].oneOf[0] || !o.loading[0].oneOf[0].port)) { //remove after backend multiport migration
      o.loading = [{
        oneOf: [{
          port: o.loadingPort,
          details: o.loadingPortDetails,
          readinessDate: o.readinessDate,
          cancellingDate: o.cancellingDate,
        }],
      }]
    }
    if (o.unloadingPort && (!o.unloading || !o.unloading[0] || !o.unloading[0].oneOf || !o.unloading[0].oneOf[0] || !o.unloading[0].oneOf[0].port)) {
      o.unloading = [{
        oneOf: [{
          port: o.unloadingPort,
          details: o.unloadingPortDetails,
        }],
      }]
    }
    if(o.typeByPurpose && o.type && o._id){ //hack, assume that o is a vessel and construct new type
      o.type =  o.typeByPurpose;
    }
    const pts = Object.prototype.toString.call(v);
    if (pts === '[object Object]' || pts === '[object Array]') {
     /* if ((key === 'loadingPort' || key === 'unloadingPort' || key === 'open') && v.code && v.name) {
        v.name = v.name + ' (' + v.code + ')';
      }*/
      return convertDatesFromUTC(v, key);
    } else if (pts === '[object String]' && reISO.exec(v)) {
      const localDate = new Date(v);
      if (key === 'end' || key === 'endAt' || key === 'sentAt' || prevKey === 'readState') {
        o[key] = localDate;
        return;
      }
      o[key] = new Date(
        localDate.getTime() + (localDate.getTimezoneOffset() * 60000),
      );
    }
  });
  return o;
}

export function convertToSameTimeZ(o) {
  Object.keys(o).forEach((key) => {
    const v = o[key];
    const pts = Object.prototype.toString.call(v);
    if (pts === '[object Object]' || pts === '[object Array]') {
      convertToSameTimeZ(v);
    } else if (v instanceof Date) {
      o[key] = new Date(
        v.getTime() - (v.getTimezoneOffset() * 60000),
      );
    }
  });
  return o;
}

export function convertFromUTC(o) {
  Object.keys(o).forEach((key) => {
    const v = o[key];
    const pts = Object.prototype.toString.call(v);
    if (pts === '[object Object]' || pts === '[object Array]') {
      convertToSameTimeZ(v);
    } else if (v instanceof Date) {
      o[key] = new Date(
        v.getTime() + (v.getTimezoneOffset() * 60000),
      );
    }
  });
  return o;
}

const pending = [];
const loadingHandlers = [];
const errorHandlers = [];
export function onLoading(fn) {
  loadingHandlers.push(fn);
}
export function offLoading(fn) {
  const index = loadingHandlers.indexOf(fn);
  if (index === -1) {
    return;
  }
  loadingHandlers.splice(index, 1);
}

export function onError(fn) {
  errorHandlers.push(fn);
}
export function offError(fn) {
  const index = errorHandlers.indexOf(fn);
  if (index === -1) {
    return;
  }
  errorHandlers.splice(index, 1);
}

function _onError(e) {
  setTimeout(() => {
    errorHandlers.forEach(fn => fn.call(null, e));
  }, 0);
}

function debounce(fn, wait) {
  let timeout;
  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(fn, wait);
  };
}

const notifyLoading = debounce(() => {
  loadingHandlers.forEach(fn => fn.call(null, pending.length));
}, 300);

function handleLoadingStart(key) {
  pending.push(key);
  notifyLoading();
}
function handleLoadingEnd(key) {
  const index = pending.indexOf(key);
  if (index !== -1) {
    pending.splice(index, 1);
    notifyLoading();
  }
}

export async function fetch(url, params = { method: 'GET', ignoreError: false, withHeaders: false }, endpoint, retry = 0) {
  const key = url + '|' + Date.now();
  handleLoadingStart(key);
  try {
    params.credentials = 'include';
    params.headers = params.headers || { ...commonHeaders };
    params.headers = {...params.headers };
    if (params.token) {
      params.headers.cookie = params.token.replace('JWT ', 'token=') + ';';
    }
    const response = await _fetch(url.substring(0, 4) === 'http' ? url : HOST + (endpoint || API_ENDPOINT) + url, params);
    const contentType = response.headers.get('content-type');
    const headers = response.headers;
    let data;
    if(response.status === 204) {
      return {};
    }
    switch (contentType) {
      case 'application/pdf':
        const blob = await response.blob();
        const filename = getFileNameFromResponse(response, 'shipnext-calculator');
        downloadBlob(filename, blob, contentType);
        data = {};
        /*const file = new File([blob], 'calculator.pdf', { lastModified: Date.now() });
        data = { url: URL.createObjectURL(file };*/
        break;
      default:
        data = await response.json();

    }
    if (response.status > 399) {
      if(params.ignoreError) {
        return;
      }
      if (retry) {
        return fetch(url, params, endpoint, retry - 1);
      }
      const e = new FetchException(
        data,
        response.status,
        url,
        JSON.stringify(params),
      );
      _onError(e);
      throw e;
    }
    if (params.withHeaders) {
        data.headers = headers;
      }
    try {
      convertDatesFromUTC(data);
      return data;
    } catch (e) {
      console.error(e);
    }
    return data;
  } catch (e) {
    if (retry) {
      return fetch(url, params, endpoint, retry - 1);
    } else {
      _onError(e);
      throw e;
    }
  } finally {
    handleLoadingEnd(key);
  }
}
export function setEndpoint(host) {
  HOST = host;
}
function encodeParams(params) {
  if (!params) {
    return '';
  }
  return (
    '?' +
    Object.keys(params)
      .map(f => (encodeURIComponent(f) + '=' + params[f]))
      .join('&')
  );
}
export default class Api {
  constructor(data) {
    Object.assign(this, data);
  }
  static fetch = fetch;
  static getAll = async function (params) {
    return await fetch(this.endpoint + encodeParams(params));
  };
  static find = async function (params = {}, apiBase, retryRequests = 3) {
    return await fetch(this.endpoint + '/find', {
      method: 'POST',
      body: JSON.stringify(params),
    }, apiBase, retryRequests);
  };

  static get = async function (id, params) {
    return await fetch(this.endpoint + '/' + id + encodeParams(params));
  };

  save = async function (params) {
    return await fetch(this.constructor.endpoint + encodeParams(params), {
      method: 'POST',
      body: JSON.stringify(this.toJSON()),
    });
  };
  fetch = fetch;
}

export class User extends Api {
  // constructor(data) {
  //   super(data);
  // }

  static endpoint = '/user';
  _id;
  _fullName;
  _email;
  _password;
  _company;
  _organization;
  _phone;
  _site;
  _address;
  _city;
  _country;

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get fullName() {
    return this._fullName || 'Your Name';
  }

  set fullName(value) {
    this._fullName = value;
  }

  get email() {
    return this._email;
  }

  set email(value) {
    this._email = value;
  }

  get password() {
    return this._password;
  }

  set password(value) {
    this._password = value;
  }

  get company() {
    return this._company;
  }

  set company(value) {
    this._company = value;
  }

  get organization() {
    return this._organization;
  }

  set organization(value) {
    this._organization = value;
  }

  get phone() {
    return this._phone;
  }

  set phone(value) {
    this._phone = value;
  }

  get site() {
    return this._site;
  }

  set site(value) {
    this._site = value;
  }

  get address() {
    return this._address;
  }

  set address(value) {
    this._address = value;
  }

  get city() {
    return this._city;
  }

  set city(value) {
    this._city = value;
  }

  get country() {
    return this._country;
  }

  set country(value) {
    this._country = value;
  }

  static register = async function (params) {
    params.country = (params.country && params.country._id) || params.country;
    params.city = (params.city && params.city._id) || params.city;
    const user = await fetch('/auth/sign_up', {
      method: 'POST',
      body: JSON.stringify(params),
      headers: { 'content-type': 'application/json' },
    });
    // user = new User(user);
    if (typeof window !== 'undefined') {
      window.__user = user;
    }
    return user;
  };

  static login = async function (email, password) {
    let user = await fetch('/auth/sign_in', {
      method: 'POST',
      body: JSON.stringify({ email, password }),
    });
    try {
       user = await User.profile();
    } catch (e) {
      console.error(e);
    }
    // user = new User(user);
    if (typeof window !== 'undefined') {
      window.__user = user;
    }
    return user;
  };
  static changePassword = async function (params) {
    return await fetch('/users/password', {
      method: 'POST',
      body: JSON.stringify(params),
    });
  };

  static profile = async function (token) {
    const userRes = await fetch('/users/profile', {
      method: 'GET',
      token,
    });

    try {
      userRes.data.accountPreferences = await User.getAccountPreferences(token);
    } catch (e) {
      console.error(e);
    }
    try {
      userRes.data.signature = (await User.getUserSignature(token)).data;
    } catch (e) {
      console.error(e);
    }
    userRes.data.accountPreferences = userRes.data.accountPreferences || {};
    if (typeof window !== 'undefined') {
      window.__user = userRes;
    }
    return userRes;
  };

  static async getAccountPreferences(token) {
    try {
      const preferencesRes = await fetch('/preferences/singleByType/accountPreferences', {
        method: 'GET',
        token,
      });
      if (preferencesRes?.data?.accountPreferences) {
        if (!preferencesRes.data.accountPreferences.marketingDialogs) {
          preferencesRes.data.accountPreferences.marketingDialogs = [];
        }
        return preferencesRes.data.accountPreferences;
      }
    } catch (e) {
      if (e.status === 404) {
        return { marketingDialogs: [] };
      }
    }
  }

  static async getAccountFilters(token) {
      return this.fetch(`/preferences/byType/lastFilters`, {
        method: 'GET',
        token,
      }, '/api/v1', 3);
  }

  static async saveAccountFilters(body) {
    const getFiltersRes = await this.getAccountFilters();
    let lastFilters = getFiltersRes.data[0]?.lastFilters || {};
    Object.keys(body).forEach(key => { body[key].filterKey = key; });
    lastFilters = { ...lastFilters, ...body };
    lastFilters = convertToSameTimeZ(cloneDeep(lastFilters));
    return this.fetch(`/preferences/lastFilters`, {
      method: 'POST',
      body: JSON.stringify(lastFilters),
    });
  }

  static async saveAccountPreferences(partial) {
    const accountPreferences = await User.getAccountPreferences();
    return await fetch('/preferences/accountPreferences', {
      method: 'POST',
      body: JSON.stringify({ ...accountPreferences, ...partial }),
    });
  }
  static async sendIssueReport(report) {
    return await fetch('/issues', {
      method: 'POST',
      body: JSON.stringify(report),
    }, '/api/v2');
  }

  static async getUserSignature(token) {
    return fetch('/signature/', { method: 'GET', token }, '/api/v2');
  }

  static async editUserSignature(params) {
    return fetch('/signature/', {
      method: 'PUT',
      body: JSON.stringify(params),
      headers: { 'content-type': 'application/json' },
    }, '/api/v2');
  }
  static async deleteUserSignature() {
    return fetch('/signature/', { method: 'DELETE' }, '/api/v2');
  }

  static statistic = async function(token, page, numberOfRows) {
    const stats = await fetch(`/tracker/userStats?page=${page}&limit=${numberOfRows}`, {
      method: 'GET',
    }, '/api/v2')
    if (typeof window !== 'undefined') {
      window.__apiStats = stats;
    }
    return stats;
  }

  static adminStatistics = async function(page, numberOfRows) {
    const stats = await fetch(`/tracker/myIntegratorUserStats?page=${page}&limit=${numberOfRows}`, {
      method: 'GET',
    }, '/api/v2');
    return stats;
  }

  static logout = async function () {
    return await fetch('/users/logout', {
      method: 'GET',
    });
  };

  static updateProfile = async function (params) {
    const user = await fetch('/users/update', {
      method: 'PUT',
      body: JSON.stringify(params),
      headers: { 'content-type': 'application/json' },
    });
    if (typeof window !== 'undefined') {
      window.__user = user;
    }
    return user;
  };

  static requestForgotLink = async function (params) {
    return await fetch('/auth/forgot-password', {
      method: 'POST',
      body: JSON.stringify(params),
    });
  };

  static changeForgotPassword = async function (params) {
    return await fetch('/auth/forgot-password', {
      method: 'PUT',
      body: JSON.stringify(params),
    });
  };
  static getAccounts = async function () {
    return await fetch('/departments', {
      method: 'GET',
    }, '/api/v2');
  };
  static upsertAccount = async function (account, companyId) {
    if (account._id) {
      return await fetch(`/departments/${account._id}`, {
        method: 'PUT',
        body: JSON.stringify(account),
      },'/api/v2');
    } else {
      return await fetch(`/departments`, {
        method: 'POST',
        body: JSON.stringify(account),
      },'/api/v2');
    }
  };

  static syncKycAccount = async function (data,departmentId) {
    return await fetch(`/departments/${departmentId}`, {
      method: 'PUT',
      body: JSON.stringify({...data,syncKycAccount:true}),
    },'/api/v2');
  }

  static deleteAccount = async function (accountId) {
    return await fetch(`/departments/${accountId}`, {
      method: 'DELETE',
    },'/api/v2');
  };

  static approveMember = async function (member, companyId) {
    return await fetch(`/companies/${companyId}/members/${member._id}/approve`, {
      method: 'PUT',
    });
  };

  static deleteMember = async function (member, companyId) {
    return await fetch(`/companies/${companyId}/members/${member._id}/disapprove`, {
      method: 'PUT',
    });
  };

  static validateEmail = function (email, corporate) {
    return fetch('/auth/validate', { method: 'POST', body: JSON.stringify({ email, corporate }) });
  };
  static confirmEmail = function (token) {
    return fetch('/auth/confirm-email/' + token, { method: 'GET' });
  };
  static requestConfirmEmail = function () {
    return fetch('/auth/confirm-email', { method: 'GET' });
  };
  static unsubscribe = function (token) {
    return fetch('/delivery/unsubscribe/' + token, { method: 'GET' });
  };

  static sendFeedback = function (feedback) {
    return fetch('/feedback', { method: 'POST', body: JSON.stringify(feedback) });
  };
  static getLimitations = function (token) {
    return fetch('/users/profile/limitation', { method: 'GET', token });
  };

  static updateWhitelist = function (emails) {
    return fetch('/users/profile/limitation/white', { method: 'PUT', body: JSON.stringify({ emails }) });
  };
  static updateBlacklist = function (emails) {
    return fetch('/users/profile/limitation/black', { method: 'PUT', body: JSON.stringify({ emails }) });
  };
  static toggleLimitations = function (data) {
    return fetch('/users/profile/limitation/toggle', { method: 'POST', body: JSON.stringify(data) });
  };
  static acceptGDPR = async function () {
    return fetch('/users/acceptGDPR', { method: 'PUT' });
  };
  static selfDestroy = async function () {
    return fetch('/users/profile', { method: 'DELETE' });
  };

  static async uploadImage(request = {}, opts = {}) {
    return fetch(`/file/upload?${opts.privateAttachment ? 'private=1' : ''}`, {
      headers: {},
      method: 'POST',
      body: request,
    });
  }
  static async getCounters() {
    return fetch('graphql', {
      method: 'POST',
      body: '{"query":"{counters{cargoBids,cargoTcBids,vesselBids,vesselTcBids,contractBids,total}}"}',
    }, '/');
  }

  static async getInviteInfo(key, token) {
    return fetch('/actionBlobs/' + key, {
      headers: {},
      method: 'GET',
    });
  }

  static async extendConnectorSubscription() {
    return fetch('/extension/enableExtensionAccess', {
      headers: {},
      method: 'POST',
    });
  }

  static async setConnectorEmail(token) {
    return fetch(`/extension/changeExtensionEmail?googleId=${encodeURIComponent(token)}`, {
      headers: {},
      method: 'PUT',
    });
  }
  static async getPaymentPackages() {
    return fetch('/subscribe/plans/', {
      method: 'GET',
    });
  }

  static async subscribe(planId){
    return fetch('/subscribe', {
      method: 'POST',
      body: JSON.stringify( { plan: planId }),
      headers: { 'content-type': 'application/json' },
    });
  }
  static async cancelSubscription(){
    return fetch('/subscribe/cancel', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
    });

  }
  static async changeSubscription(plan){
    return fetch('/subscribe/change', {
      method: 'POST',
      body: JSON.stringify( { plan: plan.id || plan }),
      headers: { 'content-type': 'application/json' },
    });
  }

  static async getPaymentStatus(id, token) {
    return fetch('/subscribe/transaction/' + id, {
      method: 'GET',
      token,
    });
  }

  static async getMailGateCompanyUsers() {
    return fetch('/users/mailGates', {
      method: 'GET',
    });
  }

  static async getMailGateCompanyUserInfo(gateId) {
    return fetch(`/users/mailGates/${gateId}`, {
      method: 'GET',
    });
  }

  static async updateMailGateCompanyUserInfo(gateId = '', request = {}) {
    return fetch(`/users/mailGates/${gateId}`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: { 'content-type': 'application/json' },
    });
  }

  static async deleteMailGateCompanyUserInfo(gateId) {
    return fetch(`/users/mailGates/${gateId}`, {
      method: 'DELETE',
    });
  }

  static async getCompanyUsers() {
    return fetch('/companies/users', {
      method: 'GET',
    });
  }

  static async changeMailGateCompanyUserDetails(gateId, request) {
    return fetch(`/users/mailGates/${gateId}/setOption`, {
      method: 'POST',
      body: JSON.stringify(request),
      headers: { 'content-type': 'application/json' },
    });
  }
  static async generateInvoice({ invoiceType, companyName, companyAddress, duration, accounts }){
    return fetch('/invoices/', {
      body: JSON.stringify({ invoiceType, companyName, companyAddress, duration, accounts }),
      method: 'POST',
    });
  }

  static async getFreeAccess(queryParams) {
    return fetch('/subscribe/free?' + queryParams, {
      method: 'POST',
    });
  }

  static async getAccount() {
    return fetch('/subscribe/account', {
      method: 'GET',
    });
  }

  static async getInvoices() {
    return fetch('/subscribe/invoices', {
      method: 'GET',
    });
  }

  static async getPendingInvoices(){
    return fetch('/subscribe/pendingState', {
      method: 'GET',
    });
  }
  static async getCurrentPlan(user) {
    if(!user || !user.activeProducts || (user.activeProducts.indexOf("connect:free") !== -1 && user.activeProducts.indexOf("shipnext:free") !== -1)) {
      return plans.free;
    }
    let res;
    res = await User.getAccount();
    const account = res.data;
    let currentPlan = plans.free;
    let stripePlan = account?.plans?.[0];
    if(stripePlan) {
      stripePlan.key = stripePlan.products.join();
      stripePlan.realMoPrice = stripePlan.amount * 0.01;
      stripePlan.realAmount = stripePlan.amount * 0.01;
      if(stripePlan.interval === 'year') {
        stripePlan.realMoPrice /= 12;
        stripePlan.perShort = 'yr';
      }else{
        stripePlan.perShort = 'mo';
      }
      currentPlan = {...plans[stripePlan.key]};
      currentPlan.stripePlan = stripePlan;

    }
    return currentPlan
  }

  static async changePaymentCard(){
    return fetch('/subscribe/paymentMethod', {
      method: 'POST',
    });
  }
  static async unsubscribeSurvey(request){
    return fetch('/subscribe/unsubscribe-survey', {
      method: 'POST',
      body: JSON.stringify(request),
      headers: { 'content-type': 'application/json' },
    });
  }


  static getPlanByProducts(activeProducts){
    const key = activeProducts.filter(p => p.indexOf(':paid') !== -1).map(p => p.replace(':paid','').replace('shipnext','marketplace')).join(',');
    return plans[key] || plans.free;
  }

  static async getMailGatesState(){
    return fetch('/users/mailGates/state', { method: 'GET' });
  }

  static async getSedna() {
    return fetch('/sedna-connect/connect', { method: 'GET' }, '/api/v2');
  }
  static async createSedna(params) {
    return fetch('/sedna-connect/connect', { method: 'POST', body: JSON.stringify(params) }, '/api/v2');
  }
  static async editSedna(userId, params) {
    return fetch(`/sedna-connect/connect?userId=${userId}`, { method: 'PUT', body: JSON.stringify(params) }, '/api/v2');
  }
  static async deleteSedna(userId) {
    return fetch(`/sedna-connect/${userId}`, { method: 'DELETE' }, '/api/v2');
  }

  get = async function () {
    return await User.get(this._id);
  };

  toJSON() {
    return {
      _id: this.id,
      fullName: this.fullName,
      email: this.email,
      password: this.password,
      company: this.company,
      organization: this.organization,
      phone: this.phone,
      site: this.site,
      address: this.address,
      city: this.city,
      country: this.country,
    };
  }
}


function getFileNameFromResponse(response, fallback) {
  const contentDisposition = response.headers.get('Content-Disposition') || '';
  return window.decodeURIComponent(((/filename\*=utf-8'\w{0,2}'(.*)(;|$)/i.exec(contentDisposition) || [])[1] || (/filename="?([^"]*)"?(;|$)/i.exec(contentDisposition) || [])[1] || fallback));
}

function downloadBlob(filename, data, type) {
  let blob = new Blob([data], { type });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    let elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
    URL.revokeObjectURL(elem.href);
  }
}
