import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DialogHalfScreen from '../Common/DialogHalfScreen';
import { Pane } from '../Common/SplitPane';
import ComposeReply from './MailFlow/ComposeReply';
import { v4 } from 'uuid';
import { connect } from 'react-redux';
import { sendNewMessage } from '../../actions/emails';
import { mapChipsToEmail } from './utils';
import { getMailGatesState } from "../../actions/login";
import Loader from '../Common/Loader';
import ConfirmDialog from "../Common/ConfirmDialog";
import {sleep} from "../../core/utils";

const StylesWrapper = styled.div`
  padding: 8px 20px;
`;

const composeHeadersProps = { subjectProps: { disabled: false } };

const paneBodyProps = { style: { height: '100%' }, className: "scroll-container-new" };

class ComposeEmailDialog extends Component {
  static contextTypes = {
    blockTransition: PropTypes.func,
  }
  state = {};

  static defaultProps = {
    transitionLockerPredicate: (location, action) => location.pathname.indexOf(`/compose/`) === -1,
    shouldConfirmClose: false,
  }
  constructor(props) {
    super(props);
    this.state.replyTo = { id: v4(), to: [], cc: [], bcc: [], replyTemplate: { to: [], cc: [], bcc: [], replyGates: this.props.mailGatesState?.senderGates, ...this.props.composeProps } };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.mailGatesState !== nextProps.mailGatesState) {
      this.setState({
        replyTo: {
          ...this.state.replyTo,
          replyTemplate: { ...this.state.replyTo.replyTemplate, replyGates: nextProps.mailGatesState?.senderGates },
        },
      });
    }
  }

  componentWillUnmount() {
    if (this.historyBlocked) {
      this.historyBlocked = false;
      this.unblock();
    }
  }

  handleClose = () => {
    if (this.historyBlocked && this.props.shouldConfirmClose) {
      this.setState({
        confirmDialog: (<ConfirmDialog
          title="DATA IS NOT SAVED"
          open
          handleClose={(confirm) => {
            this.setState({ confirmDialog: undefined });
            if (confirm) {
              this.props.onClose();
            }
          }}
        >
          <span>Are you sure you want to leave this page?</span>
        </ConfirmDialog>),
      });
    } else {
      this.props.onClose();
    }
  }

  handleHtmlChange = (html, delta, source) => {
    this.setState({ html });
    if (!this.historyBlocked && this.context.blockTransition && source !== 'silent') {
      this.historyBlocked = true;
      this.unblock = this.context.blockTransition('Are you sure you want to leave this page?', this.props.transitionLockerPredicate);
    }
  }
  refComposeReply = (el: ComposeReply) => {
    this.composeReply = el;
  }

  handleSend = async ({ from, to, cc, bcc, replyTo, subject }) => {
    await sleep(600);
    const div = document.createElement('div');
    div.innerHTML = this.state.html;
    const text = div.textContent;
    const content = this.state.html;
    const cargoId = this.props.cargoId;
    const vesselId = this.props.vesselId;
    const res = await this.props.sendNewMessage({
      subject: subject,
      content,
      text,
      cargoId,
      vesselId,
      attachments: this.composeReply.getUploadedFiles().map(f => f._id),
      to: mapChipsToEmail(to),
      cc: mapChipsToEmail(cc),
      bcc: mapChipsToEmail(bcc),
      shouldReplyTo: mapChipsToEmail(replyTo),
      replyGate: from,
    });
    if (res instanceof Error || res.status > 399) {
      this.props.getMailGatesState()
      return res;
    }
    this.handleClear();
    return res;
  }
  handleClear = (e, keepAttachments = false) => {
    this.setState({ html: "" });
    if (this.historyBlocked) {
      this.historyBlocked = false;
      this.unblock();
    }
    if (!keepAttachments) {
      this.composeReply.clearUploadedFiles();
    }
  }

  render() {
    return (
      <DialogHalfScreen open position={this.props.position} noEddie >
        <Pane headerProps={{ onClose: this.handleClose, children: <b>NEW MESSAGE</b>, useBorder: true }} bodyProps={paneBodyProps}>
          <StylesWrapper>
            {this.props.mailGatesState?.senderGates ?
              (<ComposeReply
                text={this.state.html}
                replyTo={this.state.replyTo}
                onTextChange={this.handleHtmlChange}
                ref={this.refComposeReply}
                onClearReply={this.handleClear}
                onSendReply={this.handleSend}
                headersProps={composeHeadersProps}
                mailGatesState={this.props.mailGatesState}
                user={this.props.user}
                scrollingContainer={".scroll-container-new"}
              />)
              : <Loader /> }
          </StylesWrapper>
        </Pane>
        {this.state.confirmDialog}
      </DialogHalfScreen>
    );
  }
}

export default connect(state => ({ mailGatesState: state.login.mailGatesState, user: state.login.user }), { sendNewMessage, getMailGatesState })(ComposeEmailDialog);
