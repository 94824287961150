exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._35FX7, ._3ldAs {\n  width: 100%;\n  height: 100%; }\n\n._35FX7 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-direction: column;\n      flex-direction: column;\n  -ms-flex-align: center;\n      align-items: center;\n  background-color: white; }\n\n._3FvBm {\n  margin-right: 32px;\n  color: #4D9E0E;\n  font-size: 12px;\n  line-height: 14px; }\n\n._3FvBm > div:nth-child(1) {\n  background-color: #EDEDED;\n  display: inline-block;\n  border-radius: 50%;\n  margin-right: 6px; }\n\n._3FvBm svg {\n  fill: #4D9E0E !important;\n  width: 20px !important;\n  height: 20px !important; }\n\n._3K8KW {\n  padding: 0 16px 16px 16px; }\n\n.dZ2ua {\n  width: 100%;\n  min-width: 320px;\n  padding-top: 25px; }\n\n.dZ2ua a {\n    font-size: 12px; }\n\n.dZ2ua ._2sjBq {\n    height: 70px;\n    margin-top: -13px; }\n\n.dZ2ua ._2sjBq ._1dLcr {\n      padding-top: 3px;\n      position: relative;\n      z-index: 9;\n      font-size: 12px;\n      line-height: 14px;\n      display: block;\n      width: 50px;\n      color: var(--text-green-dark); }\n\n._3CfzY {\n  font-weight: bold;\n  line-height: 23px;\n  font-size: 18px;\n  padding-top: 32px;\n  color: #333333; }\n\n.wR-Bv {\n  margin-top: 30px;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: end;\n      justify-content: flex-end; }\n\n.wR-Bv > div + div {\n  margin-left: 8px; }\n\n._2EPRa {\n  position: relative;\n  z-index: 8; }\n\n.ZDNd_ > div > div > div {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap; }\n\n.Zjc7N {\n  border-radius: 50px !important;\n  overflow: hidden; }\n\n.Zjc7N button {\n    border-radius: 50px !important; }\n", ""]);

// Exports
exports.locals = {
	"root": "_35FX7",
	"container": "_3ldAs",
	"add_position_button": "_3FvBm",
	"add_position": "_3K8KW",
	"add_position_container": "dZ2ua",
	"vessel_autocomplete_wrapper": "_2sjBq",
	"add_ship_link": "_1dLcr",
	"add_position_title": "_3CfzY",
	"add_position_buttons": "wR-Bv",
	"menuitem_name_ship": "_2EPRa",
	"vessel_name_long": "ZDNd_",
	"rounded_btn": "Zjc7N"
};