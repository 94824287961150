const leftMap = { USD: '$', EUR: '€', usd: '$', eur: '€' };

const numberFormaterCache = {
  2: { 0: new Intl.NumberFormat('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 0 }) },
}

const getNumberFormatter = (maximumFractionDigits = 2, minimumFractionDigits = 0) => {
  if (!numberFormaterCache[maximumFractionDigits]) {
    numberFormaterCache[maximumFractionDigits] = {};
  }
  if (!numberFormaterCache[maximumFractionDigits][minimumFractionDigits]) {
    numberFormaterCache[maximumFractionDigits][minimumFractionDigits] = new Intl.NumberFormat('en-US', { maximumFractionDigits, minimumFractionDigits });
  }
  return numberFormaterCache[maximumFractionDigits][minimumFractionDigits];
}
export function number(value, left = '', right = '', maximumFractionDigits = 2, minimumFractionDigits = 0, emptyValue = '---', rightDivider = ' ') {
  left = leftMap[left] || left;
  if (falsyValue(value)) return emptyValue;
  if (value === 0) {
    return emptyValue;
  }
  if (typeof value === 'number') {
    return (
      left +
      getNumberFormatter(maximumFractionDigits, minimumFractionDigits).format(value) + rightDivider +
      right
    );
  }
  return left + value + right;
}

export function formatNumberOrEmpty(value, emptyValue = null, left = '', right = '', maximumFractionDigits = 2, minimumFractionDigits = 0) {
  return number(value, left, right, maximumFractionDigits, minimumFractionDigits, emptyValue);
}

const numberRegex = /^[0-9]+.?[0-9]+$/;
export function formatStringAsNumber(str) {
  if (!str) {
    return str;
  }
  if (typeof str === 'number') {
    return formatNumberOrEmpty(str, '').trim();
  }
  if (str.match(numberRegex)) {
    return formatNumberOrEmpty(parseFloat(str), '').trim();
  }
  return str;
}

export const deleteEmptyStrings = obj => {
  if (!obj) return;
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      deleteEmptyStrings(obj[key]);
    }
    else if (typeof obj[key] === "string" && obj[key].length === 0) {
      delete obj[key]
    }
  })
}

const falsyValue = value => value === undefined || value === null || !isFinite(Number(value));

export const shortenThousands = (value, formatFn = val => val) => {
  const isThousand = value >= 1000;
  const val = isThousand ? value / 1000 : value;
  return formatFn(Number(val).toFixed(1), "", isThousand ? "k" : "");
}

export function numberFixed(value, left = '', right = '') {
  return number(value, left, right, 2, 2);
}

const DATE_OPTIONS = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
const DATE_SHORT_OPTIONS = { year: '2-digit', month: '2-digit', day: '2-digit'};
export function date(value) {
  return new Date(value).toLocaleDateString();
}

export function dateTime(value) {
  return new Date(value).toLocaleString([], DATE_OPTIONS) + ' GMT';
}
export function dateTimeUnshifted(value) {
  return new Date(value.getTime() + (value.getTimezoneOffset() * 60000)).toLocaleString([], DATE_OPTIONS) + ' GMT';
}
export function dateTimeShiftedToLocal(value) {
  return new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toLocaleString([], DATE_OPTIONS);
}
export function dateTimeLocal(value) {
  return new Date(value).toLocaleString([], DATE_OPTIONS);
}
export function dateLocalTwoDigit(value) {
  if (!value) {
    return '';
  }
  return new Date(value).toLocaleString([], {
    year: '2-digit', month: '2-digit', day: '2-digit',
  });
}

export function dateShortLocal(value) {
  if(!value) {
    return "";
  }
  return new Date(value).toLocaleString([], DATE_SHORT_OPTIONS);
}
export function dateISOLocal(value) {
  if(!value || !(value instanceof Date)) {
    return "";
  }
  return `${value.getFullYear()}-${padNumber(value.getMonth() + 1)}-${padNumber(value.getDate())}`;
}

export function dateString(date) {
  if(!date){
    return '';
  }
  const month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let month = month_names[date.getMonth()];
  let day = ('0' + date.getDate()).slice(-2);


  return `${day} ${month}, ${date.getFullYear()}`;
}

export function dateStringFirstMonth(date) {
  if(!date){
    return '';
  }
  const month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  let month = month_names[date.getMonth()];
  let day = ('0' + date.getDate()).slice(-2);


  return `${month} ${day}, ${date.getFullYear()}`;
}

export function dateStringWithTime(date) {
  const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const day = ('0' + date.getDate()).slice(-2);
  //let time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let meridiem = 'am';
  if (hours > 12) {
    hours = hours - 12;
    meridiem = ' pm';
  }
  else if (hours === 0){
    hours = 12;
  }
  if (minutes < 10) {
    minutes = "0" + minutes.toString()
  }
  return `${day} ${MONTH_NAMES[date.getMonth()]} ${hours}:${minutes}${meridiem}`;
}
export function dateStringOrTime(date) {
  const localeTodayMidnight = new Date().setHours(0, 0, 0, 0);
  const localeDateToCompare = new Date(date.toString().split(' ').slice(0, 5).join(' ') + ' GMT');
  const hours = localeDateToCompare.getHours();
  const minutes = ('0' + localeDateToCompare.getMinutes()).slice(-2);

  if (localeTodayMidnight > localeDateToCompare) {
    return dateShortLocal(date);
  }
  return `${hours}:${minutes}`;
}

export function dateWithTime(date) {
  let hours = new Date(date).getHours();
  let minutes = new Date(date).getMinutes();
  if (hours < 10) {
    hours = "0" + hours.toString()
  }
  if (minutes < 10) {
    minutes = "0" + minutes.toString()
  }
  return `${new Date(date).toLocaleDateString()} ${hours}:${minutes}`;
}
const H24 = 24 * 60 * 60 * 1000;

export function leftHoursFromSeconds(seconds, expiredValue = 'expired') {
  const m = seconds / 60;
  let hours = Math.floor(m / 60);
  const minutes = Math.floor(m % 60);
  if (minutes < 0 || hours < 0) {
    return expiredValue;
  }

  if(hours > 96) {
    const days = Math.floor(hours/ 24);
    hours =  hours % 24;
    return `${days}days ${hours}hr`;
  }
  return hours + 'hr ' + ('0' + minutes).slice(-2) + 'min';
}

export function leftHours(value) {
  let now = new Date();
  now = new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
  const seconds = ((new Date(value).getTime() + H24) - now.getTime()) / 1000;
  return leftHoursFromSeconds(seconds);
}

export function leftHoursFromMillis(millis, expiredValue) {
  if (!millis) {
    return '';
  }
  // const now = (new Date()).getTime() + ((new Date()).getTimezoneOffset()*60000);
  const now = new Date().getTime();
  return leftHoursFromSeconds((millis - now) / 1000, expiredValue);
}

export function elapsedHoursFromMillis(millis) {
  if (!millis) {
    return '';
  }
  const now = new Date();
  millis -= (now.getTimezoneOffset() * 60000);
  return leftHoursFromSeconds((now.getTime() - millis) / 1000, '0hr 0min');
}

const yesNoMap = {
  true: 'Yes',
  false: 'No',
  undefined: '',
};

export function yesNo(value, truthy, falsy) {
  if (value && truthy) {
    return truthy;
  }
  if (!value && falsy) {
    return falsy;
  }
  return yesNoMap[value];
}

export function dateRange(f, t, formatter = date) {
  if(!f && !t) {
    return;
  }
  const from = new Date(f);
  const to = new Date(t);
  if (from.getFullYear() === to.getFullYear() &&
     from.getMonth() === to.getMonth() &&
     from.getDate() === to.getDate()
  ) {
    return formatter(from);
  }
  return (formatter(from) + '\u00A0-\u00A0' + formatter(to)).replace(/\d{2}(\d{2,2})/g, '$1');
}

export function address(company) {
  if (!company) {
    return '';
  }
  let ret = ((company.country && (company.country.name + ', ')) || '') + ((company.city && (company.city.name + ', ')) || '');
  ret += company.address || '';
  return ret;
}

export function handleCapitalize (str = '') {
  if(!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export function percent(value = 0){
  return getNumberFormatter().format(value) + '%';
}

const timeUnitsMap = {perDay:'Per day', perDayProRata:'Per day pro rata', perHour: 'Per hour'};
export function timeUnit(value) {
  return timeUnitsMap[value] || value;
}

export function getDemmurageTimeOpts() {
  return Object.entries(timeUnitsMap).map(tu=> ({_id: tu[0], label: tu[1]}))
}

const dispathMap = { "": "", halfDispatch:"Dispatch at half demurrage",fullDispatch:"Full dispatch", freeDispatch:"Free of dispatch"}

export function getDemmurageTermsOpts() {
  return Object.entries(dispathMap).map(t=> ({_id: t[0], label: t[1]}))
}
export function dispatchHr(value) {
  return dispathMap[value] || value;
}

export function fullName(user){
  return (`${user.name || ''} ${user.surname || ''}`).trim() || user.company?.name || user.email;
}



const subscriptionStatuses = {
  trialing:'Trialing',
  active:'Active',
  incomplete:'Incomplete',
  incomplete_expired:'Incomplete expired',
  past_due:'Past due',
  canceled:'Canceled',
  unpaid:'Unpaid',
}

export function subscriptionStatus(status) {
 return subscriptionStatuses[status] || status;
}


const _numberFormatterCache = {};
export function formatNumber(value, left = '', right = '', fractionDigits = 2) {
  left = leftMap[left] || left;
  if (value === undefined || value === null || !isFinite(Number(value))) {
    return '---';
  }
  if (value === 0) {
    return '---';
  }
  value = Number(value);
  try {
    let formatter = _numberFormatterCache[fractionDigits];
    if (!formatter) {
      formatter = new Intl.NumberFormat('ru-RU', {
        minimumFractionDigits: 0,
        maximumFractionDigits: fractionDigits,
      });
      _numberFormatterCache[fractionDigits] = formatter;
    }
    value = formatter.format(value);
  } catch (e) {
  }
  return left + value + right;
}

export const renderSeparatedRow = (obj, transformFunc = item => item ? item : '---') => {
  if (!obj || typeof obj !== 'object') return null;
  return Object.values(obj).map(value => transformFunc(value)).join(' / ');
}

export const isFalsyObj = obj => {
  if (!obj || typeof obj !== 'object') return true;
  return Object.values(obj).every(value => !value);
}

export const isObjHasBoolean = obj => {
  if (!obj || typeof obj !== 'object') return false;
  return Object.values(obj).some(value => typeof value === 'boolean');
}

export function fittedToString(name, value) {
  if (typeof value !== 'boolean') {
    return null;
  }
  return '• ' + name + (value ? ' fitted' : ' not fitted');
}

export function atDateTime(d) {
  if (!d) {
    return '';
  }
  return (new Date(d)).toLocaleString('ru').replace(/\./g,'-').replace(', ', ' at ').slice(0,19);
}

export function padNumber(n, m = 2) {
  return ((new Array(m).fill('0').join('')) + n).slice(-1 * m);
}

export function degToDMS(deg, labels){
  let label = labels[1];
  if(deg < 0) {
    label = labels[0];
  }
  deg = Math.abs(deg);
  const d = parseInt(deg,10);
  const m = parseInt((deg - d) * 60, 10);
  const s = parseInt((((deg - d) * 60) - m) * 60, 10);
  return `${padNumber(d,3)}° ${padNumber(m)}' ${padNumber(s)}" ${label}`;
}

export function pointToDMS(point){
  if(!point?.coordinates) {
    return '';
  }
 const lng = point.coordinates[0];
 const lat = point.coordinates[1];
  return `${degToDMS(lat, ['S', 'N'])} / ${degToDMS(lng, ['W', 'E'])}`;
}


export function formatRange(value1, value2, separator = ' - ', formatter = (val) => val) {
  if(!value1 && !value2) {
    return '';
  }
  if(!value1) {
    return formatter(value2);
  }
  if(!value2) {
    return formatter(value1);
  }
  if(value1 == value2){
    return '' + formatter(value1);
  }
  return formatter(value1) + separator + formatter(value2);
}

const typeByPurposeLabels = {
  'BULK': 'Bulker Carrier',
  'BULKTANK': 'Ore/Bulk/Oil Carrier',
  'CAR': 'Car Carrier',
  'CONT': 'Container Carrier',
  'LIVESTOCK': 'Livestock Carrier',
  'MPP': 'Multipurpose and HeavyLift Carrier',
  'PASS': 'Passenger Carrier',
  'PASSCAR': 'Passenger Car Carrier',
  'REEFER': 'Reefer Carrier',
  'TANK': 'Liquid Bulk Carrier',
};

export function formatTypeByPurposePlural(t, count = 2){
  if (!t || !typeByPurposeLabels[t]) {
    return t;
  }
 if (count > 1) {
    return typeByPurposeLabels[t] + 's';
  }
 return typeByPurposeLabels[t];
}
export function dummyFormatter(val){
  return val;
}

export function escapeHtml(unsafe) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

const typeOfTransportationLabels= {
  breakbulk: "Breakbulk",
  bulk: "Dry bulk",
  container: "Containerized",
  wetbulk: "Wet bulk",
}

export function formatTypeOfTransportation(t){
  return typeOfTransportationLabels[t];
}

export const formatCargoTotalValues = (totalValues = {}) => {
  const qtyUnit = totalValues.containersCount > 1 ? 'units' : 'unit';
  const parts = [
    formatNumberOrEmpty(totalValues.containersCount, null, '', qtyUnit),
    formatNumberOrEmpty(totalValues.weight / 1000, null, '', 'mt'),
    formatNumberOrEmpty(totalValues.volume, null, '', 'm3'),
  ].filter(v => v);
  return parts.join(' / ');
};


export function formatCurrency(c) {
  return leftMap[c];
}
