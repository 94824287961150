/* @flow */
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './Monitor.scss';
//$FlowFixMe
import {dispatchHr, number, percent, timeUnit} from '../../core/format';
import {Bid, ChangedRequest} from '../../interfaces/types';
import {prepareOffer, prepareOfferForView} from './Offer/utils';
import Diff, {DeviationDiff} from '../Common/Diff';
import {CargoListView, RatePreview, Terms} from '../Cargo/Preview/Preview';
import {staticPopup} from '../../actions/runtime';
import ContractLink from '../Common/ContractLink';
import PolPodPreview from '../Cargo/Preview/PolPodPreview';
import Attachments from '../Cargo/Preview/Attachments';
import Commission from '../Common/Commission';
import {LDRatesView, Ship} from './../Documents/shared/preview';
import MultiFreightView from './Offer/MultiFreight/MultiFreightView';
import ChargesView from './Offer/Charges/ChargesView';
import Collapse from '../Common/Collapse';
import OriginalMessage from "./OriginalMessage";
import { connect } from 'react-redux';
import { setPortDialogId } from '../../actions/port';

type Props = {
  bid: Bid,
  cargo?: {[string]: any},
  vessel?: {[string]: any},
  commEditable?: boolean,
}
type State = {
  terms: any,
  offer:ChangedRequest,
  previousValues: ChangedRequest,
}



class OfferInMainDeck extends PureComponent<Props, State> {

  static defaultProps = {
    isVesselSide: false,
  };
  labels = { charterer: "Charterer/Merchant" };

  static contextTypes = {
    contracts: PropTypes.array,
    store: PropTypes.object,
    indexedVoyageTerms: PropTypes.array,
  };

  state = {};

  constructor(props:Props){
    super(props);
    const preparedOffer = prepareOfferForView(props.cargo || {}, props.bid, props.vessel||{}, this.props.user, false);
    this.state.offer = preparedOffer.offer;
    this.state.previousValues = preparedOffer.previousValues;
    // this.state.attachments = preparedOffer.offer.attachments;
    if (this.state.offer.cargo.length) {
      this.isWetbulk = this.state.offer.cargo[0].typeOfTransportation === 'wetbulk';
    }
    if (this.isWetbulk) {
      this.labels.charterer = "Charterer";
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.bid !== nextProps.bid) {
      const preparedOffer = prepareOfferForView(nextProps.cargo || {}, nextProps.bid,nextProps.vessel||{},this.props.user , false);
      this.setState(preparedOffer);
    }
  }

  openTermsDetails = (termsLink) => {
    this.context.store.dispatch(staticPopup(termsLink, true));
  };


  render() {
    const cv = this.state.offer;
    const pv = this.state.previousValues;
    const showChartererSubject = (cv.chartererSubject?.value || pv.chartererSubject?.value) || false;
    const showOwnerSubject = (cv.ownerSubject?.value || pv.ownerSubject?.value) || false;
    const withoutTrading = !this.props.bid.trading;
    const attachments = [...cv.files || [], ...cv.attachments || []];
    const additionalDetailsEditable = (withoutTrading && cv.addedByCompany) && !this.props.frRequest;
    const bid = this.props.bid;
    const tradingDiff = bid?.tradingDiff;
    return (
      <div className={cx(s.wrapper_offer, withoutTrading && s.without_trading)}>
        <div className={cx('row', s.main_details)}>
          {cv.message && <div className={cx("col preview_label", s.charterer)}>Message</div> }
          {cv.message && <div style={{whiteSpace: "pre-line"}} className={cx('col-12 col-md-auto preview_value grow', s.message)}>
            {cv.message}
          </div> }
          <div className="w-100" />
          { cv.clientRefNo ? [
            <div key={0} className={cx("col preview_label")}>Cargo Reference(s)</div>,
            <div key={1} className={cx('col-12 col-md-auto preview_value grow')}>
              {cv.clientRefNo}
            </div>,
          ] : null }
          <div className="w-100" />
          <div className={cx("col preview_label", s.charterer)}>{this.labels.charterer}</div>
          <div className={cx('col-12 col-md-auto preview_value grow',s.name_with_chat, s.charterer)}>
            <Diff newValue={cv.charterer?.name} oldValue={pv.charterer?.name}  />
          </div>
          <div className="w-100" />
          {showChartererSubject && <div className={cx("col preview_label", s.subjects)}>SUBJECTS TO BE LIFTED BY</div> }
          {showChartererSubject && (
            <div className={cx('col-12 col-md-auto preview_value grow', s.subjects)}>
              <Diff
                newValue={cv.chartererSubject.value}
                oldValue={pv.chartererSubject && (pv.chartererSubject.value || '')}
              />
              h
            </div>
          )}
          <div className="w-100" />
          <div className={cx("col preview_label", s.owner)}>Owner/Carrier</div>
          <div className={cx('col-12 col-md-auto preview_value grow', s.owner)}>
            <Diff newValue={cv.owner?.name} oldValue={pv.owner?.name} />
          </div>
          <div className="w-100" />
          {this.props.isVesselSide && cv.vesselDetails && <div className={cx("col preview_label", s.owner)}>Vessel Details</div>}
          {this.props.isVesselSide && cv.vesselDetails && <div className={cx('col-12 col-md-auto preview_value grow', s.owner)}>
            <Diff newValue={cv.vesselDetails} oldValue={pv.vesselDetails} />
          </div>}
          {!this.props.isVesselSide && !this.props.vesselRequest && <div className={cx("col preview_label", s.owner)}>Vessel</div>}
          {!this.props.isVesselSide && !this.props.vesselRequest && <div className={cx('col-12 col-md-auto preview_value grow', s.owner)}>
          {this.props.vessel?.vessel?.name} {cv.vesselDetails && " - "} {cv.vesselDetails && <Diff newValue={cv.vesselDetails} oldValue={pv.vesselDetails} />}
          </div>}
          {
            this.props.vesselRequest
              ? <Ship details={this.props.cargo.vesselDetails} ship={this.props.vesselRequest} dialogPortId={this.props.dialogPortId} setPortDialogId={this.props.setPortDialogId} />
              : null
          }
          <div className="w-100" />
          {showOwnerSubject && <div className={cx("col preview_label", s.subjects)}>SUBJECTS TO BE LIFTED BY</div> }
          {showOwnerSubject && (
            <div className={cx('col-12 col-md-auto preview_value grow', s.subjects)}>
              <Diff
                newValue={cv.ownerSubject.value}
                oldValue={pv.ownerSubject && (pv.ownerSubject.value || '')}
              />h
            </div>
          )}
          <div className="w-100" />
          { cv.incoterms?.kind || pv.incoterms?.kind ? [
            <div key={0} className={cx("col preview_label")}>INCOTERMS</div>,
            <div key={1} className={cx('col-12 col-md-auto preview_value grow')}>
              <Diff newValue={cv?.incoterms?.kind || "" } oldValue={pv.incoterms?.kind || ""}/>
              {cv.incoterms?.place || pv.incoterms?.place ? [
                ', Port or place: ',
                <Diff newValue={cv.incoterms?.place || ""} oldValue={pv.incoterms?.place || ""}/>,
              ] : null }
            </div>,
          ] : null }
          <div className="w-100">
            <PolPodPreview
              loading={cv.loading}
              previousLoading={pv.loading}
              unloading={cv.unloading}
              previousUnloading={pv.unloading}
              loadingDiff={tradingDiff?.loading}
              unloadingDiff={tradingDiff?.unloading}
              isVesselSide={this.props.isVesselSide}
              shipId={this.props.vesselRequest && this.props.vesselRequest?._id}
              closeRightDialogs={this.props.closeRightDialogs}
            />
          </div>
         {/* <div className="col preview_label">POL</div>
          <div className={cx('col-12 col-md-auto preview_value grow')}>
            <GroupOfPortsPreview ports={cv.loading} previousValue={pv.loading} />
          </div>
          <div className="w-100" />
          <div className="col preview_label">POD</div>
          <div className={cx('col-12 col-md-auto preview_value grow')}>
            <GroupOfPortsPreview ports={cv.unloading} previousValue={pv.unloading} />
          </div>*/}
          <div className="w-100" />
          {cv.cargo ? (<div className="col preview_label">CARGO</div>) : null }
          {cv.cargo ? (
            <div className={cx('col-12 col-md-auto preview_value grow')}>
              <CargoListView request={cv} prevRequest={pv} additionalDetailsEditable={additionalDetailsEditable} />

            </div>
          ) : null }
          <div className="w-100" />
          <div className={cx("col preview_label", s.deviation)}>TTL WEIGHT/VOLUME +-,%</div>
          <div className={cx('col-12 col-md-auto preview_value grow', s.deviation)}>
            <DeviationDiff cv={cv} pv={pv} />
          </div>
          <div className="w-100" />
          <div className={cx("col preview_label", s.freight)}>Freight</div>
          <div className={cx('col-12 col-md-auto preview_value grow', s.freight)}>
            {cv.freights?.length ?
              <MultiFreightView freights={cv.freights} />
              :
              <div>
                <Diff
                  newValue={number(cv.freight.value, cv.freight.currency)}
                  oldValue={number(pv.freight.value, pv.freight.currency)}
                />
                <Diff newValue={cv.freight.term} oldValue={pv.freight.term} />,&nbsp;
                <Diff newValue={cv.freight.method} oldValue={pv.freight.method} />
              </div>
            }
          </div>
          {cv.charges?.length ? (
              [<div className={cx("col preview_label", s.freight)}>Charges</div>,
              <div className={cx('col-12 col-md-auto preview_value grow', s.freight)}>
                <ChargesView charges={cv.charges} />
              </div>]
          ) : null }
          <div className="w-100" />
          <LDRatesView loading={cv.ldRates.loading} prevLoading={pv.ldRates.loading} discharging={cv.ldRates.discharging} prevDischarging={pv.ldRates.discharging} />
          <div className="w-100" />
          {cv.demurrage || pv.demurrage ? [
            <div key="0" className={cx("col preview_label", s.demurrage)}>DEMURRAGE</div>,
            <div key="1" className={cx('col-12 col-md-auto preview_value grow', s.demurrage)}>
              <Diff newValue={number(cv.demurrage, cv.demurrageOpts?.currency, "",2,0,'')} oldValue={number(pv.demurrage, pv.demurrageOpts?.currency, "",2,0,'')} />
              <Diff newValue={timeUnit(cv.demurrageOpts?.timeUnit)} oldValue={timeUnit(pv.demurrageOpts?.timeUnit)} />
              {cv.demurrageOpts?.terms || pv.demurrageOpts?.terms ? ", " : null}
              <Diff newValue={dispatchHr(cv.demurrageOpts?.terms)} oldValue={dispatchHr(pv.demurrageOpts?.terms)} />
            </div>,
          ] : null}
          <div className="w-100" />
          {cv.detention || pv.detention ? [
            <div key="0" className={cx("col preview_label", s.detention)}>DETENTION</div>,
            <div key="1" className={cx('col-12 col-md-auto preview_value grow', s.detention)}>
              <Diff newValue={number(cv.detention, cv.detentionOpts?.currency, "",2,0,'')} oldValue={number(pv.detention, pv.detentionOpts?.currency, "",2,0,'')} />
              <Diff newValue={timeUnit(cv.detentionOpts?.timeUnit)} oldValue={timeUnit(pv.detentionOpts?.timeUnit)} />
            </div>,
          ] : null}
          <div className="w-100" />
          <div className="col preview_label">Terms</div>
          <div className={cx(s.terms_wrapper)}>
            <Terms
              openTermsDetails={this.openTermsDetails}
              previousValues={pv.terms}
              terms={cv.terms}
              indexedTerms={this.context.indexedVoyageTerms}
              billOfLading={cv.billOfLading}
              previousBillOfLading={pv.billOfLading}
            />
          </div>
          <div className="w-100" />
          <div className="col preview_label">Contract</div>
          <div className={cx('col-12 col-md-auto preview_value grow')}>
            {pv.contract &&
            pv.contract._id !== cv.contract._id
              ? <span className={s.old_data}>
                  <ContractLink contract={pv.contract} />
                </span>
              : null}
            <span className={cx({ [s.new_data]: pv.contract && pv.contract._id !== cv.contract._id })}>
              <ContractLink contract={cv.contract} />
            </span>
          </div>
          <div className="w-100" />
          <div className="col preview_label flex align-items-center">Commission</div>
          {this.props.commEditable ?
            <div className={cx('col-12 col-md-auto preview_value grow')}>
              <Commission comm={pv.comm} deductible={pv.deductible} pus={pv.pus} floatingLabelText={''} previousValues={pv} />
            </div>
            :
          <div className={cx('col-12 col-md-auto preview_value grow')}>
            <Diff newValue={number(cv.comm, '', '%')} oldValue={number(pv.comm, '', '%')} />{' '}
            <Diff
              oldValue={pv.pus !== undefined ? ((pv.pus && 'PUS') || '') : undefined}
              newValue={(cv.pus && 'PUS') || ''}
            />
            {' '}
            <Diff
              oldValue={pv.deductible !== undefined ? ((pv.deductible && 'Deductable') || '') : undefined}
              newValue={(cv.deductible && 'Deductable') || ''}
            />
          </div>}
          {cv.additionalMessage && <div className={"col preview_label"}>Additional Message</div> }
          {cv.additionalMessage && <div style={{ whiteSpace: "pre-line" }} className={cx('col-12 col-md-auto preview_value grow')}>
            {cv.additionalMessage}
          </div> }
          {(cv.files?.length || cv.attachments?.length) && this.props.isVesselSide ?
            <div className={cx("col preview_label", s.charterer)}>Attachments</div>
          : null}
          {(cv.files?.length || cv.attachments?.length) && this.props.isVesselSide ?
            <div style={{ whiteSpace: "pre-line" }} className={cx('col-12 col-md-auto preview_value grow', s.message)}>
              <Attachments attachments={cv.attachments} />
            </div>
          : null}
        </div>
        {cv.canViewOriginalMail && !this.props.isVesselSide && !this.props.frRequest ?
          <Collapse renderClosed={false} defaultOpen={false} title={"ORIGINAL MESSAGE"}>
            <OriginalMessage _id={cv._id} />
          </Collapse>
          : null}
        {!this.props.frRequest && !this.props.sendReminder && attachments.length && !this.props.isVesselSide ?
          <Collapse title={`ATTACHMENTS (${attachments.length})`} defaultOpen>
          <Attachments attachments={attachments} />
        </Collapse>
        : null}
        {this.props.sendReminder && attachments.length ?
          <Attachments attachments={attachments} />
        : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dialogPortId: state.port.dialogPortId,
}
);
const mapDispatchToProps = (dispatch) => (
{
  setPortDialogId: (ids) => dispatch(setPortDialogId(ids)),
}
);
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(s)(OfferInMainDeck));
