import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import CircularProgress from 'material-ui/CircularProgress';
import s from './Loader.scss';
import cx from 'classnames';

function Loader({ classes = '', className = '', opaque = false, transparent = false, isDark = false, ...rest }) {
  return <div className={cx(classes, className, s.loader, isDark ? s.dark : "", opaque ? s.opaque : '', transparent && s.transparent)}>
    <CircularProgress size={80} thickness={5} {...rest} />
  </div>
    ;
}

function _FailedToLoad(props) {
  return <div className={s.loader} >
    <div>Failed to load <a onClick={props.onRetry}>retry</a></div>
  </div>
}

export default withStyles(s)(Loader);

export const FailedToLoad = withStyles(s)(_FailedToLoad);
