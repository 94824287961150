import React from 'react';
import styled, { css } from 'styled-components';

export const Flag = styled.div`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 11px solid var(--text-warning);

  margin-right: 4px;
  display: inline-block;

  ${
    props => props.small ? css`
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-left: 6px solid var(--text-warning);
      margin-right: 2px;
    ` : ""
  }
`;

function SanctionsFlag({ hasSanctions, small = false, ...rest }) {
  return (
    hasSanctions ? <Flag title="Sanctionded vessel" small={small} {...rest} /> : null
  )
}

export default SanctionsFlag;
