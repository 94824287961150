import * as React from "react";
import s from "../Common/MapPosition.scss";
import {Component} from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cx from 'classnames';
import { loadMap } from '../../core/utils';
import PubSub from "pubsub-js";
import { getIconForGoogleMap, IconForGoogleMap } from '../Vessel/Icon';
import GmapStyles from './GmapStyles';
class MapPosition extends Component {
  constructor(props) {
    super(props);
    this.mapId = `vessel-position-map-${Math.random().toString(16).slice(2)}`
  }

  initMap() {
    if (typeof window !== 'undefined') {
      if (window.google) {
        return this.drawMap();
      }
      loadMap().then(this.drawMap.bind(this));
    }
  }

  componentDidMount() {
    this.initMap();
    PubSub.subscribe("vessel:posUpdated", this.handlePosUpdated);
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.handlePosUpdated);
  }

  handlePosUpdated = (e, vessel) => {
    if (vessel.imo.toString() === this.props.vessel.imoNumber.toString()) {
      this.props.vessel.lastPos = vessel.lastPos;
      this.removeMarker();
      this.addMarker(vessel);
      this.addRoute(vessel);
    }
  }
  shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
    return false;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.vessel._id !== this.props.vessel._id ) {
      this.removeMarker();
      this.addMarker(nextProps.vessel);
      this.addRoute(nextProps.vessel);
    }
  }

  removeRoute = () => {
    this.routeLayer.forEach((feature) => {
      this.routeLayer.remove(feature);
    })
    this.routeGapsLayer.forEach((feature) => {
      this.routeGapsLayer.remove(feature);
    })
  }
  addRoute = ({previousRoutes}) => {
    this.removeRoute();
    if(!previousRoutes) {
      return;
    }
    if(previousRoutes.routes) {
      this.routeLayer.addGeoJson({ type: 'Feature', geometry: previousRoutes.routes });
      this.routeLayer.addGeoJson({ type: 'Feature', geometry: { type: 'MultiPoint', coordinates: previousRoutes.ports.map(p => p.point.coordinates) } });
      this.routeGapsLayer.addGeoJson({ type: 'Feature', geometry: previousRoutes.gaps });
      this.removeMarker();
      const lastPoint = previousRoutes.points[previousRoutes.points.length - 1];
      const fakeLastPos = { coords: lastPoint.coordinates, angle: lastPoint.trueHeading || lastPoint.cog, speed: lastPoint.speed };
      this.addMarker({ name: this.props.vessel.name, typeByPurpose: this.props.vessel.typeByPurpose, lastPos: fakeLastPos });

    }

  }


  drawMap() {
    const { name, lastPos } = this.props.vessel;
    let center = { lat: 0, lng: 0 };
    if(lastPos) {
      center = { lat: lastPos.coords[1], lng: lastPos.coords[0] };
    }

    this.map = this.map || new google.maps.Map(document.getElementById(this.mapId), {
      center: center,
      zoom: this.props.zoom || 8,
      styles: GmapStyles,
    });
    this.routeLayer = this.routeLayer || new google.maps.Data({map: this.map});
    const portSymbol = {
      path: google.maps.SymbolPath.CIRCLE,
      strokeColor: 'white',
      fillColor: '#75C422',
      fillOpacity: 1,
      strokeOpacity: 1,
      strokeWeight: 1,
      scale: 4,
    };
    const arrowSymbol = {
      path: "M3.64645 4.35355C3.84171 4.54882 4.15829 4.54882 4.35355 4.35355L7.53553 1.17157C7.7308 0.976311 7.7308 0.659728 7.53553 0.464466C7.34027 0.269204 7.02369 0.269204 6.82843 0.464466L4 3.29289L1.17157 0.464466C0.976311 0.269204 0.659728 0.269204 0.464466 0.464466C0.269204 0.659728 0.269204 0.976311 0.464466 1.17157L3.64645 4.35355ZM3.5 1V4H4.5V1H3.5Z",
      strokeOpacity: 1,
      strokeWeight: 1,
      scale: 1,
      rotation: 180,
      anchor: new google.maps.Point(4,0),
    };
    this.routeLayer.setStyle({
      strokeWeight: 1,
      strokeColor: 'white',
      icons: [
        {
          icon: arrowSymbol,
          offset: "100%",
          repeat: "20px",
        },
      ],
      icon: portSymbol,
    });
    this.routeGapsLayer = this.routeGapsLayer || new google.maps.Data({map: this.map});
    const lineSymbol = {
      path: "M 0,0 L0,5",
      strokeOpacity: 0.7,
      scale: 1,
    };
    this.routeGapsLayer.setStyle({
      strokeWeight: 1, strokeColor: 'white', strokeOpacity:0,
      icons: [
        {
          icon: lineSymbol,
          offset: "5",
          repeat: "10px",
        },
      ],
    })
    if (lastPos) {
      this.addMarker();
    }
    this.addRoute(this.props.vessel);

  }

  addMarker = (vessel) => {
    vessel = vessel || this.props.vessel;
    const { name, lastPos } = (vessel || this.props.vessel);
    if (!lastPos) {
      return this.removeMarker();
    }
    const position = { lat: lastPos.coords[1], lng: lastPos.coords[0] };
    this.marker = new google.maps.Marker({
      position,
      map: this.map,
      icon: getIconForGoogleMap(vessel,lastPos),
      title: name
    });
    this.map.setCenter(position);
  }

  removeMarker = () => {
    if(this.marker) {
     this.marker.setMap(null);
     this.marker = null;
    }
  }

  renderPositionInfo(lastPos) {

    return [
      <div id={this.mapId} className={cx(s.map)} ></div>
    ];
  }

  render() {
    return (
      <div className={cx(s.wrapper_map,s.map_main_deck)}>
        { this.renderPositionInfo(this.props.vessel.lastPos) }
      </div>
    )
  }
};

export default ( withStyles(s)(MapPosition));
