import React, { PureComponent } from 'react';
import s from './PublicPort.scss';
import cx from 'classnames';
import LocationIcon from "material-ui/svg-icons/communication/location-on";
import { dateWithTime, number } from '../../core/format';
import Link from '../Link/Link';
import { ProgressLine } from '../Common/ProgressVesselPosition';

export default class VesselsList extends PureComponent{
    render() {
        return(
            <div>
                {this.props.vessels.map((item, vesselIndex) => {
                    let fromPortName = item.route.from?.shipnextData?.name || item.route.from?.name;

                    return (<div key={item.imo} className={cx(s.general,s.body_part)}>
                        <div className={s.name}>
                        <span className={s.title_name}><Link href={undefined} rel="nofollow" to={`/port/${this.props.port?.seo?.sefName || this.props.port?._id}/${item.shipnextId}`}>{item.name}</Link></span><br/>
                        <span className={s.wrapper_position}>
                            <LocationIcon
                            style={{
                                position: 'relative',
                                left: '-2px',
                                width: '14px',
                                height: '14px',
                            }}
                            />
                            <span className={s.position} onClick={() => this.props.handleVesselPositionClick(item)}>Position</span>
                        </span>
                        <span className={s.bulk}>{item.imo} / {item.details.type}</span>
                        </div>
                            <div className={s.dwt}><p>{number(item.details.dwt)} MT</p></div>
                        <div className={s.blt}><p>{item.details.blt}</p></div>
                        {item.route.from ? ([
                          <div key={0} className={s.departure}>
                            {item.route.from.portId ?
                              <a rel="nofollow" title={fromPortName.toUpperCase()} href={`/port/${item.route.from.portId}`} >{fromPortName} </a>
                              :
                              <span className={s.name_without_id} title={fromPortName}>{item.route.from.name}</span>
                            }
                            <br/>
                            <span>{dateWithTime(item.route.from.date)}</span>
                          </div>,
                            <div key={1} className={s.progress_line_wrapper}>
                              <ProgressLine onRoute={item} showPercent={false} />
                            </div>,
                          ]
                        ) : [
                          <div key={0} className={s.departure}>
                          </div>,
                          <div key={1} className={s.progress_line_wrapper}>
                          </div>,
                        ]}
                        <div className={s.arrival}>
                            {/* <a href={`/port/${this.props.port._id}`} title={`${this.props.port.name}`}>{this.props.port.name}</a><br/> */}
                            {item.route.to.portId ?
                              <a href={`/port/${item?.route?.to?.portId}`} title={`${item?.route?.to?.name}`}>{item?.route?.to?.name}</a>
                            :
                              <span className={s.name_without_id} title={item?.route?.to?.name}>{item?.route?.to?.name}</span>
                            }
                            <br/>
                            <span>{dateWithTime(item.route.to.date)}</span>
                        </div>
                    </div>);
                })
                }
            </div>
        );
    }
}
