import React, { Component } from 'react'
import styles from './muistyles'
import cx from 'classnames'
import CargoAutocomplete from '../Common/CargoAutocomplete';
import s from './inputs.scss';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
export class NarrowCargoAutocomplete extends Component {
    static defaultProps = {
        shouldReset: false
    }
    selectItem = item => {

        if (item.length <= 3) {
            if (this._autocomplete.refs.input) {
                this._autocomplete.refs.input.close();
            }
        }

        const {shouldReset} = this.props;
        if (this.props.onNewRequest) {
            this.props.onNewRequest(item)
        }
        if (shouldReset && this._autocomplete) {
            this._autocomplete.resetValue()
        }
    }
    render() {
        const { inputStyles, classes, onNewRequest, ...props} = this.props;
        return (
            <CargoAutocomplete
                className={cx('input_default', classes)}
                textFieldStyle={styles.container}
                floatingLabelFocusStyle={styles.text.labelFocus}
                floatingLabelStyle={styles.text.label}
                hintStyle={styles.text.label}
                errorStyle={styles.error}
                inputStyle={{...styles.text.input, ...inputStyles}}
                floatingLabelShrinkStyle={styles.text.labelShrink}
                underlineStyle={styles.text.underline}
                underlineFocusStyle={styles.text.underlineFocus}
                popoverProps={{ style: { minWidth: '256px' } }}
                ref={el => {
                  this.props.innerRef?.(el);
                  this._autocomplete = el;
                }}
                onNewRequest={this.selectItem}
                {...props}
            />
        )
    }
}

export default withStyles(s)(NarrowCargoAutocomplete)
