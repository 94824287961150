import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Stowage from './Stowage';
import fieldsConfig, { getConfigFields } from './fields';
import Clauses from './Clauses';
import FormsyInput from '../Common/FormsyInput';
import CargoTypeSelect from '../Common/CargoTypeSelect';
import NarrowCargoAutocomplete from '../NewInputs/NarrowCargoAutocomplete';
import {
  accessObjectValueWithName,
  extendStowageFields,
  stripBrackets,
} from './utils';
import merge from 'lodash/merge';
import NarrowFormsyText from '../NewInputs/NarrowFormsyText';
import { hsCode as hsCodeAPI } from '../../core/api/Vessel';
import NarrowAutocomplete from '../NewInputs/NarrowAutocomplete';
import {formatRange, formatTypeOfTransportation} from '../../core/format';
import {debounceWithoutFirstCall, set} from '../../core/utils';
import { constructCargoTitle } from './constructCargoTitle';
import s from "./Cargo.scss";
import FormsyRadio from "formsy-material-ui/lib/FormsyRadio";
import FormsyRadioGroup from "formsy-material-ui/lib/FormsyRadioGroup";
import {getDefaultContainerType} from "../dictionaries";
import cx from 'classnames';



export default class CargoContent extends PureComponent {
  state = {};
  static propTypes = {
    handleAddCargo: PropTypes.func.isRequired,
    handleCargoNameChange: PropTypes.func.isRequired,
    last: PropTypes.bool,
    index: PropTypes.number.isRequired,
    cargoInArray: PropTypes.object,
    defaultValues: PropTypes.object,
  };

  static contextTypes = {
    showMessage: PropTypes.func,
    onChange: PropTypes.func,
    cargoTypes: PropTypes.array,
  };

  onChange = (index, stateDataName) => (...rest) => this.context.onChange('cargoList')(index)(...rest).then((state) => {
    this.changeTitle(this.stowageSelector(state));
    if (stateDataName) {
      const updatedData = this.dataSelector(stateDataName)(state);

      return updatedData;
    }
    return state;
  });

  componentWillReceiveProps(nextProps, nextContext) {
    if (
      this.props.cargoInArray?.cargoType &&
      nextProps.cargoInArray?.cargoType !== this.props.cargoInArray.cargoType &&
      this.props.cargoInArray &&
      this.props.cargoInArray._id
    ) {
      this._cargoName.setValue();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { cargoInArray: { cargo: prevCargo, cargoType: prevCargoType } = {} } = prevProps;
    const { cargoInArray: { cargo, cargoType } = {} } = this.props;

    if (cargo?._id !== prevCargo?._id || cargoType?._id !== prevCargoType?._id) {
      this.populateCategory();
      this.changeTitle();
    }
  }

  componentDidMount() {
    this.populateCategory();
    this.changeTitle();
  }

  constructObj = (name, value = "", context = {}) => {
    const copy = {
      ...context,
    };
    let currentObj = copy;

    const nesting = String(name).match(/\[([\w\s]+)\]/g);

    if (!nesting) return {};

    const length = nesting?.length;

    if (length > 0) {
      for (let i = 0; i < length - 1; i++) {
        const property = stripBrackets(nesting[i]);
        if (!currentObj[property]) {
          currentObj[property] = {};
        }
        currentObj = currentObj[property];
      }

      currentObj[stripBrackets(nesting[length - 1])] = value;
    }

    return copy;
  }

  populateObj = (arr, data) => Array.isArray(arr) ? arr.reduce((acc, item) => {

    const populateValueInObj = (field = {}, name = field.name) => {
      let defaultVal = '';
      if (field.type === 'checkbox' || field.type === 'switch') {
        defaultVal = false;
      }
      if (field.hidden) {
        return defaultVal;
      }
      return accessObjectValueWithName(data, name) || field.defaultValue || defaultVal;
    };

    const { cargoInArray } = this.props;

    data = data || extendStowageFields(cargoInArray?.stowage, cargoInArray?.cargo);

    let value = this.constructObj(item.name, populateValueInObj(item));

    if (item.fields) {
      for (const field of item.fields) {
        value = {
          ...this.constructObj(field.name, populateValueInObj(field, field.name), value),
        };
      }
    }

    if (item.spread) {
      if (data.stowageSpread) {
        acc.stowageSpread = true;
      }
      for (const field of item.spread) {
        value = {
          ...this.constructObj(field.name, accessObjectValueWithName(data, field.name) || field.defaultValue || "", value),
        };
      }
    }

    return merge(acc, value);
  }, {}) : {};

  populateRequestCargoStep = fields => {
    if (!fields) return;
    const { populateObj } = this;
    const { cargoInArray } = this.props;
    const stowageFields = populateObj(fields.stowage);
    if (fields.packingCategories.map(pc => pc.value || pc).indexOf(cargoInArray.stowage.category) !== -1) {
      stowageFields.category = cargoInArray.stowage.category;
    } else {
      stowageFields.category = fields.packingCategories[0];
    }
    const packedFields = populateObj(fields.packed);

    const unpackedFields = populateObj(fields.unpacked);

    const clausesFields = populateObj(fields.clauses, cargoInArray?.clauses);
    const stateToPopulate = merge(stowageFields, packedFields, unpackedFields);

    this.onChange(this.props.index)({
      ...cargoInArray,
      stowage: stateToPopulate,
      clauses: clausesFields,
    });
  }

  handleNameChange = value => {
    const { onChange } = this;
    const { index, cargoInArray } = this.props;
    const newCargo = { cargo: value || "" };
    const prevDetails = cargoInArray.cargo?.details || cargoInArray.cargoType?.details;
    newCargo.name = (value && value.name) || value;
    if (!value) {
      newCargo.title = '';
    } else {
      if (value.parent?.red) {
        const cargoType = this.findCargoType(value.parent.red._id);
        if (cargoType) {
          newCargo.cargoType = { ...cargoType };
        }
      }
    }
    if (value.containerForm) {
      if (!cargoInArray.stowage.containerDetails) {
        cargoInArray.stowage.containerDetails = {};
      }
        cargoInArray.stowage.containerDetails.kind = value._id;
        cargoInArray.stowage.containerDetails.stc = "";
      if (!cargoInArray.stowage.containerDetails.owner) {
        cargoInArray.stowage.containerDetails.owner = "shipper";
      }
    } else {
      if (cargoInArray.typeOfTransportation === 'container') {
        if (!cargoInArray.stowage.containerDetails) {
          cargoInArray.stowage.containerDetails = {};
        }
        cargoInArray.stowage.containerDetails.stc = newCargo.name;
      }
    }

    const updated = {
      ...cargoInArray,
      ...newCargo,
    };
    if (typeof value === "string" && cargoInArray.cargoType?.forbidNewSubtypes) {
      updated.cargoType = undefined;
    }
    const newDetails = updated.cargo?.details || updated.cargoType?.details;
    updated.title = this.constructTitle(updated);
    let { config, typesOfTransportation} = this.getConfigFields(updated.cargo, updated.cargoType, updated.typeOfTransportation, updated.stowage);
    if (typesOfTransportation.indexOf(updated.typeOfTransportation) === -1) {
      updated.typeOfTransportation = typesOfTransportation[0];
      config = this.getConfigFields(updated.cargo, updated.cargoType, updated.typeOfTransportation, updated.stowage).config;
    }

    onChange(index)(updated).then((state) => {
      if (newDetails !== prevDetails) {
        this.populateRequestCargoStep(config);
        this.populateCategory(this.stowageSelector(state));
      }
    });
    //this.props.handleCargoNameChange(this.props.index, value);
  };

  populateCategory = (stowage = this.props.cargoInArray.stowage || {}) => {
    const { cargoInArray: { cargo, cargoType, typeOfTransportation } = {} } = this.props;
    const { config } = this.getConfigFields(cargo, cargoType, typeOfTransportation);
    const packingCategories = this.computePackingCategories(config, typeOfTransportation);
    if (stowage.category && packingCategories.map(pc => pc.value || pc).indexOf(stowage.category) !== -1) return;

    const categoryRadioBtnName = '[category]';
    return this.handleStowageChange(categoryRadioBtnName)(null, packingCategories[0]);
  }

  handleTypeChange = value => {
    const { onChange } = this;
    const { index, cargoInArray } = this.props;
    const { typesOfTransportation } = this.getConfigFields(cargoInArray.cargo, value, cargoInArray.typeOfTransportation, cargoInArray.stowage);
    if (typesOfTransportation.indexOf(cargoInArray.typeOfTransportation) === -1) {
      cargoInArray.typeOfTransportation = typesOfTransportation[0];
    }
    if (value.details === 15) {
        set(cargoInArray, 'stowage.containerDetails.stc', cargoInArray.name);
        if (!cargoInArray.stowage.containerDetails.kind) {
          cargoInArray.stowage.containerDetails.kind = getDefaultContainerType()._id;
        }
        if (!cargoInArray.stowage.containerDetails.owner) {
          cargoInArray.stowage.containerDetails.owner = "shipper";
        }
    }
    onChange(index)({
      ...cargoInArray,
      cargoType: value || "",
    }).then(() => {
      const { details = 0 } = value;
      const { config } = this.getConfigFields(cargoInArray.cargo, value, cargoInArray.typeOfTransportation, cargoInArray.stowage);
      this.populateRequestCargoStep(config);
      this.populateCategory();
    });

    //this.props.handleCargoNameChange(this.props.index, value);
  };

  handleTypeOfTransportationChange = (e, value) => {
    const { onChange } = this;
    const { index, cargoInArray } = this.props;
    if (value === 'container') {
      set(cargoInArray, 'stowage.containerDetails.stc', cargoInArray.name);
      if (!cargoInArray.stowage.containerDetails.kind) {
        cargoInArray.stowage.containerDetails.kind = getDefaultContainerType()._id;
      }
      if (!cargoInArray.stowage.containerDetails.owner) {
        cargoInArray.stowage.containerDetails.owner = "shipper";
      }
    }
    onChange(index)({
      ...cargoInArray,
      typeOfTransportation: value,
    }).then(() => {
      this.populateCategory();
    });

    //this.props.handleCargoNameChange(this.props.index, value);
  };

  handleGenericChange = (stateDataName = 'stowage') => name => (ev, val) => {
    return this.onChange(this.props.index, stateDataName)(updatedCargoInArray => {
      const newCargo = { ...updatedCargoInArray };
      const newVal = val ?? (ev?.target?.value || ev);
      if (!stateDataName) {
        newCargo[stripBrackets(name)] = val;
      } else {
        if (name === '$root') { //TODO consider change full updates to batch incremental updates
          newCargo[stateDataName] = val;
        } else {
          const newSubstate = this.constructObj(name, newVal, { ...newCargo[stateDataName] });
          newCargo[stateDataName] = newSubstate;
        }
      }
      newCargo.title = this.constructTitle(newCargo);
      return newCargo;
    });
  }

  handleStowageChange = this.handleGenericChange();

  handleClausesChange = this.handleGenericChange('clauses')

  dataSelector = stateKey => (state = {}) => state.cargoList?.[this.props.tabIndex]?.[stateKey];

  stowageSelector = this.dataSelector('stowage');

  // cargo autocomplete's find api endpoint returns cargo with packing field that is treated as packingList
  // but simple form recognition api returns packing and packingList as two separate fields
  getPackingList = (cargo, cargoType) => {
    let packingArray;
    if (cargoType) {
      cargoType = this.findCargoType(cargoType._id || cargoType);
    }
    if (typeof cargo === 'object' && cargo !== null) {
      if (Array.isArray(cargo.packing)) {
        packingArray = cargo.packing;
      } else if (cargo.packingList?.[0]?.packingType) {
        packingArray = cargo.packingList;
      }
    }

    if ((!packingArray || !packingArray.length) && cargoType) {
      packingArray = cargoType.packing;
    }
    return packingArray || [];
  }

  changeTitle = debounceWithoutFirstCall((stowage = this.props.cargoInArray.stowage || {}) => {
    console.error("avoid calling this function")
    //this.props.handleChangeTitle && this.props.handleChangeTitle(title);
  });


  constructTitle = (cargoInArray) => {
    return constructCargoTitle(cargoInArray);
  }

  getConfigFields = (cargo, cargoType, typeOfTransportation, stowage) => {
      return getConfigFields(cargo, cargoType, typeOfTransportation, stowage);
  }

   findCargoType(_id) {
    return this.context.cargoTypes.find(ct => ct._id === _id);
  }

  computePackingCategories = (config, typeOfTransportation) => {
    let packingCategories = config.packingCategories;
    switch (typeOfTransportation) {
      case 'container' :
        packingCategories = ['container'];
        break;
      case 'wetbulk':
        packingCategories = ['wetbulk'];
        break;
      case 'bulk':
        packingCategories = ['bulk'];
        break;
      default :
        packingCategories = packingCategories.filter(pc => pc !== 'bulk');
    }
    return packingCategories;
  }

  render() {
    const namePrefix = this.props.namePrefix || `cargo[${this.props.index}]`;
    const { cargo, cargoType = cargo?.cargoType, hsCode = '', typeOfTransportation, stowage, additionalCargoDetails } = this.props.cargoInArray || {};

    const { config, configIndex, typesOfTransportation } = this.getConfigFields(cargo, cargoType, typeOfTransportation, stowage);
    const packingList = this.getPackingList(cargo, cargoType);
    const tt = typeOfTransportation || typesOfTransportation[0].value;
    const packingCategories = this.computePackingCategories(config, tt);
    const isContainerCargo = !!cargo?.containerForm;


    return (
      <div className="row">
        <div className="col-12 col-md-4">
          <NarrowCargoAutocomplete
            hintText="Cargo name*"
            floatingLabelText="Cargo name*"
            validationErrors={{ isDefaultRequiredValue: 'required' }}
            requiredError="required"
            name={namePrefix + '[cargoName]'}
            onNewRequest={this.handleNameChange}
            required
            fullWidth
            forceSelection={false}
            innerRef={el => this._cargoName = el}
            data-tabindex={this.props.tabIndex}
            value={cargo}
          />
          <FormsyInput
            name={namePrefix + '[details]'}
            type="hidden"
            value={configIndex}
          />
        </div>
        <div className="col-12 col-md-4">
          <NarrowAutocomplete
              floatingLabelText="HS Code"
              name={`${namePrefix}[hsCode]`}
              fullWidth
              api={hsCodeAPI}
              onNewRequest={this.handleGenericChange(null)('hsCode').bind(this, null)}
              smallFont
              value={hsCode}
              validations="isObj"
              validationErrors={{
                "isObj": 'select hs code from list',
              }}
              maxHeight={271}
          />
        </div>
        {cargo && !cargo.parent ?
          <div className="col-12 col-md-4">
            <CargoTypeSelect
              hintText="Type of cargo*"
              floatingLabelText="Type of cargo*"
              requiredError="required"
              name={namePrefix + '[cargoType]'}
              ref="cargoType"
              value={cargoType?._id}
              onChange={(ev, val) => this.handleTypeChange(val)}
              required
              data-tabindex={this.props.tabIndex}
              fullWidth
              hideForbidden
            />
          </div>
          : null}
        <div className="col-12">
          <NarrowFormsyText
            floatingLabelText="Additional details"
            name={namePrefix + '[additionalCargoDetails]'}
            onChange={this.handleGenericChange(null)('additionalCargoDetails')}
            fullWidth
            data-tabindex={this.props.tabIndex}
            value={additionalCargoDetails}
          />
        </div>
        <div className={cx("col-12", isContainerCargo && s.container_cargo_stowage_wrapper)}>
          {cargo && (cargo.parent || cargoType)
            ? <span>
               <span className={cx(typesOfTransportation.length < 2 && 'hidden')}>
                <h5>CATEGORY</h5>
                <FormsyRadioGroup
                  name={namePrefix.replace('[pack]', '') + '[typeOfTransportation]'}
                  onChange={this.handleTypeOfTransportationChange}
                  className={s.type_of_transportation_radio_group}
                  required
                  value={tt}
                  requiredError="required"
                >
                      {
                        typesOfTransportation.map(category => {
                          return (
                            <FormsyRadio
                              value={category}
                              label={formatTypeOfTransportation(category)}
                              className={tt === category ? 'checked' : null}
                              labelStyle={{fontSize: '13px'}}
                              style={{ marginRight: "22px", whiteSpace: "nowrap" }}
                            />
                          )
                        })
                      }
                    </FormsyRadioGroup>
              </span>
                <Stowage
                  stateDataPrefix="stowage"
                  data={this.props.cargoInArray.stowage ?? {}}
                  handleChange={this.handleStowageChange}
                  stowageFields={config.stowage}
                  handleAddCargo={this.props.handleAddCargo}
                  namePrefix={namePrefix + '[packing][pack]'}
                  packingList={packingList}
                  categories={packingCategories}
                  cargoName={cargo?.name}
                  tabIndex={this.props.tabIndex}
                  otherFields={config[this.props.cargoInArray.stowage?.category] || config.unpacked}
                  handleChangeTitle={this.props.handleChangeTitle}
                  defaultValues={this.props.defaultValues}
                  flipNested={config.flipNested}
                  withoutSpread={config.withoutSpread}
                />
                {config.clauses && config.clauses.length
                  ? <Clauses
                      stateDataPrefix="clauses"
                      data={this.props.cargoInArray.clauses}
                      handleChange={this.handleClausesChange}
                      clausesFields={config.clauses}
                      handleAddCargo={this.props.handleAddCargo}
                      tabIndex={this.props.tabIndex}
                      namePrefix={namePrefix + '[clauses]'}
                    />
                  : null}
              </span>
            : null}

        </div>

      </div>
    );
  }
}
