import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cx from "classnames";


const style = { display: 'inline-block' };

/**
 * if you are using this component inside scrollable container,  add clip-path: inset(0); to container
 * and dispatch  window.dispatchEvent(new CustomEvent('scroll')) because scroll event doesn't bubble
 */
export class WithTooltip extends PureComponent {
  static propTypes = {
    showOnEventName: PropTypes.oneOf(['onMouseOver', 'onClick']),
    offset: PropTypes.shape({x: PropTypes.number, y: PropTypes.number}),
    horizontalPosition: PropTypes.oneOf(['left', 'right']),
    closeOnChildrenClick: PropTypes.bool,
    positionInsideTransform: PropTypes.bool,
    bottomArrow: PropTypes.bool,
    position: PropTypes.oneOf(['absolute', 'fixed']),
    noArrow: PropTypes.bool,
  }

  static defaultProps = {
    showOnEventName: 'onMouseOver',
    offset: {x:0, y:0},
    horizontalPosition: 'right',
    closeOnChildrenClick: false,
    positionInsideTransform: false,
    bottomArrow: false,
    endAnchor: false,
    position: 'fixed',
    noArrow: false,
  }

  constructor(props){
    super(props);
    this.state={
      arrow: 'up',
      className: '',
      styleTop: null,
    };
  }

  componentWillUnmount(nextProps, nextState, nextContext) {
    this.removeListeners();
  }

  removeListeners = () => {
    window.removeEventListener('scroll',this.handleWindowScroll )
    window.removeEventListener('resize',this.handleWindowScroll )
  }

  handleClose = ()=> {
    this.setState({coords:null, anchor: null});
    this.removeListeners();
    clearTimeout(this.openTimeout)
    this.props.onClose && this.props.onClose();
  };

  handleLeave = () => {
    if (this.props.showOnEventName === 'onMouseOver') {
      clearTimeout(this.openTimeout);
      this.closeTimer = setTimeout(() => {
        this.handleClose();
      }, 300);
    }
  }

  handleOver = (e)=> {
    if (!this.props.tip) {
      return;
    }

    if (e) { //assume this is initial call before showing
      if (this.props.showOnEventName === 'onClick') {
        e.stopPropagation();
        e.preventDefault();
      }

      if(this.state.coords && this.props.showOnEventName === 'onClick' ) {
        return this.handleClose();
      }
      if (this.props.position === 'absolute'){
        return this.repositionAbsolute(e);
      }
      e.stopPropagation();
      clearTimeout(this.closeTimer);
      window.addEventListener('scroll', this.handleWindowScroll);
      window.addEventListener('resize', this.handleWindowScroll);
    }

    clearTimeout(this.openTimeout);

    const anchor = this.state.anchor || e && e.currentTarget;
    const rect= anchor?.getBoundingClientRect() || {x: 0, y: 0, height: 0};
    const coords = {x: this.props.positionInsideTransform ? this.props.offset.x || 0 :  rect.x + (this.props.offset.x || 0) + (this.props.endAnchor ? (anchor?.offsetWidth || 0) : 0), y:rect.y + rect.height + (this.props.offset.y || 0)};
    if (this.props.closeOnChildrenClick) {
      this.setState(state => ({
        coords: state.coords ? null : coords
      }))
      return;
    }

    this.openTimeout = setTimeout(()=> {
      this.setState( {coords, anchor}, () => {
        let heightDraggableSpan = this.refs.spanTooltip.offsetHeight;
        let heightWindow = document.documentElement.scrollHeight;
        let classNames = [];
        const coords = {
          ...(this.state.coords || {x:0,y:0})
        };
        if (this.props.positionInsideTransform) {
          coords.x = this.state.anchor.offsetLeft + (this.state.anchor.offsetWidth / 4);
          coords.y =  this.state.anchor.offsetTop + this.state.anchor.offsetHeight;
          this.refs.spanTooltip.style.transition = '0s';
          this.setState({
            coords
          });
          return;
        }
        if (this.props.horizontalPosition === 'right' && rect.x + this.props.offset.x + this.refs.spanTooltip.offsetWidth > document.body.offsetWidth) {
          classNames.push('center_arrow')
          this.setState(state => ({coords: {...state.coords, x:state.coords.x + this.props.offset.x + this.refs.spanTooltip.offsetWidth > document.body.offsetWidth ? state.coords.x - this.refs.spanTooltip.offsetWidth / 2 : state.coords.x}}))
        }
        if (heightWindow - this.state.coords.y < heightDraggableSpan) {
          classNames.push('bottom_arrow_tooltip')
          this.setState({
            className: classNames.join(" "),
            styleTop: this.state.coords.y - heightDraggableSpan - 18
          });
        } else {
          this.setState({
            className: classNames.join(" "),
            styleTop: null
          });
        }
      });
      this.props.onOpen && this.props.onOpen();
    }, 300);

  };

  repositionAbsolute(e) {
    const coords = { x: 0, y: 0 };
    const rect = e.currentTarget.getBoundingClientRect();
    coords.y = rect.height;
    coords.x = 0;
    this.setState({
      coords,
      styleTop: null,
    });
  }

  muteEvent = (e) => {
    // e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();
    this.handleLeave(e);
    return false;
  }
  onMouseDown=(e)=> {
    this.handleLeave(e);
  }

  handleWindowScroll = (e) => {
    this.handleOver()
  }

  render() {
    let tip;
    if (this.state.coords) {
      if (typeof this.props.tip === 'function') {
        tip = this.props.tip({ handleClose: this.handleClose, reposition: this.handleOver });
      } else {
        tip = this.props.tip;
      }
    }

    const { style: s = {}, className = "" } = this.props;
    return <div style={{ ...style, ...s }} className={className} onMouseLeave={this.handleLeave} onDragStart={this.onDragStart} onMouseDown={this.onMouseDown} {...{[this.props.showOnEventName]:this.handleOver}}
    >
      {this.props.children}
      {this.state.coords && tip && (<span
        ref={'spanTooltip'}
        id="draggable"
        draggable="false"
        onDragStart={this.muteEvent}
        onMouseDown={this.muteEvent}
        onClick={this.muteEvent}
        className={cx(this.props.bottomArrow && 'bottom_arrow_tooltip', 'arrow_tooltip', 'top_arrow_tooltip', this.state.className, this.props.horizontalPosition === 'left' && 'right_arrow_tooltip', this.props.whiteArrow && 'white_arrow', this.props.noArrow && 'no_arrow', this.props.centerArrow && 'center_arrow')}
        style={{ position: this.props.position, left: this.state.coords.x, transition:"all 0.2s", top: this.state.styleTop || this.state.coords.y, zIndex:10000}}>
          {tip}
        </span>)}
    </div>
  }
}
