/**
 * Created by jenya on 09.04.17.
 */
/* eslint-disable dot-notation */
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import MenuItem from 'material-ui/MenuItem';
import Formsy from 'formsy-react';
import formDataToObject from 'form-data-to-object';
import s from './TcOffer.scss';
import {removeFromArray, toUTC, scrollToAnchor, isUserSubscribedToMarketplace, } from '../../../core/utils';
import VesselLink from '../../Vessel/Preview/VesselLink';
import FormsyText from '../../Common/FormsyText';
import FormsySelect from '../../Common/Select';
import FormsyCheckbox from '../../Common/CheckBox';
import PortAutocomplete from '../../Common/PortAutocomplete';
import DeliveryTermsSelect from '../../Common/DeliveryTermsSelect';
import RedeliveryTermsSelect from '../../Common/RedeliveryTermsSelect';
import DatePicker from '../../Common/FormsyDate';
import Toggle from '../../Common/Toggle';
import RaisedButton from '../../Common/RaisedButton';
import Terms from '../../Documents/Terms';
import ContractSelect from '../../Common/ContractSelect';
import CompanyAutocomplete from '../../Common/CompanyAutocomplete';
import Channels from './Channels';
import Bunkers from './Bunkers';
import BankDetails from '../../Common/BankDetails';
import history from '../../../core/history';
import Attachments, { AddAttachment } from '../../Cargo/Preview/Attachments';
import NarrowFormsyText from "../../NewInputs/NarrowFormsyText";
import {NarrowSelect} from "../../NewInputs/NarrowSelect";
import FormsyRadioGroup from 'formsy-material-ui/lib/FormsyRadioGroup';
import FormsyRadio from 'formsy-material-ui/lib/FormsyRadio';
import FormsyDateTime from '../../Common/FormsyDateTime';
import NavigationClose from 'material-ui/svg-icons/navigation/close';
import FormsyAttachments from '../../Common/FormsyAttachments/FormsyAttachments';
import {mapInitialEmails} from "../Offer/utils";
import MailHeadersEdit from "../../Common/Share/MailHeadersEdit";
import {Formik} from "formik";
import Cargo from "../../../core/api/Cargo";
import CustomTooltip from '../../Common/CustomTooltip';
import ActionInfo from 'material-ui/svg-icons/action/info';

class TcOffer extends Component {
  static propTypes = {
    vessel: PropTypes.object.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSend: PropTypes.func.isRequired,
    user: PropTypes.object,
    offer: PropTypes.object,
    loading: PropTypes.bool,
  };
  static contextTypes = {
    timeTerms: PropTypes.array,
  };

  state = {
    selectedChannels: [],
    message: '',
    additionalMessage: '',
    previousValues: {},
    userType: 'cargoOwner',
    vesselDetails: '',
    attachmentsKey: 0,
    attachments: [],
    cc: [],
    bcc: [],
    replyTo: [],
  };

  constructor(props) {
    super(props);
    if (this.props.vessel.channels && this.props.vessel.channels.length === 1) {
      this.state.selectedChannels.push(this.props.vessel.channels[0]._id);
    }
    if (props.offer) {
      this.state.imdgChecked = props.offer.imdgChecked || !!props.offer.quantityOfIMO;
      if (!this.props.offer.bids.originalRequest) {
        this.props.offer.bids.originalRequest = JSON.parse(
          JSON.stringify(this.props.offer.bids.changedRequest)
        );
      }
      if (!this.props.offer.bids.changedRequest.bankingDetails) {
        this.props.offer.bids.changedRequest.bankingDetails =
          this.props.offer.bids.originalRequest.bankingDetails;
      }
      const pv =
        this.props.offer.bids.originalRequest ||
        this.props.offer.bids.changedRequest;
      const formData = formDataToObject.fromObj(pv);

      formData.contract =
        (formData.contract && formData.contract._id) || formData.contract;
      formData.imdgChecked = this.state.imdgChecked;
      this.state.previousValues = formData;
      if (props.offer.requestCreator === props.user._id) {
        this.state.userType = 'shipOwner';
      }
      this.state.delivery = this.props.offer.bids.changedRequest.deliveryPlace;
    }

    this.state.attachments = this.props.offer?.attachments;
    this.state.attachmentsKey = this.props.offer?.attachments?.length - 1 ?? 0;
    const minDate = new Date();
    minDate.setHours(minDate.getHours() + 2);
    minDate.setMinutes(60);
    this.state.minDate = minDate;
  }

  componentDidMount() {
    if (this.props.offer) {
      const formData = formDataToObject.fromObj(this.props.offer);
      delete formData.message;
      delete formData.additionalMessage;
      formData.contract =
        (formData.contract && formData.contract._id) || formData.contract;
      formData.timeForReply = formData.timeForReply || 24;
      formData.attachments = this.state.attachments;
      if (this.state.userType === 'shipOwner') {
        if (this.props.user && this.props.user.company && this.props.user.company.accounts && this.props.user.company.accounts.length) {
          if (!formData['bankingDetails[bank]']
            && !formData['bankingDetails[bankAddress]']
            && !formData['bankingDetails[beneficiaryName]']
            && !formData['bankingDetails[accountNo]']
            && !formData['bankingDetails[ibanNo]']
            && !formData['bankingDetails[swiftCode]']
          ) {
            const account = this.props.user.company.accounts.find(acc => acc.default) || this.props.user.company.accounts[0];
            if (account) {
              formData['bankingDetails[bank]'] = account.bankName || '';
              formData['bankingDetails[bankAddress]'] = account.bankAddress || '';
              formData['bankingDetails[beneficiaryName]'] = account.beneficiaryName || '';
              formData['bankingDetails[accountNo]'] = account.accountNo || '';
              formData['bankingDetails[ibanNo]'] = account.ibanNumber || '';
              formData['bankingDetails[swiftCode]'] = account.swiftCode || '';
            }
          }
        }
      }
      this.refs.form.reset(formData);
    } else {
      if(this.props.vessel.channels && !this.props.vessel.channels.length) {
        this.props.toggleDialogMessage({ level: 'error', message: 'Sorry, no suitable channels.' });
        history.safeBack('/main-deck/general/')
      }
    }
    this.getStartInfo();
  }

  handleSelectChannel = (selectedChannels) => {
      this.setState({
        selectedChannels,
      });
  }

  handleMessageChange = (e) => {
    this.setState({ message: e.target.value });
  };
  handleAdditionalMessageChange = (e) => {
    this.setState({ additionalMessage: e.target.value });
  };
  handleVesselDetailsChange = (e) => {
    this.setState({ vesselDetails: e.target.value });
  };

  handleCancel = () => {
    this.props.handleCancel();
  };

  handleSend = (values) => {

    if (!values.terms) {
      values.terms = { default: [], user: [] };
    }
    if (!values.terms.default) {
      values.terms.default = [];
    }
    if (!values.terms.user) {
      values.terms.user = [];
    }
    if (this.channels) {
      const selectedChannels = this.channels.getValue();
      if(selectedChannels.length === 0 ) {
        // document.querySelector('.'+ s.select_chanels).scrollIntoView();
        const node = document.querySelector('.'+ s.select_chanels);
        scrollToAnchor(node);
        return;
      }
      values.vesselRequests = selectedChannels;


    }
    if (this.props.offer) {
      values._id = this.props.offer._id;
      values.vesselRequests = undefined;
    }
    const formValues = {};
    if (values.replyDurationType === "timeForReply") {
      formValues.timeForReply = values.timeForReply;
      values.dateForReply = undefined;
    }
    if (values.replyDurationType === "dateForReply") {
      const now = new Date();
      now.setDate(now.getDate() + 1);
      values.timeForReply = undefined;
      if (values.dateForReply) {
        formValues.dateForReply = values.dateForReply;
      } else {
        formValues.dateForReply = now;
      }
    }
    values.readinessDate = toUTC(values.readinessDate);
    values.cancellingDate = toUTC(values.cancellingDate);
    values.terms.default = values.terms.default.filter(term => !!term);
    if (values.bunkers) {
      if (values.bunkers && values.bunkers.delivery) {
        values.bunkers.delivery = values.bunkers.delivery.filter(bunker => !!bunker);
      }
      if (values.bunkers && values.bunkers.redelivery) {
        values.bunkers.redelivery = values.bunkers.redelivery.filter(bunker => !!bunker);
      }
    } else {
      values.bunkers = { delivery: [], redelivery: [] };
    }

    !values.bankingDetails && (values.bankingDetails = {}); //TODO remove this line
    if (!values.deliveryPlace.term) {
      values.deliveryPlace.term = '';
    }
    values.additionalMessage = this.state.additionalMessage;
    values.shareEmails = {
      cc: this.state.cc?.map(se => se.email),
      bcc: this.state.bcc?.map(se => se.email),
      replyTo: this.state.replyTo?.map(se => se.email),
    };
    const valuesToSend = { ...values, ...formValues };
    this.props.handleSend(valuesToSend).then((res) => {
      this.sent = true;
    })
  };
  handleReadinessChange = (e, value) => {
    this.setState({ readinessDate: value });
    if (
      this.state.cancellingDate &&
      value.getTime() > this.state.cancellingDate.getTime()
    ) {
      this.refs.cancellingDate.resetValue();
      this.setState({ cancellingDate: undefined });
    }
  };
  handleCancellingChange = (e, value) => {
    this.setState({ cancellingDate: value });
  };

  preventEnterSubmit(e) {
    const keyCode = e.which;
    if (keyCode === 13) { //enter
      e.nativeEvent.cancelBubble = true;
      e.stopPropagation();
      if (e.nativeEvent.target.nodeName !== 'TEXTAREA') {
        e.preventDefault();
      }
      e.nativeEvent.stopImmediatePropagation();
      return false;
    }
  }

  handleInvalidSubmit = (values) => {
    if (this.channels) {
      const selectedChannels = this.channels.getValue();
      if(selectedChannels.length === 0 ) {
        // document.querySelector('.'+ s.select_chanels).scrollIntoView();
        const node = document.querySelector('.'+ s.select_chanels);
        scrollToAnchor(node);
        return;
      }

    }
    const selector = '.' + s.root + ' [data-valid=false]';
    const invalidNodes = document.querySelectorAll(selector);
    if (!invalidNodes || !invalidNodes.length) {
      return;
    }
    const tabAttr = invalidNodes[0].attributes.getNamedItem('data-tab');
    if (tabAttr && tabAttr.value === 'standard-terms') {
      try {
        const list = this.refs.terms?._reactInternalInstance?._renderedComponent
          ?._renderedComponent?._instance?.refs?.standardList || this.refs.terms?._reactInternalInstance?._renderedComponent
          ?._renderedComponent?._renderedComponent?._instance?.refs?.standardList;
        if (!list?.state?.open) {
          list.handleNestedListToggle(new MouseEvent('click'));
        }
      } catch (e) {
        console.error(e);
      }
      setTimeout(() => {
        scrollToAnchor(invalidNodes[0]);
      }, 0);
    } else {
      scrollToAnchor(invalidNodes[0]);
    }
  };

  handleImdgToggle = (e, value) => {
    this.setState({ imdgChecked: value });
  };
  handleDeliveryChange = (value) => {
    this.setState({ delivery: value });
  };

  uploadFile = (data) => {
    this.setState(state => ({ attachmentsKey: state.attachmentsKey + 1, attachments: [...state.attachments, { name: data.name, _id: data._id, url: data.url }] }));
  };

  handleRemove = (e, _id) => {
    const index = this.state.attachments.findIndex(at => at._id === _id);
    this.setState({ attachments: removeFromArray(this.state.attachments, index) });
    e.preventDefault();
    e.cancelBubble = true;
    e.stopPropagation();
  }

  handleDurationType = (e) => {
    if (e.target.name === "dateForReply") {
      this.refs.replyDurationType.setValue("dateForReply");
    } else {
      this.refs.replyDurationType.setValue("timeForReply");
      this.refs.dateForReply.setValue(undefined);
    }
  }
  async getStartInfo() {
    try {
      const startInfo = await Cargo.getTradeStartInfo();
      let cc = [];
      if (this.props.offer) {
        const shareEmails = this.props.offer.shareEmails;
        if (shareEmails) {
          cc = [...(shareEmails.cc || []), ...(shareEmails.replyTo || [])];
        }
      }
      this.setState({ replyTo: startInfo.data.replyTo, cc }, this.refs.shareEmailForm.resetForm(mapInitialEmails(startInfo.data.replyTo, cc)));
    } catch (e) {
      console.error(e.message);
    }
  }
  updateEmails = ({ cc, bcc, replyTo }) => {
    this.state.cc = cc;
    this.state.bcc = bcc;
    this.state.replyTo = replyTo;
  };

  render() {
    let { vessel, user } = this.props;
    const channels = vessel.channels?.filter(channel => channel.company?._id !== channel.realUser?.companyId);
    vessel = vessel.vessel || vessel;
    const now = new Date();
    let isPublic;
    if (this.props.offer) {
      isPublic = this.props.offer.source === "market";
    } else {
      isPublic = channels?.some(c => c.computedChannel === "market" && this.state.selectedChannels?.some(x => x === c._id));
    }
    const SHARE_AVAILABLE_FIELDS = { cc: !isPublic, bcc: true, replyTo: isUserSubscribedToMarketplace(user), subject: false, to: false };
    return (
      <div className={s.root}>
        <div className={s.content}>
          <TcHeader handleClose={this.props.handleCancel} />
          <Formsy.Form
            noValidate
            onValidSubmit={this.handleSend}
            onInvalidSubmit={this.handleInvalidSubmit}
            ref="form"
            onKeyPress={this.preventEnterSubmit}
            className={s.tc_form}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  {channels
                    ? <div className={s.select_chanels}>
                      {channels.length > 1 ? <div className="bold">SELECT CHANNEL & POSITION FOR YOUR OFFER</div> : null}
                      <Channels
                        refInternal={(channels) => { this.channels = channels; }}
                        channels={channels}
                        selectable
                        vessel={vessel}
                        onChange={this.handleSelectChannel}
                      />
                    </div>
                    : null}
                </div>
                <div className="w-100" />
                <Formik initialValues={mapInitialEmails(this.state.replyTo)} onSubmit={() => {}} ref="shareEmailForm">
                  {(formik) => <div className="col-12">
                    <MailHeadersEdit availableFields={SHARE_AVAILABLE_FIELDS}/>
                    {this.updateEmails(formik.values)}
                  </div>}
                </Formik>
                <div className="col-12 col-md-auto grow">
                  <FormsyText
                    floatingLabelText="Your message (optional)"
                    fullWidth
                    className={cx(s.message, s.first_message)}
                    value={this.state.message}
                    onChange={this.handleMessageChange}
                    multiLine
                    name="message"
                    rowsMax={5}
                  />
                </div>
                <div className="w-100" />
                {this.state.previousValues['owner']
                  ? [
                    <div key="0" className="col preview_label">
                      OWNER/CARRIER:
                    </div>,
                    <div
                      key="1"
                      className="col-12 col-md-auto preview_value grow flex align-items-center"
                    >
                      <CompanyAutocomplete
                        hintText="Owner/Carrier"
                        name="owner"
                        required
                        fullWidth
                        validationErrors={{
                          isDefaultRequiredValue: 'required',
                        }}
                        onNewRequest={() => {
                        }}
                        previousValue={this.state.previousValues['owner']}
                      />
                    </div>,
                    <div key="2" className="w-100" />,
                    <div
                      key="2.1"
                      className={cx(
                        'col preview_label',
                        s.time_for_reply_label,
                      )}
                    >
                      Subjects to be lifted by:
                    </div>,
                    <div
                      key="3"
                      className={cx('col-12 col-md-6 preview_value')}
                    >
                      <_Subject
                        key="4"
                        name="ownerSubject"
                        currentValue={
                          this.props.offer && this.props.offer.ownerSubject
                        }
                        previousValue={
                          this.state.previousValues['ownerSubject[value]']
                        }
                      />
                    </div>,
                  ]
                  : null}
                <div className="w-100" />
                <div className="col preview_label">CHARTERER/MERCHANT:</div>
                <div className="col-12 col-md-auto preview_value grow flex align-items-center">
                  <CompanyAutocomplete
                    hintText="Charterer/Merchant"
                    name="charterer"
                    required
                    fullWidth
                    validationErrors={{ isDefaultRequiredValue: 'required' }}
                    onNewRequest={() => {
                    }}
                    defaultValue={this.props.user.company}
                    previousValue={this.state.previousValues['charterer']}
                  />
                </div>
                <div className="w-100" />
                <div
                  className={cx('col preview_label', s.time_for_reply_label)}
                >
                  Subjects to be lifted by:
                </div>
                <div className={cx('col-12 col-md-6 preview_value')}>
                  <_Subject
                    name="chartererSubject"
                    currentValue={
                      this.props.offer && this.props.offer.chartererSubject
                    }
                    previousValue={
                      this.state.previousValues['chartererSubject[value]']
                    }
                  />
                </div>
                <div className="w-100" />
                <div className="col preview_label">VESSEL:</div>
                <div className="col-12 col-md-auto preview_value grow flex align-items-center">
                  <VesselLink vessel={vessel} />
                  <div className="col-12 col-md-auto preview_value grow">
                    <NarrowFormsyText
                      floatingLabelText="Details"
                      fullWidth
                      className={s.vessel_details}
                      value={this.state.vesselDetails}
                      onChange={this.handleVesselDetailsChange}
                      name="vesselDetails"
                      rowsMax={1}
                    />
                    </div>
                </div>
                <div className="w-100" />
                <div className="col preview_label">Delivery place:</div>

                <div className="col-12 col-md-auto grow preview_value">
                  <div className={cx('row', s.delivery)}>
                    <div className="col-12 col-md-6">
                      <PortAutocomplete
                        hintText="WWR or enter port, region"
                        name="deliveryPlace[region]"
                        fullWidth
                        forceSelection
                        validationErrors={{
                          isDefaultRequiredValue: 'required',
                        }}
                        previousValue={
                          this.state.previousValues['deliveryPlace[region]']
                        }
                        onNewRequest={this.handleDeliveryChange}
                        params={{ withAreas: true, withWWR: true }}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      {this.state.delivery && this.state.delivery.name !== 'WWR' ?
                        <DeliveryTermsSelect
                          hintText="Terms"
                          name="deliveryPlace[term]"
                          fullWidth
                          autoWidth
                          defaultValue={''}
                          previousValue={
                            this.state.previousValues['deliveryPlace[term]']
                          }
                        />
                        : null}
                    </div>
                  </div>

                </div>
                <div className="w-100" />
                <div className="col preview_label">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span>
                      LAYCAN
                    </span>
                    <div style={{ margin: "0 2px", textTransform: "none" }}>
                      <CustomTooltip
                        style={{ alignSelf: "center", cursor: "pointer" }}
                        horizontalAlign="right"
                        tooltip={
                          <div>
                            <div>Readiness date to count from 00:00</div>
                            <div>Cancelling date to count until 23:59</div>
                          </div>
                        }
                      >
                        <ActionInfo style={{ width: "16px", height: "16px" }} />
                      </CustomTooltip>
                    </div>:
                  </div>
                </div>
                <div className="col-12 col-md-auto preview_value grow">
                  <div className={cx('row', s.laycan)} >
                    <div className="col-6 col-md-3">
                      <DatePicker
                        fullWidth
                        name="readinessDate"
                        container="inline"
                        hintText="Readiness date"
                        requiredError="required"
                        autoOk
                        ref="readinessDate"
                        minDate={now}
                        required
                        onChange={this.handleReadinessChange}
                        previousValue={
                          this.state.previousValues['readinessDate']
                        }
                      />
                    </div>
                    <div className="col-6 col-md-3">
                      <DatePicker
                        fullWidth
                        name="cancellingDate"
                        container="inline"
                        hintText="Cancelling date"
                        required
                        requiredError="required"
                        ref="cancellingDate"
                        minDate={this.state.readinessDate || now}
                        startDate={this.state.readinessDate}
                        autoOk
                        onChange={this.handleCancellingChange}
                        previousValue={
                          this.state.previousValues['cancellingDate']
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100" />
                <div className="col preview_label">Duration:</div>
                <div className="col-12 col-md-auto grow preview_value">
                  <div className={cx('row', s.delivery)}>
                    <div className="col-12 col-md-6">
                      <FormsyText
                        hintText=" "
                        validationErrors={{
                          minLength: 'min length 6 characters',
                        }}
                        requiredError="required"
                        name="duration"
                        required
                        fullWidth
                        previousValue={this.state.previousValues['duration']}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100" />
                <div className="col preview_label">Redelivery place:</div>
                <div className="col-12 col-md-auto grow preview_value">
                  <div className={cx('row', s.delivery)}>
                    <div className="col-12 col-md-6">
                      <PortAutocomplete
                        hintText="Enter port or region"
                        name="redeliveryPlace[region]"
                        required
                        forceSelection
                        fullWidth
                        validationErrors={{
                          isDefaultRequiredValue: 'required',
                        }}
                        previousValue={
                          this.state.previousValues['redeliveryPlace[region]']
                        }
                        params={{ withAreas: true }}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <RedeliveryTermsSelect
                        hintText="Terms"
                        name="redeliveryPlace[term]"
                        fullWidth
                        autoWidth
                        defaultValue={''}
                        previousValue={
                          this.state.previousValues['redeliveryPlace[term]']
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="w-100" />
                <div className="col preview_label">Cargo:</div>
                <div className="col-12 col-md-auto preview_value grow">
                  <FormsyText
                    hintText=" "
                    validationErrors={{ maxLength: 'max 15000 characters' }}
                    validations="maxLength:15000"
                    requiredError="required"
                    name="cargo"
                    required
                    fullWidth
                    previousValue={this.state.previousValues['cargo']}
                    multiLine
                    rowsMax={5}
                  />
                </div>
                <div className="w-100" />
                <div className="col preview_label">IMDG Cargo:</div>
                <div className="col-12 col-md-auto preview_value grow flex align-items-center">
                  <div className="flex justify-content-start align-items-center">
                    no
                    <Toggle
                      onChange={this.handleImdgToggle}
                      style={{ width: '50px' }}
                      value={this.state.imdgChecked}
                      previousValue={
                        this.state.previousValues['cargo']
                          ? !!this.state.previousValues['imdgChecked']
                          : undefined
                      }
                      name="imdgChecked"
                    />
                    {' '}
                    yes
                  </div>
                </div>
                <div className="w-100" />
                {this.state.imdgChecked
                  ? [
                    <div key="0" className="col preview_label">
                      &nbsp;
                    </div>,
                    <div key="1" className="col-12 col-md-auto grow">
                      <FormsyText
                        hintText="Quantity of IMO cargo allowed for carriage, mt"
                        validations="isNumeric,gt0"
                        validationErrors={{
                          isNumeric: 'only numbers',
                          gt0: 'must be greater than 0',
                        }}
                        name="quantityOfIMO"
                        fullWidth
                        previousValue={
                          this.state.previousValues['quantityOfIMO']
                        }
                      />
                    </div>,
                  ]
                  : null}

                <div className="w-100" />
                <div className="col preview_label">Hire (per day):</div>
                <div className="col-12 col-md-3 preview_value">
                  <FormsyText
                    hintText=" "
                    validations="isNumeric,gt0"
                    validationErrors={{
                      isNumeric: 'only numbers',
                      gt0: 'must be greater than 0',
                    }}
                    requiredError="required"
                    name="hirePerDay[value]"
                    required
                    fullWidth
                    previousValue={
                      this.state.previousValues['hirePerDay[value]']
                    }
                  />
                </div>
                <div className="col-6 col-md-2 preview_value">
                  <FormsySelect
                    requiredError="required"
                    name="hirePerDay[currency]"
                    fullWidth
                    autoWidth
                    required
                    defaultValue="USD"
                    style={{ display: 'block' }}
                    previousValue={
                      this.state.previousValues['hirePerDay[currency]']
                    }
                  >
                    <MenuItem key="USD" value="USD" primaryText="USD" />
                    <MenuItem key="EUR" value="EUR" primaryText="EUR" />
                  </FormsySelect>
                </div>
                <div className="w-100" />
                <div className="col preview_label">ILHOC:</div>
                <div className="col-6 col-md-3 preview_value">
                  <FormsyText
                    hintText=" "
                    validations="isNumeric,gt0"
                    validationErrors={{
                      isNumeric: 'only numbers',
                      gt0: 'must be greater than 0',
                    }}
                    requiredError="required"
                    name="ilhoc[value]"
                    required
                    fullWidth
                    previousValue={this.state.previousValues['ilhoc[value]']}
                  />
                </div>
                <div className="col-6 col-md-2 preview_value">
                  <FormsySelect
                    requiredError="required"
                    name="ilhoc[currency]"
                    fullWidth
                    autoWidth
                    required
                    defaultValue="USD"
                    style={{ display: 'block' }}
                    previousValue={this.state.previousValues['ilhoc[currency]']}
                  >
                    <MenuItem key="USD" value="USD" primaryText="USD" />
                    <MenuItem key="EUR" value="EUR" primaryText="EUR" />
                  </FormsySelect>
                </div>
                <div className="w-100" />
                <div className="col preview_label">CVE:</div>
                <div className="col-6 col-md-3 preview_value">
                  <FormsyText
                    hintText=" "
                    validations="isNumeric,gt0"
                    validationErrors={{
                      isNumeric: 'only numbers',
                      gt0: 'must be greater than 0',
                    }}
                    requiredError="required"
                    name="cve[value]"
                    required
                    fullWidth
                    previousValue={this.state.previousValues['cve[value]']}
                  />
                </div>
                <div className="col-6 col-md-2 preview_value">
                  <FormsySelect
                    requiredError="required"
                    name="cve[currency]"
                    fullWidth
                    autoWidth
                    required
                    defaultValue="USD"
                    style={{ display: 'block' }}
                    previousValue={this.state.previousValues['cve[currency]']}
                  >
                    <MenuItem key="USD" value="USD" primaryText="USD" />
                    <MenuItem key="EUR" value="EUR" primaryText="EUR" />
                  </FormsySelect>
                </div>
                <div className="w-100" />
                <div className="col preview_label">COMM:</div>
                <div className="col-12 col-md-3 preview_value">
                  <FormsyText
                    name="comm"
                    validationErrors={{
                      isNumeric: 'only numbers',
                    }}
                    validationError="0 - 25%"
                    validations="isNumeric,min:0,max:25"
                    fullWidth
                    type={'number'}
                    step={0.25}
                    min={0}
                    max={25}
                    defaultValue={0}
                    previousValue={this.state.previousValues.comm}
                  />
                </div>
                <div className="w-100" />
                <div className="col preview_label">REDELIVERY NOTICES (DAYS):</div>
                <div className="col-12 col-md-5 preview_value">
                  <FormsySelect
                    requiredError="required"
                    name="redeliveryNotices"
                    fullWidth
                    autoWidth
                    required
                    defaultValue="30/20/15/10/7/5/3/2/1"
                    previousValue={this.state.previousValues['redeliveryNotices']}
                  >
                    <MenuItem key="30/20/15/10/7/5/3/2/1" value="30/20/15/10/7/5/3/2/1" primaryText="30/20/15/10/7/5/3/2/1" />
                    <MenuItem key="15/10/7/5/3/2/1" value="15/10/7/5/3/2/1" primaryText="15/10/7/5/3/2/1" />
                    <MenuItem key="10/7/5/3/2/1" value="10/7/5/3/2/1" primaryText="10/7/5/3/2/1" />
                    <MenuItem key="7/5/4/3/2/1" value="7/5/4/3/2/1" primaryText="7/5/4/3/2/1" />
                    <MenuItem key="5/4/3/2/1" value="5/4/3/2/1" primaryText="5/4/3/2/1" />
                  </FormsySelect>

                </div>
                <div className="w-100" />
                <div className="col preview_label">TRADING LIMITS:</div>
                <div className="col-12 col-md-5 preview_value">
                  <FormsyText
                    hintText=" "
                    requiredError="required"
                    name="tradingLimits"
                    required
                    fullWidth
                    previousValue={this.state.previousValues['tradingLimits']}
                  />
                </div>
                <div className="w-100" />
                {this.state.previousValues['cargo'] ?
                [
                  <div key="2" className="col preview_label">TRADING EXCLUSIONS:</div>,
                  <div key="3" className="col-12 col-md-5 preview_value">
                    <FormsyText
                      hintText=" "
                      requiredError="required"
                      name="tradingExclusions"
                      fullWidth
                      previousValue={this.state.previousValues['tradingExclusions']}
                    />
                  </div>,
                  <div key="4" className="w-100" />,
                  <div key="5" className="col preview_label">COUNTRIES AFFECTED BY WAR:</div>,
                  <div key="6" className="col-12 col-md-5 preview_value">
                    <FormsyText
                      hintText=" "
                      name="countriesAffectedByWar"
                      fullWidth
                      previousValue={this.state.previousValues['countriesAffectedByWar']}
                    />
                  </div>,
                  <div key="7" className="w-100" />,
                  <div key="0" className={cx('col preview_label', s.bunkers_label)}>DELIVERY BUNKERS:</div>,
                  <div key="1" className="col">
                    <Bunkers
                      bunkers={this.props.offer && this.props.offer.bids.changedRequest.bunkers.delivery}
                      previousValues={this.props.offer && this.props.offer.bids.originalRequest && this.props.offer.bids.originalRequest.bunkers.delivery}
                      disabled={this.state.userType === 'cargoOwner'}
                      namePrefix={'bunkers[delivery]'}
                    />
                  </div>,
                  <div key="8" className="w-100" />,
                  <div key="9" className={cx('col preview_label', s.bunkers_label)}>REDELIVERY BUNKERS:</div>,
                  <div key="10" className="col">
                    <Bunkers
                      bunkers={this.props.offer && this.props.offer.bids.changedRequest.bunkers.redelivery}
                      previousValues={this.props.offer && this.props.offer.bids.originalRequest && this.props.offer.bids.originalRequest.bunkers.redelivery}
                      disabled={this.state.userType === 'cargoOwner'}
                      namePrefix={'bunkers[redelivery]'}
                    />
                  </div>,
                ]
                  : null}
                <div className="w-100" />
                {this.state.previousValues.cargo ?
                [
                  <div key="0" className={cx('col preview_label', s.bank_details_label)}>Banking details:</div>,
                  <div
                    key="1"
                    className={cx(
                      'col-12 col-md-auto grow',
                      s.bank_details)}
                  >
                    <BankDetails
                      required
                      disabled={this.state.userType === 'cargoOwner'}
                      bankingDetails={this.props.offer && this.props.offer.bids.changedRequest.bankingDetails}
                      previousValues={this.props.offer && this.props.offer.bids.originalRequest && this.props.offer.bids.originalRequest.bankingDetails}
                    />
                  </div>,
                ]
                  : null}
                <div className="w-100" />
                <div className="col preview_label">Grace period:</div>
                <div className="col-12 col-md-3 preview_value">
                  <FormsyText
                    hintText="Number of days"
                    validations="isNumeric,isInt,gt0,min:1,max:10"
                    validationErrors={{
                      isNumeric: 'only numbers',
                      gt0: 'from 1 to 10',
                      isInt: 'integer only',
                      min: 'from 1 to 10',
                      max: 'from 1 to 10',
                    }}
                    name="gracePeriod"
                    fullWidth
                    previousValue={this.state.previousValues['gracePeriod']}
                  />
                </div>
                <div className="w-100" />
                <div className="col preview_label">REQUISITION PERIOD:</div>
                <div className="col-12 col-md-3 preview_value">
                  <FormsyText
                    hintText="Number of month"
                    validations="isNumeric,isInt,gt0,min:1,max:10"
                    validationErrors={{
                      isNumeric: 'only numbers',
                      gt0: 'from 1 to 10',
                      isInt: 'integer only',
                      min: 'from 1 to 10',
                      max: 'from 1 to 10',
                    }}
                    name="requisitionPeriod"
                    fullWidth
                    previousValue={this.state.previousValues['requisitionPeriod']}
                  />
                </div>
                <div className="w-100" />
                <div className="col preview_label">Intermediate cleaning:</div>
                <div className="col-12 col-md-auto grow preview_value flex">
                  <div>
                    <FormsyText
                      hintText="Amount"
                      validations="isNumeric,gt0"
                      validationErrors={{
                        isNumeric: 'only numbers',
                        gt0: 'must be greater than 0',
                      }}
                      name="cleaningOfHolds"
                      style={{ width: '161px' }}
                      previousValue={this.state.previousValues['cleaningOfHolds']}
                    />
                  </div>
                  <div className="align-items-center">USD, per hold</div>
                </div>
                <div className="w-100" />
                <div className="col preview_label">Servants/Supercargo meals:</div>
                <div className="col-12 col-md-auto grow preview_value align-items-center">
                  <div>
                    <FormsyText
                      hintText="Amount"
                      validations="isNumeric,min:0,max:50"
                      validationError="1 - 50"
                      name="servantsSupercargoMeals"
                      style={{ width: '161px' }}
                      previousValue={this.state.previousValues['servantsSupercargoMeals']}
                    />
                  </div>
                  <div className="align-items-center">USD, per day</div>
                </div>
                <div className="w-100" />
                <div className="col preview_label">Dry Docking:</div>
                <div className={cx('col-12 col-md-auto grow preview_value align-items-center')}>
                  <div className="flex justify-content-start align-items-center">
                    not allowed
                    <Toggle
                      style={{ width: '50px' }}
                      previousValue={this.state.previousValues['dryDocking']}
                      name="dryDocking"
                    />
                    {' '}
                    allowed
                  </div>
                </div>
                <div className="w-100" />
                <div className="col preview_label">Contract:</div>
                <div className="col-12 col-md-3 preview_value">
                  <ContractSelect
                    required
                    requiredError="required"
                    name="contract"
                    type="timeCharter"
                    fullWidth
                    autoWidth
                    previousValue={this.state.previousValues['contract']}
                  />
                </div>
                <div className="w-100" />
                <div className="col preview_label">
                  TIME FOR REPLY:
                </div>
                <div
                  className={cx(
                    'col-12 col-md-3 preview_value',
                    s.time_for_reply
                  )}
                >
                  <FormsyRadioGroup
                    name="replyDurationType"
                    required
                    requiredError="required"
                    className={s.radio_group}
                    value="timeForReply"
                    ref="replyDurationType"
                  >
                   <FormsyRadio className={s.radio_time} value="timeForReply" />
                   <FormsyRadio className={s.radio_date} value="dateForReply" />
                  </FormsyRadioGroup>
                  <FormsySelect
                    className={s.time_for_repl}
                    requiredError="required"
                    name="timeForReply"
                    autoWidth
                    fullWidth
                    required
                    defaultValue={24}
                    onClick={this.handleDurationType}
                    previousValue={this.state.previousValues['timeForReply']}
                  >
                    <MenuItem key="24" value={24} primaryText="24 hr" />
                    <MenuItem key="12" value={12} primaryText="12 hr" />
                    <MenuItem key="6" value={6} primaryText="6 hr" />
                    <MenuItem key="3" value={3} primaryText="3 hr" />
                    <MenuItem key="2" value={2} primaryText="2 hr" />
                    <MenuItem key="1" value={1} primaryText="1 hr" />
                    <MenuItem
                      key="0.5"
                      value={0.5}
                      primaryText="30 min"
                    />
                    <MenuItem
                      key="0.25"
                      value={0.25}
                      primaryText="15 min"
                    />
                  </FormsySelect>
                  <FormsyDateTime
                    className={s.date_for_reply}
                    style={{ right: 0 }}
                    ref="dateForReply"
                    withIcon name="dateForReply"
                    placeholder="Select date & time"
                    minDate={this.state.minDate}
                    onClick={this.handleDurationType}
                  />
                </div>
                <div className="w-100" />
                <div className={cx('col preview_label', s.terms_label)}>
                  Terms:
                </div>
                <div
                  className={cx(
                    'col-12 col-md-auto grow',
                    s.terms,
                    s.terms_value,
                  )}
                >
                  <Terms
                    isCounterOffer={this.props.offer}
                    showStandard
                    termsType="tcTerms"
                    standardTermsList={this.context.timeTerms}
                    selectedTerms={this.props.offer && this.props.offer.terms}
                    previousValues={this.state.previousValues}
                    ref="terms"
                    categoriesSelectorEnabled={false}
                  />
                </div>
                <div className="w-100" />
                <div
                  className={cx(
                    'col-12 col-md-auto grow',
                  )}
                >
                  <hr style={{ borderTop: "1px dashed #D2D2D2" }}/>
                  <FormsyAttachments files={this.state.attachments} name="attachments" oldFilesLocked />
                </div>
                <div className="w-100" />
                <div className="col-12 col-md-auto grow">
                  <FormsyText
                    floatingLabelText="Your message (optional)"
                    fullWidth
                    className={s.message}
                    value={this.state.additionalMessage}
                    onChange={this.handleAdditionalMessageChange}
                    multiLine
                    name="additionalMessage"
                    rowsMax={5}
                  />
                </div>
                <div className="w-100" />

                <div className="col-12 col-md-auto preview_value grow">
                  <div className={cx('col-12 col-md-12', s.buttons)}>
                    <RaisedButton
                      label="Cancel"
                      disableTouchRipple
                      disableFocusRipple
                      secondary
                      onTouchTap={this.props.handleCancel}
                      disabled={this.props.loading}
                    />
                    <RaisedButton
                      label="Send Offer"
                      disableTouchRipple
                      disableFocusRipple
                      type="submit"
                      primary
                      disabled={this.props.loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Formsy.Form>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(TcOffer);

class _Subject extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.state.checked = !!(props.currentValue && props.currentValue.value);
  }

  static defaultProps = {};
  static propTypes = {};

  handleToggle = (e, value) => {
    this.setState({ checked: value });
  };

  render() {
    const name = this.props.name;
    const locked = this.props.currentValue && this.props.currentValue.locked;
    return (
      <div
        className={cx(
          s.subject,
          !this.state.checked && this.props.previousValue ? s.red_subject : '',
        )}
      >
        <div className="">
          <FormsyCheckbox
            label="Define"
            name={name + '[checked]'}
            disabled={locked}
            onChange={this.handleToggle}
            defaultValue={this.state.checked}
          />
        </div>
        <div className="">
          {this.state.checked
            ? <NarrowSelect
              requiredError="required"
              name={name + '[value]'}
              autoWidth
              fullWidth
              required
              defaultValue={this.props.previousValue || 24}
              disabled={locked}
              previousValue={this.props.previousValue}
            >
              <MenuItem key="24" value={24} primaryText="24 hr" />
              <MenuItem key="12" value={12} primaryText="12 hr" />
              <MenuItem key="6" value={6} primaryText="6 hr" />
              <MenuItem key="3" value={3} primaryText="3 hr" />
              <MenuItem key="2" value={2} primaryText="2 hr" />
              <MenuItem key="1" value={1} primaryText="1 hr" />
            </NarrowSelect>
            : null}
        </div>

      </div>
    );
  }
}
export const Subject = withStyles(s)(_Subject);

function TcHeader({ handleClose }) {
  return (
    <div className={s.cargo_details_header}>
      <div className={s.request}>OFFER T/C RATE </div>
      <div className={s.cargo_details_buttons}>
        <a className={s.close} onClick={handleClose}>
          <NavigationClose />
        </a>
      </div>
    </div>
  );
}
