import React, { Component } from 'react';
import ChatMailIcon from '../Icons/ChatMailIcon';
import EmptyList from '../Common/EmptyList';
import RaisedButton from '../Common/RaisedButton';
import Link from '../Link/Link';

export default function MailsNotConnected(){
  return (
  <EmptyList Icon={ChatMailIcon} iconStyle={{width:'48px',height:'48px'}} maxTextWidth='570px' title="No e-mails yet" titleStyle={{ fontSize: "18px", color: "#333", }}
  descriptionStyle={{fontSize: "15px", color: "#787878",lineHeight:'150%'}}
  description={[`To use this Service, please connect your mail.
  Shipnext is a Microsoft Partner solution, and provides an email service that can easily pair with Outlook, Gmail, Sedna and other email services.`,
  `To set up E-mail tagging, please go to Settings/Tags. This will help you and your team use Shipnext algorithm and AI to automate tagging.`
]} button={<RaisedButton
  containerElement={<Link to={`/settings/tags`} />}
  styles="xs"
  buttonStyle={{
    background: "#4380C7",
    color: "#fff",
    width: "fit-content"
  }}
  primary
  label="settings"
/>} />
)
}