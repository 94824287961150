import produce from "immer";
import {uniqBy} from "lodash";

/** @name CONTACTS_GET_COMPANIES_LIST_REDUCER */
 export default function CONTACTS_GET_COMPANIES_LIST(state, { res, payload = {} }) {
  return produce(state, draft => {
    draft.companyList.filters = payload.filters;
    draft.companyList.sort = payload.sort;
    if (payload.page === 1) {
      draft.companyList.data = res.data;
      draft.companyList.page = 1;
    } else {
      draft.companyList.data = uniqBy([...draft.companyList.data, ...res.data], c => c.id);
      draft.companyList.page = payload.page;
    }
    if (res.data.length === 50) {
      draft.companyList.next = payload.page + 1;
    } else {
      draft.companyList.next = undefined;
    }
  });
}
