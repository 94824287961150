/**@see  {@link CARGO_REQUEST_REDUCER}*/
export const CARGO_REQUEST = 'CARGO_REQUEST';
/**@see  {@link CARGO_REQUEST_REQUEST_REDUCER}*/
export const CARGO_REQUEST_REQUEST = 'CARGO_REQUEST_REQUEST';
/**@see  {@link CARGO_REQUEST_FAILED_REDUCER}*/
export const CARGO_REQUEST_FAILED = 'CARGO_REQUEST_FAILED';

/**@see  {@link CARGO_REQUEST_LIST_REDUCER}*/
export const CARGO_REQUEST_LIST = 'CARGO_REQUEST_LIST';
/**@see  {@link CARGO_REQUEST_LIST_REQUEST_REDUCER}*/
export const CARGO_REQUEST_LIST_REQUEST = 'CARGO_REQUEST_LIST_REQUEST';
/**@see  {@link CARGO_REQUEST_LIST_FAILED_REDUCER}*/
export const CARGO_REQUEST_LIST_FAILED = 'CARGO_REQUEST_LIST_FAILED';

/**@see  {@link CARGO_SEND_FREIGHT_REQUEST_REDUCER}*/
export const CARGO_SEND_FREIGHT_REQUEST = 'CARGO_SEND_FREIGHT_REQUEST';
/**@see  {@link CARGO_SEND_FREIGHT_REQUEST_REQUEST_REDUCER}*/
export const CARGO_SEND_FREIGHT_REQUEST_REQUEST =
  'CARGO_SEND_FREIGHT_REQUEST_REQUEST';
/**@see  {@link CARGO_SEND_FREIGHT_REQUEST_FAILED_REDUCER}*/
export const CARGO_SEND_FREIGHT_REQUEST_FAILED =
  'CARGO_SEND_FREIGHT_REQUEST_FAILED';

/**@see  {@link CARGO_SEND_OFFER_REDUCER}*/
export const CARGO_SEND_OFFER = 'CARGO_SEND_OFFER';
/**@see  {@link CARGO_SEND_OFFER_REQUEST_REDUCER}*/
export const CARGO_SEND_OFFER_REQUEST = 'CARGO_SEND_OFFER_REQUEST';
/**@see  {@link CARGO_SEND_OFFER_FAILED_REDUCER}*/
export const CARGO_SEND_OFFER_FAILED = 'CARGO_SEND_OFFER_FAILED';

/**@see  {@link CARGO_FIX_REDUCER}*/
export const CARGO_FIX = 'CARGO_FIX';
/**@see  {@link CARGO_FIX_REQUEST_REDUCER}*/
export const CARGO_FIX_REQUEST = 'CARGO_FIX_REQUEST';
/**@see  {@link CARGO_FIX_FAILED_REDUCER}*/
export const CARGO_FIX_FAILED = 'CARGO_FIX_FAILED';

/**@see  {@link CARGO_REJECT_REDUCER}*/
export const CARGO_REJECT = 'CARGO_REJECT';
/**@see  {@link CARGO_REJECT_REQUEST_REDUCER}*/
export const CARGO_REJECT_REQUEST = 'CARGO_REJECT_REQUEST';
/**@see  {@link CARGO_REJECT_FAILED_REDUCER}*/
export const CARGO_REJECT_FAILED = 'CARGO_REJECT_FAILED';
/**@see  {@link CARGO_REQUEST_REFRESH_STATUS_REDUCER}*/
export const CARGO_REQUEST_REFRESH_STATUS = 'CARGO_REQUEST_REFRESH_STATUS';
/**@see  {@link CARGO_REQUEST_REFRESH_STATUS_REQUEST_REDUCER}*/
export const CARGO_REQUEST_REFRESH_STATUS_REQUEST = 'CARGO_REQUEST_REFRESH_STATUS_REQUEST';
/**@see  {@link CARGO_REQUEST_REFRESH_STATUS_FAILED_REDUCER}*/
export const CARGO_REQUEST_REFRESH_STATUS_FAILED = 'CARGO_REQUEST_REFRESH_STATUS_FAILED';
/**@see  {@link CARGO_REQUEST_DELETE_REDUCER}*/
export const CARGO_REQUEST_DELETE = 'CARGO_REQUEST_DELETE';
/**@see  {@link CARGO_REQUEST_DELETE_REQUEST_REDUCER}*/
export const CARGO_REQUEST_DELETE_REQUEST = 'CARGO_REQUEST_DELETE_REQUEST';
/**@see  {@link CARGO_REQUEST_DELETE_FAILED_REDUCER}*/
export const CARGO_REQUEST_DELETE_FAILED = 'CARGO_REQUEST_DELETE_FAILED';

/**@see  {@link MY_CARGO_DETAILS_REDUCER}*/
export const MY_CARGO_DETAILS = 'MY_CARGO_DETAILS';
/**@see  {@link MY_CARGO_DETAILS_REQUEST_REDUCER}*/
export const MY_CARGO_DETAILS_REQUEST = 'MY_CARGO_DETAILS_REQUEST';
/**@see  {@link MY_CARGO_DETAILS_FAILED_REDUCER}*/
export const MY_CARGO_DETAILS_FAILED = 'MY_CARGO_DETAILS_FAILED';

/**@see  {@link SEND_VOYAGE_REMAINDER_REDUCER}*/
export const SEND_VOYAGE_REMAINDER = 'SEND_VOYAGE_REMAINDER';
/**@see  {@link SEND_VOYAGE_REMAINDER_REQUEST_REDUCER}*/
export const SEND_VOYAGE_REMAINDER_REQUEST = 'SEND_VOYAGE_REMAINDER_REQUEST';
/**@see  {@link SEND_VOYAGE_REMINDER_FAILED_REDUCER}*/
export const SEND_VOYAGE_REMAINDER_FAILED = 'SEND_VOYAGE_REMAINDER_FAILED';

/**@see  {@link CANCEL_VOYAGE_REMAINDER_REDUCER}*/
export const CANCEL_VOYAGE_REMAINDER = 'CANCEL_VOYAGE_REMAINDER';
/**@see  {@link CANCEL_VOYAGE_REMAINDER_REQUEST_REDUCER}*/
export const CANCEL_VOYAGE_REMAINDER_REQUEST = 'CANCEL_VOYAGE_REMAINDER_REQUEST';
/**@see  {@link CANCEL_VOYAGE_REMAINDER_FAILED_REDUCER}*/
export const CANCEL_VOYAGE_REMAINDER_FAILED = 'CANCEL_VOYAGE_REMAINDER_FAILED';


export const UPDATE_CARGO_DETAILS = 'UPDATE_CARGO_DETAILS';
export const UPDATE_CARGO_DETAILS_REQUEST = 'UPDATE_CARGO_DETAILS_REQUEST';
export const UPDATE_CARGO_DETAILS_FAILED = 'UPDATE_CARGO_DETAILS_FAILED';
