import * as handlers from './port/index';

export default function port(
  state = {
    portList: {
      data: [],
      page: 0,
      next: 1,
      filters: { query: '' },
      sort: { field: 'name', value: 1 },
      loading: false,
    },
    dialogPortId: null,
    uid: null,
  },
  action,
) {
  if (!handlers[action.type]) {
    return state;
  }
  try {
    return handlers[action.type](state, action) || state;
  } catch (e) {
    console.error(e);
    try {
      if (typeof window !== 'undefined' && typeof window.Raven !== 'undefined') {
        window.Raven.captureException(e);
      }
    } catch (e2) {
      console.error(e2);
    }
    return state;
  }
}
/** @typedef {Object} ContractState
 * @property {List} contractList
 */
