import * as constants from '../constants/emails';
import EmailsApi from '../core/api/EmailsApi';
import type { INewMessageReq } from '../components/Emails/types';

export function getEmailList(payload: { folder: string, filters: any, cursor: string}) {
  return async (dispatch, getState) => {
    dispatch({
      type: constants.EMAILS_GET_LIST_REQUEST,
    });
    try {
      const res = await EmailsApi.getEmailsList(payload);
      dispatch({
        type: constants.EMAILS_GET_LIST,
        payload: { ...payload, res },
        takeLatest: true,
      });
    } catch (error) {
      console.error(error);
    }
  };
}

export function checkEmail(id, checked) {
  return {
    type: constants.EMAILS_CHECK_EMAIL,
    payload: { id, selected: checked },
  };
}

export function checkAll(checked) {
  return {
    type: constants.EMAILS_CHECK_ALL,
    payload: { selected: checked },
  };
}

export function toggleTheme(dark) {
  return {
    type: constants.EMAILS_TOGGLE_THEME,
    payload: dark,
  }
}

export function archiveEmails(ids, archive) {
  return {
    type: constants.EMAILS_ARCHIVE,
    promise: EmailsApi.archiveEmails({ ids, archive }),
    payload: { ids, archive },
  }
}

export function prepareDeleteEmails(ids, isThread, deleteInMailbox) {
  return {
    type: constants.EMAILS_DELETE,
    payload: { ids, isThread, deleteInMailbox },
  }
}

export function deleteEmails(ids, isThread, deleteInMailbox) {
  return {
    type: constants.EMAILS_DELETE,
    promise: EmailsApi.deleteEmails({ ids, isThread, deleteInMailbox }),
    payload: { ids, isThread, deleteInMailbox },
  }
}

export function getEmailDetails(id, silent = false) {
  return (dispatch, getState) => {
    const { user } = getState().login;
    return dispatch({
      type: constants.EMAILS_GET_EMAIL,
      promise: EmailsApi.getEmailDetails(id, user),
      payload: { id, silent },
      takeLatest: true,
    })
  }
}

export function sendReply(id, reply: INewMessageReq) {
  return {
    type: constants.EMAIL_SEND_REPLY,
    promise: EmailsApi.sendReply(id, reply),
    payload: { id, reply },
  };
}

export function sendNewMessage(reply: INewMessageReq) {
  return {
    type: constants.EMAILS_SEND_NEW_MESSAGE,
    promise: EmailsApi.sendNewMessage(reply),
    payload: { reply },
  };
}

export function markAsRead(id, threads, changes) {
  if (typeof changes.read !== 'undefined') {
    changes.hasUnread = !changes.read;
  }
  return {
    type: constants.EMAILS_MARK,
    promise: EmailsApi.markAsRead({ read: changes.read, ids: threads || [id], markThreads: !threads }),
    payload: { idList: [id], threads, changes },
  };
}
//TODO rename to markEmailsAs
export function markEmailAs(idList, changes) {
  if (typeof changes.read !== 'undefined') {
    changes.hasUnread = !changes.read;
  }
  return {
    type: constants.EMAILS_MARK,
    promise: EmailsApi.markAsRead({ ids: idList , read: changes.read, markThreads: true }),
    payload: { idList, changes: { ...changes, selected: false } },
  };
}

export function changeEmailTags(id, changes :{toAdd: [], toRemove: []}) {
  return {
    type: constants.EMAILS_CHANGE_TAGS,
    promise: EmailsApi.changeTags(id, changes),
    payload: { id, changes },
  };
}

export function shareEmail(id, { visibleTo }) {
  return {
    type: constants.EMAILS_SHARE_EMAIL,
    promise: EmailsApi.sendShared(id, { visibleTo: visibleTo.map(u => u._id) }),
    payload: { id, visibleTo },
  };
}
