import React from "react";
import { fileToFormData, removeFromArray, replaceInArray } from "./utils";
import CalculatorApi from "./api/Calculator";
import { connect } from "react-redux";
import Vessel from "./api/Vessel";

/*
TODO:

  USE React.Component for extending default Component
  it will eliminate all possible collisions with arguments / outer scope variable names

*/

import PropTypes from 'prop-types';

import PubSub from "pubsub-js";
import { ratingSave, commentSave } from "./../actions/vessel";
import history from "./history";
import createFragment from 'react-addons-create-fragment';
import ConfirmDialog from '../components/Common/ConfirmDialog';
import { uploadImage } from '../actions/login';
export function withConfirmationDialog(wrapperElProps = {}, blockOnMount = true) {
  return function (Component) {
    return class _ConfirmationDialogWrapper extends React.Component {
      static contextTypes = {
        blockTransition: PropTypes.func,
      };
      state = {
        confirmTransitionDialog: null,
        unblock: null,
      }
      // must return unblock function
      // eslint-disable-next-line no-return-assign
      blockHistory = () => {
        const unblockCallback = this.context.blockTransition('Are you sure you want to leave this page?');
        this.setState({
          unblock: unblockCallback,
        }, () => {
          this.context?.setBlockUnblock?.({
            block: this.blockHistory,
            unblock: this.unblock,
          });
        });
        return unblockCallback;
      };

      componentWillUnmount() {
        if (typeof window !== 'undefined') {
          this.state.unblock && this.state.unblock();
        }
      }

      unblockAndBlockTransition = () => {
        this.state.unblock && this.state.unblock();
        setTimeout(() => {
          const unblockCallback = this.context.blockTransition('Are you sure you want to leave this page?');
          this.setState({
            unblock: unblockCallback,
          });
        }, 0);
      }

      componentDidMount() {
        if (typeof window !== 'undefined') {
          if (blockOnMount) {
            this.blockHistory();
          }
        }
      }

      render() {
        const { unblockAndBlockTransition, blockHistory } = this;
        const { unblock } = this.state;
        return (
          <div {...wrapperElProps}>
            <Component unblock={unblock} unblockAndBlockTransition={unblockAndBlockTransition} blockHistory={blockHistory} {...this.props} />
          </div>
        );
      }
    };
  }
}
export class WithUserUploads extends React.Component {
  static defaultProps = {
    accept:
      ".xls, .xlsx, .txt, .csv, .pdf, .jpg, .jpeg, .png, .doc, .docx, .rtf, .tiff, .dwg, .dxf, .vsd, .xlsm, .zip, .rar, .7z",
    maxSizeMb: 25,
    maxFiles: Number.MAX_SAFE_INTEGER,
  };

  state = {
    erroredFiles: [],
  };

  validateUserUploads = async (ev, batchSuccess = false) => {
    const input = ev.target;
    ev.stopPropagation();
    let uploadedFiles = [];
    const allowedExt = this.props.accept.split(", ");
    let files = Array.from(ev.currentTarget.files);
    files = files.slice(0, Math.max(this.props.maxFiles - (this.props.files?.length || 0), 0));
    const erroredFiles = [];
    // batch valid files and upload them together
    const validatedFiles = [];
    this.setState({ uploading: true });
    for (const file of files) {
      try {
        const nameParts = file.name.split(".");
        const extension =
          "." + nameParts[nameParts.length - 1].toLocaleLowerCase();

        let err = null;
        if (allowedExt.length !== 0 && allowedExt.indexOf(extension) === -1) {
          err = {
            message: {
              errors: [{ messages: [`Allowed only ${this.props.accept}`] }]
            }
          };
        }
        const sizeMb = file.size / (1024 * 1024);

        if (sizeMb > this.props.maxSizeMb) {
          err = {
            message: {
              errors: [
                {
                  messages: [
                    `File size exceeds the maximum limit (${this.props.maxSizeMb}MB)`
                  ]
                }
              ]
            }
          };
        }

        if (err) {
          this.props.onError?.(err);
          throw err;
        }

        const formData = fileToFormData(file, 'attachment');
        if (batchSuccess) {
          validatedFiles.push(formData);
        }
        else {
          await this.props.onSuccess(formData, file.size);
        }
        if (this.props.shouldUpload) {
          const res = await uploadImage(formData, { privateAttachment: this.props.privateAttachment }).promise;
          uploadedFiles = [...uploadedFiles, { ...res.data, size: file.size }];
        }
      } catch (e) {
        erroredFiles.push(file);
        console.error(e);
        const errors = e.message?.errors;
        if (errors && errors[0] && errors[0].messages) {
          file.errorMessage = errors[0].messages.join(", ");
        }
      }
    }

    if (validatedFiles.length) {
      await this.props.onSuccess(validatedFiles, uploadedFiles);
    }

    this.setState({ erroredFiles, uploadedFiles, uploading: false });
    input.value = '';
    input.type = '';
    input.type = 'file';
    return validatedFiles;
  };

  render() {
    const { erroredFiles, uploadedFiles } = this.state;
    const { validateUserUploads } = this;
    const { children, accept, uploading } = this.props;

    return children({ erroredFiles, validateUserUploads, uploadedFiles, accept, uploading });
  }
}

export function withDynamicStack(initialState) {
  return function _DynamicStack(Component) {
    return class _DynamicStackHolder extends React.Component {
      constructor(props) {
        super(props);

        this.state = this.prepareInitialState(initialState);
      }

      static childContextTypes = {
        right: PropTypes.array,
        left: PropTypes.array,
        addToRightPart: PropTypes.func,
        removeFromRightPart: PropTypes.func,
        addToLeftPart: PropTypes.func,
        removeFromLeftPart: PropTypes.func
      };

      static contextTypes = {
        showMessage: PropTypes.func
      };

      getChildContext() {
        return {
          right: this.state.right,
          left: this.state.left,
          addToRightPart: this.addToRightPart,
          removeFromRightPart: this.removeFromRightPart,
          addToLeftPart: this.addToLeftPart,
          removeFromLeftPart: this.removeFromLeftPart
        };
      }

      prepareInitialState = initialState => {
        return Object.assign(
          {
            right: [],
            left: []
          },
          initialState
        );
      };

      handleGenericChange = updater => {
        return new Promise((res, rej) => {
          this.setState(updater, res);
        });
      };

      // must have unique id and render func
      addToRightPart = renderer => {
        return this.handleGenericChange(state => ({
          ...state,
          right: [...state.right, renderer]
        }));
      };

      removeFromRightPart = rendererId => {
        return this.handleGenericChange(state => ({
          ...state,
          right: state.right.filter(renderer => renderer.id !== rendererId)
        }));
      };

      // must have unique id and render func
      addToLeftPart = renderer => {
        return this.handleGenericChange(state => ({
          ...state,
          right: [...state.right, renderer]
        }));
      };

      removeFromLeftPart = rendererId => {
        return this.handleGenericChange(state => ({
          ...state,
          right: state.right.filter(renderer => renderer.id !== rendererId)
        }));
      };

      render() {
        const { left, right } = this.state;
        const {
          addToRightPart,
          removeFromRightPart,
          addToLeftPart,
          removeFromLeftPart
        } = this;
        return (
          Component && (
            <Component
              {...this.props}
              left={left}
              right={right}
              addToRightPart={addToRightPart}
              removeFromRightPart={removeFromRightPart}
              addToLeftPart={addToLeftPart}
              removeFromLeftPart={removeFromLeftPart}
            />
          )
        );
      }
    };
  };
}

export function withReset(getState = () => ({})) {
  // TODO: implement getting ( e.g. fetching API ) default state ( what to reset to )
  return function _Resetable(Component) {
    return class _ResetableComponent extends React.Component {
      state = {
        key: 1,
        isChanged: false,
        ...getState()
      };

      setChanged = (isChanged = true) => this.setState({ isChanged });

      reset = () =>
        this.setState(state => ({
          ...state,
          isChanged: false,
          key: state.key + 1
        }));

      render() {
        return (
          <Component
            {...this.props}
            key={this.state.key}
            isChanged={this.state.isChanged}
            reset={this.reset}
            setChanged={this.setChanged}
          ></Component>
        );
      }
    };
  };
}

export function withPopover(Component) {
  return class _WithPopover extends React.Component {
    state = {
      open: false
    };

    anchorEl = null;

    componentWillUnmount() {
      window.removeEventListener("click", this.outsideClick);
    }

    setAnchorEl = node => (this.anchorEl = node);

    outsideClick = ev => {
      if (ev.target !== this.anchorEl && !this.anchorEl.contains(ev.target)) {
        this.setState({ open: false });
        window.removeEventListener("click", this.outsideClick);
      }
    };

    showSelect = ev => {
      this.setState({ open: true });
      window.addEventListener("click", this.outsideClick);
    };

    closeSelect = () => {
      window.removeEventListener("click", this.outsideClick);
      this.setState({ open: false });
    };

    render() {
      const { open } = this.state;
      const { closeSelect, showSelect, setAnchorEl } = this;
      return (
        <Component
          {...this.props}
          open={open}
          setAnchorEl={setAnchorEl}
          closeSelect={closeSelect}
          showSelect={showSelect}
        />
      );
    }
  };
}
export function withRatingAndComment(Component) {
  const hoc = class _CommonRatingComment extends React.Component {
    static contextTypes = {
      showMessage: PropTypes.func
    };

    constructor(props) {
      super(props);
      this.state = {
        comment: "",
        average: this.props.entity
          ? this.props.entity.rating?.average || 0
          : this.props.vessels && this.props.currentVesselRatingIndex
          ? this.props.vessels[this.props.currentVesselRatingIndex]?.rating
              ?.average
          : 0,
        reviews: this.props.entity
          ? this.props.entity.rating?.reviews || 0
          : this.props.vessels && this.props.currentVesselRatingIndex
          ? this.props.vessels[this.props.currentVesselRatingIndex]?.rating
              ?.reviews
          : 0,
        techRating: 0,
        operRating: 0,
        total: null
      };
    }

    componentDidMount() {
      this.getPreviousRating();
    }

    componentWillReceiveProps(nextProps) {
      if (
        nextProps.entity.rating?.average !==
          this.props.entity.rating?.average ||
        nextProps.entity.rating?.reviews !==
          this.props.entity.rating?.reviews ||
        nextProps.currentVesselRatingIndex !==
          this.props.currentVesselRatingIndex
      ) {
        if (this.state.average !== nextProps.entity.rating.average) {
          this.getPreviousRating();
        }
        this.setState({
          average: this.props.entity
            ? nextProps.entity?.rating?.average
            : nextProps.vessels?.[this.props.currentVesselRatingIndex]?.rating
                ?.average,
          reviews: this.props.entity
            ? nextProps.entity?.rating?.reviews
            : nextProps.vessels?.[this.props.currentVesselRatingIndex]?.rating
                ?.reviews
        });
      }
    }

    setTotalComments = data => {
      if (data && data.hasOwnProperty("total")) {
        this.setState({
          total: data.total
        });
      }
    };

    getPreviousRating = () => {
      if (!this.props.user) {
        return;
      }
      let { entity, vessels = [], currentVesselRatingIndex } = this.props;
      entity = entity || vessels[currentVesselRatingIndex];
      if (!entity) return;

      Vessel.getPreviousRating({ id: entity._id })
        .then(res =>
          res.status === 200
            ? this.setState({
                operRating: res.data.operationResponse,
                techRating: res.data.technicalCondition
              })
            : null
        )
        .catch(error => console.error(error));
    };

    setComment = val => {
      this.setState({
        comment: val
      });
    };

    handleSaveRating = e => {
      /* if (!this.state.subscribedToMarketplace) {
        this.props.toggleSubscribeDialog(true);
        return;
      }; */
      /*  let techRating = Array.from(document.getElementsByName('rating-technical')).find(item => !!item.checked)?.value || 0;
      let operRating = Array.from(document.getElementsByName('rating-operational')).find(item => !!item.checked)?.value || 0; */

      let { entity, entityType = "vessel", vessels } = this.props;

      this.props
        .ratingSave({
          id: entity._id,
          type: entityType,
          technicalCondition: +this.state.techRating || 0,
          operationResponse: +this.state.operRating || 0
        })
        .then(response => {
          if (response.status === 200) {
            this.setState(
              {
                average: response.data.average
              },
              () => {
                const newEntity = { ...entity, rating: response.data };
                this.props.handleShowModifiedRatingForRelevant &&
                  this.props.handleShowModifiedRatingForRelevant(newEntity);
                if (this.props.updateRating) {
                  this.props.updateRating(newEntity);
                } else {
                  PubSub.publish(`${entityType}:ratingUpdated`, newEntity);
                }
                this.props.updateShowRating &&
                  this.props.updateShowRating({ ...response.data });
              }
            );
          }
        })
        .catch(error => console.error(error));
    };

    handleStars = (type, i) => {
      const { user } = this.props;
      if (!user) {
        return history.push("/login");
      }

      this.setState(
        {
          [type]: i
        },
        this.handleSaveRating
      );
    };

    handleSaveComment = async (isPrivate = false) => {
      const { user } = this.props;
      if (!user) {
        return history.push("/login");
      }
      try {
        let { entity, vessels, currentVesselRatingIndex } = this.props;
        if (this.state.comment.trim().length <= 0) return;
        if (!entity) {
          entity = vessels[currentVesselRatingIndex];
        }
        const { entityId = entity._id, entityType = "vessel" } = this.props;
        const res = await this.props.commentSave({
          entityId,
          entityType,
          text: this.state.comment,
          isPrivate: isPrivate
        });
        if (res.status === 200) {
          this.setComment("");
          PubSub.publish("vessel:commentsUpdated", res.data);
        }
      } catch (error) {
        console.error(error);
        this.context?.showMessage({
          message: {
            level: "error",
            message: `Couldn't save comment. Please try later. Error: ${error?.message ??
              error}`
          }
        });
      }
    };

    render() {
      const {
        handleSaveComment,
        handleStars,
        handleSaveRating,
        setComment,
        getPreviousRating,
        setTotalComments
      } = this;
      const {
        comment,
        average,
        review,
        techRating,
        operRating,
        total
      } = this.state;

      return (
        <Component
          {...this.props}
          handleSaveComment={handleSaveComment}
          handleStars={handleStars}
          handleSaveRating={handleSaveRating}
          setComment={setComment}
          getPreviousRating={getPreviousRating}
          setTotalComments={setTotalComments}
          comment={comment}
          average={average}
          total={total}
          review={review}
          techRating={techRating}
          operRating={operRating}
        />
      );
    }
  };

  return connect(state => ({ user: state.login.user }), {
    ratingSave,
    commentSave
  })(hoc);
}
withRatingAndComment.ratingGradation = [1, 0.8, 0.6, 0.4, 0.2];

export function withCalculations(Component) {
  const hoc = class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        savedCalculations: [],
        opened: false
      };
    }
    async componentDidMount() {
      this.setState({ loading: true });
      try {
        const res = await CalculatorApi.getList(this.props.calculatorCodeName);
        const savedCalculations = res.data.map(c => ({
          ...c,
          mine: c.createdBy === this.props.user._id,
          checked: false
        }));
        savedCalculations.sort((a, b) => b.createdAt - a.createdAt);
        this.setState({ savedCalculations });
      } catch (error) {
        console.error(error);
      } finally {
        this.setState({
          loading: false
        });
      }
    }

    handleCalculationSelected = async _id => {
      try {
        const calculation = (await CalculatorApi.findById(_id)).data;
        this.props.onCalculationSelected(calculation);
        this.setState({ opened: true });
      } catch (error) {
        console.log(error);
      }
    };

    handleDelete = async (_id, e) => {
      try {
        e.stopPropagation();
        await CalculatorApi.remove(_id);
        this.setState({
          savedCalculations: this.state.savedCalculations.filter(
            sc => sc._id !== _id
          )
        });
      } catch (error) {
        console.log(error);
      }
    };

    mutateCalculation = (id, field, value) => {
      const { savedCalculations } = this.state;
      const index = savedCalculations.findIndex(c => c._id === id);

      const newCalculation = {
        ...savedCalculations[index],
        [field]: value
      };

      let newSavedCalculations = [...savedCalculations];
      newSavedCalculations[index] = newCalculation;
      this.setState({
        savedCalculations: newSavedCalculations
      });
    };

    changeCalculationsArray = calculations => {
      this.setState({
        savedCalculations: calculations
      });
    };
    printSelected = async () => {
      this.setState({ loading: true });
      try {
        await this.props.printSelected(
          this.state.savedCalculations.filter(c => c.checked)
        );
      } finally {
        this.setState({ loading: false });
      }
    };

    render() {
      const {
        handleDelete,
        handleCalculationSelected,
        mutateCalculation,
        changeCalculationsArray,
        printSelected
      } = this;
      const { savedCalculations, loading, opened } = this.state;
      return (
        <Component
          handleDelete={handleDelete}
          handleCalculationSelected={handleCalculationSelected}
          mutateCalculation={mutateCalculation}
          changeCalculationsArray={changeCalculationsArray}
          savedCalculations={savedCalculations}
          loading={loading}
          opened={opened}
          {...this.props}
          printSelected={printSelected}
        />
      );
    }
  };

  const connected = connect(({ login }) => ({ user: login.user }))(hoc);
  return connected;
}

export function withRows(Component) {
  return class extends React.Component {
    static propTypes = {
      rows: PropTypes.array
    };
    static defaultProps = {
      rows: [],
      handleChange: () => undefined
    };
    constructor(props) {
      super(props);
      this.state = {
        rows: props?.rows?.length
          ? this.transformProps(props.rows)
          : Component?.defaultRows
          ? Component.defaultRows
          : [this.getRandomId()]
      };
    }

    componentWillReceiveProps(nextProps) {
      if (
        nextProps?.rows?.length >= 0 &&
        nextProps?.rows !== this.props?.rows
      ) {
        const defaultRows = Component?.defaultRows ? Component.defaultRows : [];
        this.setState(state => ({
          rows: [...this.transformProps(nextProps.rows)]
          // rows: defaultRows && defaultRows.length && state.rows !== defaultRows ? [...this.transformProps(nextProps.rows)].concat(defaultRows) :  [...this.transformProps(nextProps.rows)]
        }));
      }
    }
    transformProps = (rows = []) => {
      let func = (item, index) => index;
      if (Component.parseRows) {
        rows = Component.parseRows(rows).map((row, index) => {
          return (
            row && {
              ...(typeof row === "object" ? row : {}),
              id: row?.id ? row.id : this.getRandomId()
            }
          );
        });
      } else {
        rows = rows.map(func);
      }

      /*  let defaultRows = Component?.defaultRows || [];

      if (defaultRows && defaultRows.length) {
       defaultRows = defaultRows.map(row => {
         const inRows = rows.findIndex(i => i.mode.trim() === row.mode);
          if (inRows >= 0) {
            const obj = {
              ...rows[inRows],
              id: row.id
            }
            rows.splice(inRows, 1)
            return obj;
          }
          return row;
       })
      } */
      return rows;
    };
    getRandomId = () => String(Math.random()).replace(".", "");

    handleAddRow = () => {
      const rows = [...this.state.rows, this.getRandomId()];
      this.props.handleChange(rows);
      this.setState({ rows });
    };

    changeField = ({ id, field } = {}, _, val) => {
      const rows = this.state.rows.map(row =>
        row === id
          ? { id: row, [field]: val }
          : row.id === id
          ? { ...(typeof row === "object" ? row : {}), [field]: val }
          : row
      );
      this.props.handleChange(rows);
      this.setState({ rows });
    };

    handleRemoveRow = index => {
      index = index?.id ? index.id : index;
      const rows = this.state.rows.filter((row, i) =>
        row?.id ? row.id !== index : row !== index
      );
      this.props.handleChange(rows);
      this.setState({ rows });
    };
    render() {
      return (
        <Component
          {...this.props}
          changeField={this.changeField}
          rows={this.state.rows}
          handleAddRow={this.handleAddRow}
          handleRemoveRow={this.handleRemoveRow}
          changeRowsQuantity={this.changeRowsQuantity}
        ></Component>
      );
    }
  };
}

export function withGears(Component) {
  const types = ["CR", "BT", "DR", "GN", "HC"];
  const labels = {
    CR: "Crane",
    BT: "Boom Transporter",
    DR: "Derrick",
    GN: "Gantry",
    HC: "Hose Crane"
  };
  return class extends React.PureComponent {
    static propTypes = {
      gears: PropTypes.array
    };
    static defaultProps = {
      gears: [{ category: "CR", key: 0 }]
    };
    static types = types;
    static labels = labels;
    state = {};

    constructor(props) {
      super(props);
      this.state.gears = this.props.gears.map((g, i) => {
        g.key = i;
        return g;
      });
      if (this.state.gears.length === 0) {
        this.state.gears = [{ category: "CR", key: 0 }];
      }
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps.gears !== this.props.gears) {
        this.setState({
          gears: nextProps.gears
        });
      }
    }

    handleAddGear = () => {
      const biggestKey = this.state.gears.reduce(
        (acc, gear) => (gear.key >= acc ? gear.key : acc),
        0
      );
      this.setState({
        gears: [
          ...this.state.gears,
          {
            ...this.state.gears[this.state.gears.length - 1],
            key: biggestKey + 1
          }
        ]
      });
    };

    handleRemoveGear = i => {
      this.setState({ gears: removeFromArray(this.state.gears, i) });
    };

    handleChangeGear = (i, fieldName, e, value) => {
      const newGear = { ...this.state.gears[i], [fieldName]: value };
      this.setState({ gears: replaceInArray(this.state.gears, i, newGear) });
    };
    render() {
      const { ...rest } = this.props;
      return (
        <Component
          {...rest}
          types={types}
          labels={labels}
          handleChangeGear={this.handleChangeGear}
          handleAddGear={this.handleAddGear}
          handleRemoveGear={this.handleRemoveGear}
          gears={this.state.gears}
        ></Component>
      );
    }
  };
}

export function withHoHa(Component) {
  const hatchTypes = [
    "Combined",
    "Folding",
    "Mc Gregor",
    "Pontoon",
    "Rolling",
    ""
  ];
  return class extends React.PureComponent {
    static propTypes = {
      holds: PropTypes.object,
      hatches: PropTypes.object
    };
    static defaultProps = {
      holds: { quantity: 0, lowerDims: [], tweenDims: [] },
      hatches: { quantity: 0, hatchType: "", size: [] }
    };
    state = {};

    constructor(props) {
      super(props);
      this.state.holds = { ...this.props.holds };
      this.state.hatches = { ...this.props.hatches };
    }

    handleChangeHoldsQuantity = (e, value) => {
      this.setState({
        holds: { ...this.state.holds, quantity: parseInt(value, 10) }
      });
    };
    handleChangeHatchesQuantity = (e, value) => {
      this.setState({
        hatches: { ...this.state.hatches, quantity: parseInt(value, 10) }
      });
    };
    componentWillReceiveProps(nextProps) {
      if (
        nextProps.holds !== this.props.holds ||
        nextProps.hatches !== this.props.hatches
      ) {
        this.setState({
          holds: nextProps.holds,
          hatches: nextProps.hatches
        });
      }
    }
    handleAreasChange = () => {
      setTimeout(() => {
        const md =
          (this.refs.child.refs.areasMd &&
            parseFloat(this.refs.child.refs.areasMd.refs.input.getValue())) ||
          0;
        const td =
          (this.refs.child.refs.areasTd &&
            parseFloat(this.refs.child.refs.areasTd.refs.input.getValue())) ||
          0;
        const wd =
          (this.refs.child.refs.areasWd &&
            parseFloat(this.refs.child.refs.areasWd.refs.input.getValue())) ||
          0;
        const total = md + td + wd;
        if (total) {
          this.refs.child.refs.areasTotal &&
            this.refs.child.refs.areasTotal.refs.input.setValue(total);
        }
      }, 0);
    };
    render() {
      const { ...rest } = this.props;
      return (
        <Component
          ref="child"
          {...rest}
          handleChangeHoldsQuantity={this.handleChangeHoldsQuantity}
          handleChangeHatchesQuantity={this.handleChangeHatchesQuantity}
          holds={this.state.holds}
          hatchTypes={hatchTypes}
          handleAreasChange={this.handleAreasChange}
          hatches={this.state.hatches}
        />
      );
    }
  };
}
