import getMuiTheme from 'material-ui/styles/getMuiTheme';

const theme = getMuiTheme({
  palette: {
    primary1Color: '#7FC931',
    primary2Color: '#0097a7',
    primary3Color: '#bdbdbd',
    accent1Color: '#4D9E0E',
    accent2Color: '#f5f5f5',
    accent3Color: '#9e9e9e',
    textColor: 'rgba(0, 0, 0, 0.87)',
    secondaryTextColor: 'rgba(0, 0, 0, 0.54)',
    alternateTextColor: '#ffffff',
    canvasColor: '#ffffff',
    borderColor: '#e0e0e0',
    disabledColor: 'rgba(0, 0, 0, 0.3)',
    pickerHeaderColor: '#285596',
    clockCircleColor: 'rgba(0, 0, 0, 0.07)',
    shadowColor: 'rgba(0, 0, 0, 1)',
  },
  contentFontFamily: "'Roboto', sans-serif",
  fontFamily: "'Roboto', sans-serif",
});
theme.svgIcon.color = 'rgba(0, 0, 0, 0.298039)';
theme.checkbox.boxColor = 'rgba(1, 1, 1, 0.54)';
theme.checkbox.labelColor = '#B3B3B3';

theme.radioButton.borderColor = 'rgba(1, 1, 1, 0.54)';
theme.radioButton.labelColor = '#B3B3B3';

theme.raisedButton.secondaryColor = '#EDEDED';
theme.raisedButton.secondaryTextColor = '#787878';
theme.raisedButton.grayTextColor = '#999999';
theme.raisedButton.grayColor = '#EDEDED';
theme.raisedButton.fontSize = 13;
theme.raisedButton.boxShadow = 'none';
theme.raisedButton.borderRadius = 0;

theme.datePicker.selectColor = '#285596';

theme.button.boxShadow = 'none';
theme.button.borderRadius = 0;

theme.button.height = 32;
theme.button.minWidth = 92;

theme.customProperties = {
  vesselIconActive: '#75C422',
}

export default theme;
