import React from 'react';
import Layout from '../../components/Layout';
import {
  getVessel,
} from '../../actions/vessel';
import history from '../../core/history';
import Page from '../../components/Page/Page';
import NotFound from '../notFound/NotFound';
import defaultVesselImg from '../../public/defaultVesselImg.png';
import { atDateTime, formatTypeByPurposePlural, pointToDMS } from '../../core/format';

export default {
  path: '/vessel',
  children: [
    {
      path: '/create',
      action({ params, hash, ...rest }) {
        if(typeof window !== 'undefined') {
          history.replace("/main-deck/general/-/-/create");
        }
        return {
          title: 'SHIPNEXT - Shipping Marketplace and Transportation Platform',
          component: (
            <Layout>
              <div></div>
            </Layout>
          ),
        };
      },
    },
    {
      path: '/:imoOrId/:action?/:bidId?/:tcOffer?',
      async action({ params, hash, store, token, next, ...rest }) {
        const FullPageVesselDetails = ((await import(/* webpackChunkName: 'Fleet' */ '../../components/Vessel/FullPageVesselDetails/FullPageVesselDetails')).default);
        let vessel;
        let edit = false;
        let tc = false;
        let tcOffer = false;
        let bidOpen = false;
        let cargoContracts = false;
        let fleetContracts = false;
        let exchange = false;
        try {
          const res = await store.dispatch(getVessel(params.imoOrId, token));
          vessel = res.data;

          if (params.imoOrId.length === 24 && params.action === 'edit') {
            edit = true;
          }
          if (params.action === 'tc') {
            tc = true;
          }
          if (params.action === 'cargo-contracts') {
            cargoContracts = true;
          }
          if (params.action === 'fleet-contracts') {
            fleetContracts = true;
          }
          if (params.action === 'exchange') {
            exchange = true;
          }
          if (params.tcOffer === 'tc-offer') {
            tcOffer = true;
          }
          if (params.bidId) {
            bidOpen = true;
          }
        } catch (e) {
          console.error(e);
        }
        if (!vessel?.vessel) {
          return ({
            title: "Not Found",
            component: <NotFound title={"Not Found"} />,
            status: 404,
            public: true,
          }); 
        }
        const description = generateVesselDescription(vessel);
        const image = generateVesselImagePath(vessel);
        const title = generateVesselTitle(vessel)
        return {
          title,
          description,
          component: (
            <Layout>
              <Page headerTitle={"FLEET"}>
                <FullPageVesselDetails {...rest} selectedVessel={vessel} edit={edit} tc={tc} bidId={params.bidId} tcOffer={tcOffer} bidOpen={bidOpen} cargoContracts={cargoContracts} fleetContracts={fleetContracts} exchange={exchange} />
              </Page>
            </Layout>
          ),
          public: !tc && !tcOffer,
          ogImg: image,
        };
      },
    },
  ],
};


function generateVesselDescription(vessel) {
    return `Vessel ${vessel.vessel?.name || vessel.name}, IMO ${vessel.vessel?.imoNumber || vessel.imoNumber} is a ${formatTypeByPurposePlural(vessel.vessel?.typeByPurpose || vessel.typeByPurpose, 1)} built in ${vessel.vessel?.blt || vessel.blt} and currently sailing under the flag of the ${vessel.vessel?.flag.name || vessel.flag.name}`
}

function generateVesselImagePath(vessel) {
  const path = [];
  if (vessel) {
    const selectedVessel = vessel.vessel || vessel;
    if (selectedVessel.images && selectedVessel.images.length) {
      const url = selectedVessel.images[0].url;
      path.push(url);
    } else {
      path.push(`https://shipnext.com/api/v1/file/64f0ab6f277e15c2babf2d40`)
    }
  }
  return path.join('');
}

function generateVesselTitle(vessel){
  return `${vessel.vessel?.name || vessel.name}: ${formatTypeByPurposePlural(vessel.vessel?.typeByPurpose || vessel.typeByPurpose, 1)} & Details and current position, IMO ${vessel.vessel?.imoNumber || vessel.imoNumber} | Shipnext `
}
