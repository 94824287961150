import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cx from 'classnames';
// import IconButton from 'material-ui/IconButton';
import ActionSwapVert from 'material-ui/svg-icons/action/swap-vert';
import MenuItem from 'material-ui/MenuItem';
import IconMenu from 'material-ui/IconMenu';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Sort.scss';
import RoundActionBtn from './../Common/RoundActionBtn';
import Divider from 'material-ui/Divider';
import { debounceWithoutFirstCall as debounce } from './../../core/utils';

const OWNER_ME = 'me';
const OWNER_GROUP = 'group';

class Sort extends Component {
  static propTypes = {
    handleSort: PropTypes.func.isRequired,
    sort: PropTypes.object.isRequired,
    values: PropTypes.object,
    handleChangeExtra: PropTypes.func,
    extraValues: PropTypes.object,
    extraValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    hasLinerTraderFirst: PropTypes.bool,
  };
  static defaultProps = {
    sort: { field: 'addedAt', value: -1, label: 'Status' },
    values: {
      addedAt: { field: 'addedAt', value: -1, label: 'Status' },
      status: { field: 'status', value: -1, label: 'Status' },
    },
    owner: [OWNER_ME, OWNER_GROUP],
  };

  handleChange = debounce((e, value) => {
    if (this.preventChangeOnce) {
      delete this.preventChangeOnce;
      return;
    }

    this.props.handleSort && this.props.handleSort(typeof value === "object" ? value : {
      ...this.props.values[value],
      value: this.props.sort?.value || 1,
      biddingStatus: this.props.sort?.biddingStatus || false,
    });
  }, 150)

  handleAscDesc = async (ev, val) => {
    ev.stopPropagation();
    ev.preventDefault();
    ev.nativeEvent?.stopImmediatePropagation?.();
    if (this.refs.iconMenu) {
      this.refs.iconMenu?.setState({
        open: false,
        anchorEl: null,
      });
    }

    const { values, sort } = this.props;

    this.handleChange(null, values[sort.field] ? {
      ...sort,
      value: val,
    } : "addedAt");
  }

  handleBidStatus = async (ev, value) => {
    const { values, sort } = this.props;
    ev.stopPropagation();
    ev.preventDefault();
    this.handleChange(null, values[sort.field] ? {
      ...sort,
      biddingStatus: value,
    } : "addedAt");
  }

  handleFavoriteStatus = async (ev, value) => {
    const { values, sort } = this.props;
    ev.stopPropagation();
    ev.preventDefault();
    this.handleChange(null, values[sort.field] ? {
      ...sort,
      favedFirst: value,
    } : "addedAt");
  }

  handleLinerTraderFirst = async (ev, value) => {
    const { values, sort } = this.props;
    ev.stopPropagation();
    ev.preventDefault();
    this.handleChange(null, values[sort.field] ? {
      ...sort,
      isLinerTraderFirst: value,
    } : "addedAt");
  }

  handleExtraItemClick = (ev, value) => {
    ev.stopPropagation();
    ev.preventDefault();

    const { extraValue } = this.props;

    if (Array.isArray(extraValue)) {
      const newExtraValue = extraValue.slice();
      const index = extraValue.indexOf(value);
      if (index >= 0) {
        if (extraValue.length === 1) { // The last one option may not be unselected
          return;
        }
        newExtraValue.splice(index, 1);
      } else {
        newExtraValue.push(value);
      }
      this.handleChangeExtra(newExtraValue);
    } else {
      // TODO: to implement
    }

    this.preventChangeOnce = true; // TODO: get rid of this hack
  };

  handleChangeExtra = debounce((extraValue) => {
    this.props.handleChangeExtra && this.props.handleChangeExtra(extraValue);
  }, 150);

  render() {
    const value = this.props.sort.field;
    const label = this.props.values[value] && this.props.values[value].label;
    const { statusCargo, bidStatus, extraValue, withFavorites, hasLinerTraderFirst } = this.props;

    const isAsc = this.props.sort.value === 1;
    const isBid = this.props.sort.biddingStatus;
    const isFavoriteFirst = this.props.sort.favedFirst;
    const { isLinerTraderFirst } = this.props.sort

    return (
      <div style={{ ...this.props.style }} className={cx(s.sort, statusCargo, value ? s.active_sort : "")}>
        <IconMenu
          desktop
          ref="iconMenu"
          onChange={this.handleChange}
          value={value}
          style={{
            verticalAlign: 'middle',
          }}
          iconButtonElement={
            <RoundActionBtn
              dark={!this.props.isDark}
              tooltip={label || "Sort"}
              iconClassName={s.sort_btn}
              //onClick={this.props.sortOnIconClick ? this.handleAscDesc : arg => arg}
            >
              <ActionSwapVert />
            </RoundActionBtn>
          }
        >
          {Object.keys(this.props.values).map((key) => {
            const o = this.props.values[key];
            return <MenuItem checked={value === o.field} className={s.sort_item} key={key} value={o.field} primaryText={o.label} />;
          })}
          {!!this.props.extraValues && <Divider />}
          {!!this.props.extraValues && Object.keys(this.props.extraValues).map((key) => {
            const o = this.props.extraValues[key];
            return <MenuItem checked={Array.isArray(extraValue) ? extraValue.includes(o.field) : extraValue === o.field} className={s.sort_item} key={key} value={o.field} primaryText={o.label} onClick={e => this.handleExtraItemClick(e, o.field)} />;
          })}
          <Divider />
          <MenuItem className={s.sort_item} checked={isAsc} primaryText="Ascending" onClick={ev => this.handleAscDesc(ev, 1)} />
          <MenuItem className={s.sort_item} checked={!isAsc} primaryText="Descending" onClick={ev => this.handleAscDesc(ev, -1)} />
          {bidStatus === "biddingStatus" && <Divider />}
          {bidStatus === "biddingStatus" && <MenuItem className={s.sort_item} checked={isBid} primaryText="Bidding first" onClick={ev => this.handleBidStatus(ev, !isBid)} />}
          {withFavorites && <Divider />}
          {withFavorites && <MenuItem className={s.sort_item} checked={isFavoriteFirst} primaryText="Starred first" onClick={ev => this.handleFavoriteStatus(ev, !isFavoriteFirst)} />}
          {hasLinerTraderFirst && <Divider />}
          {hasLinerTraderFirst && <MenuItem className={s.sort_item} checked={isLinerTraderFirst} primaryText="Liner Trader first" onClick={ev => this.handleLinerTraderFirst(ev, !isLinerTraderFirst)} />}
        </IconMenu>
      </div>
    );
  }
}

export default withStyles(s)(Sort);
